/**
 * If you want to enable logs from datafeed set it to `true`
 */
import { ChartStateParams } from "../models/orders"
var isLoggingEnabled = false;
var ClientCode = "Anonymous User"
export function logMessage(message:string,level = 0) {
    if (isLoggingEnabled) {
        const now = new Date();
        // tslint:disable-next-line:no-console
        if( level ==  0){
            console.info(`${ClientCode}>${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`);
        }else if(level = 2){
            console.warn(`${ClientCode}>${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`)
        }
        
    }
}

export function setLogging(flag:boolean){
    isLoggingEnabled = flag
}

export function setLoggingCC(client_code:string){
    ClientCode = client_code
}
export function getErrorMessage(error:any) {
    if (error === undefined) {
        return '';
    }
    else if (typeof error === 'string') {
        return error;
    }
    return error.message;
}

export function setOrderProperties(params:ChartStateParams){
    if(params.margin == undefined){
        params.margin = 1
    }
    if(params.order_type == undefined){
        if(params.type == 1){
            params.order_type = '1'
        }else{
            params.order_type = '2'
        }
    }
    return params
}