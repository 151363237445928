import { ReactComponent as Delete } from "../../assets/icons/trash.svg";

interface CustomAlertButtonProps {
  onClick: (event: React.MouseEvent<SVGElement>) => void; // Click handler
  isDisabled?: boolean; // Whether the button is disabled
  status : "active" | "triggered" | "deleted"
}

const DeleteAlertButton: React.FC<CustomAlertButtonProps> = ({
  onClick,
  isDisabled = false, // Default to not disabled
  status
}) => {
  if (status === 'deleted') {
    return <>-</>; // Return null if `isDeleted` is true
  }
  return (
    <Delete
      onClick={isDisabled ? undefined : onClick} // Disable click if `isDisabled` is true
      style={{
        height: 16,
        width: 16,
        cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor when disabled
        opacity: isDisabled ? 0.7 : 1, // Adjust opacity when disabled
      }}
    />
  );
};

export default DeleteAlertButton;
