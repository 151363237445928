import "./create-edit-form.scss";
import AlertContainer from "../AlertContainer";
import AlertTile from "../AlertTile";
import { AlertData } from "../../../hooks/api/get/getAlerts";
import { Alerts, AlertsType } from "../AlertTools";
import NoStocksFound from "../../../components/NoStocksFound";

interface AlertList {
  currentAlerts: AlertsType;
  finalData: AlertData[];
  handleAlertsTab: (alerts: Alerts) => void;
  onDeleteAlert: (id: number) => void;
  onEditAlert: (alert: Omit<AlertData, "status">) => void;
  numberOfAlerts: number;
}

const AlertSearchList: React.FC<AlertList> = ({
  currentAlerts,
  finalData,
  handleAlertsTab,
  onDeleteAlert,
  onEditAlert,
  numberOfAlerts,
}) => {
  const isDisabled = numberOfAlerts >= 20;
  return (
    <>
      <AlertContainer>
        <div className="px-5 py-4">
          {finalData.length ? (
            finalData.map((item) => (
              <AlertTile
                key={item.id}
                onEditAlert={onEditAlert}
                onDeleteAlert={onDeleteAlert}
                {...item}
              />
            ))
          ) : (
            <NoStocksFound
              heading={
                currentAlerts === "all"
                  ? "No Alert Found"
                  : currentAlerts === "active"
                  ? "No Active Alert Found" 
                  : "No Triggred Alert Found"
              }
              subHeading=""
            />
          )}
        </div>
      </AlertContainer>
      <div
        style={{ height: 60 }}
        className={`px-5 alerts-create-edit-background d-flex justify-content-${
          isDisabled ? "between" : "end"
        } gap-3 py-2`}
      >
       {isDisabled && <div
          className="my-auto max-20-alerts"
        >
          You can set up to 20 alerts.
        </div>}
        <button
          type="button"
          className="btn-alert primary"
          style={{
            cursor: isDisabled ? "not-allowed" : "pointer",
            opacity: isDisabled ? 0.7 : 1,
          }}
          disabled={isDisabled}
          onClick={() => handleAlertsTab("create-alerts")}
        >
          Create Alert
        </button>
      </div>
    </>
  );
};

export default AlertSearchList;
