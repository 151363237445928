import { logMessage } from '../utils/helpers';

interface Headers {
    [key: string]: string; // Type for headers object (key-value pairs)
}

export class OdinRequester {
    private _headers?: Headers; 
    private logoutFunction: () => void; 

    constructor(headers?: Headers, logoutFunction?: () => void) {
        if (headers) {
            this._headers = headers;
        }
        if (logoutFunction) {
            this.logoutFunction = logoutFunction;
        } else {
            this.logoutFunction = () => {
                throw new Error('Logout function is not provided');
            };
        }
    }

    async sendRequest(odinUrl: string,urlPath: string,body?: any,method: string = 'GET') {
        logMessage('Odin New request: ' + urlPath);

        const options: RequestInit = { method };

        if (this._headers) {
            options.headers = this._headers;
        }
        if (body) {
            options.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(`${odinUrl}${urlPath}`, options);
            if (response.status === 401) {
                this.logoutFunction();
                return;
            }
            const responseText = await response.text();
            return JSON.parse(responseText);
        } catch (error) {
            console.error(error);
        }
    }
}
