import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setModal } from "../../../../slices/gttSlices";
import { OptionTimeframe, TradeDirection } from "../gttTypes";
import GttHeaders from "../GttHeader";
import useStockDetails from "../../../../hooks/api/get/getScripDetails";
import nextId from "react-id-generator";
import { DataFeedInstance } from "../../../../chart-datafeed/udf-compatible-datafeed";
import type { Quotes } from "../../../../models/symbolData";
import {
  ExchangeTypes,
  getInstrumentType,
  GttBodyTabsSelectors,
} from "../GTTBody/gtt.body.selector.helper";
import GTTTabs from "../GTTTabs";
import EditGTTComp from "../GTTBody/EditGTT";
import type { LiveQuotes } from "../../../../models/gttOrders";
import "../common/scss/gtt.scss";
import { ReactComponent as CloserIcon } from "../../../../assets/images/close-square2.svg";

const EditGTT: React.FC = () => {
  // State Management
  const [currentGttTab, setCurrentGttTab] = useState<OptionTimeframe>();
  const [selectedMarketSegmentId, setSelectedMarketSegmentId] = useState<number | null>(null);
  const [tradeDirection, setTradeDirection] = useState<TradeDirection>("Sell");
  const [ltpChChp, setLtpChChp] = useState<LiveQuotes>({ ltp: 0, ch: 0, chp: 0 });

  const dispatch = useDispatch();
  const { isOpenModal: currentId, gttOrders } = useSelector(
    (state: RootState) => state.gttAlerts
  );

  const targetOrder = gttOrders.find((order) => order.id === currentId);

  // Separate useEffect for trade direction to ensure it updates properly
  useEffect(() => {
    if (targetOrder?.transaction_type) {
      const newTradeDirection = targetOrder.transaction_type === "BUY" ? "Buy" : "Sell";
      if (tradeDirection !== newTradeDirection) {
        setTradeDirection(newTradeDirection);
      }
    }
  }, [targetOrder?.transaction_type, tradeDirection]);

  useEffect(() => {
    if (targetOrder?.token && targetOrder?.market_segment_id) {
      setSelectedMarketSegmentId(targetOrder.market_segment_id);
    }
  }, [targetOrder?.token, targetOrder?.market_segment_id]);

  const symbols = targetOrder
    ? `${targetOrder.exchange.split("_")[0]}:${targetOrder.symbol}-${targetOrder.exchange.split("_")[1]}`
    : "";

  // const { data, error } = useSymbolsToTokens([symbols]);

  const token = targetOrder?.token || 0;
  const exchange = targetOrder ? getInstrumentType(targetOrder.exchange as ExchangeTypes) : "";

  const { stockDetails } = useStockDetails(token, exchange || "");

  const gttTabsArray = GttBodyTabsSelectors({
    marketSegmentId: stockDetails?.marketSegmentID || "",
    choserString: stockDetails?.instrumentName || "",
  });

  useEffect(() => {
    if (gttTabsArray.length > 0 && !currentGttTab) {
      setCurrentGttTab(gttTabsArray[0]);
    }
  }, [gttTabsArray, currentGttTab]);

  useEffect(() => {
    let listenerGuid: string | undefined;

    const cleanup = () => {
      if (listenerGuid) {
        DataFeedInstance?.unsubscribeQuotes(listenerGuid);
      }
    };

    if (selectedMarketSegmentId) {
      listenerGuid = nextId();
      DataFeedInstance?.subcscribeQuotesFromTokens(
        [{ market_segment_id: selectedMarketSegmentId, token }],
        (quotes: Quotes[]) => {
          const quote = quotes[0]?.v;
          if (quote) {
            setLtpChChp({ ltp: quote.lp, ch: quote.ch, chp: quote.chp });
          }
        },
        listenerGuid
      );
    }

    return cleanup;
  }, [selectedMarketSegmentId, token]);

  if (!currentId || !targetOrder || !token || !exchange || !stockDetails || !currentGttTab) {
    return null;
  }

  const onModelClose = () => {
    dispatch(setModal("0"));
  };

  return (
    <Draggable handle=".handle_b" bounds="parent">
      <div
        style={{ maxWidth: targetOrder.orders.length === 2 ? "960px" : "483px" }}
        className="custom-order-dialog_basket currentBg draggable-border"
      >
        <div className="container optionStartegyHead handle_b" style={{ height: 80 }}>
          <GttHeaders
            disabled
            toggleTradeDirection={() => {}}
            tradeDirection={tradeDirection}
            exchnage={exchange.toUpperCase()}
            scriptName={stockDetails.symbol}
            securityDesc={stockDetails.securityDesc}
            ltp={ltpChChp.ltp.toString()}
            ch={ltpChChp.ch}
            chp={ltpChChp.chp.toString()}
          />
          <div className="flex c-pointer" onClick={onModelClose}>
            <CloserIcon />
          </div>
        </div>
        {gttTabsArray.length > 0 && (
          <GTTTabs
            disabled
            optionTimeframe={gttTabsArray}
            selected={currentGttTab}
            toggleOptionTimeframe={setCurrentGttTab}
          />
        )}
        <EditGTTComp
          targatedOrder={targetOrder}
          setConditionalWidth={() => {}}
          isCondtionalWidthNeeded={targetOrder.orders.length === 2}
          assetClass="Stocks"
          securityType="Direct"
          tradeDirection={tradeDirection}
          ltp={ltpChChp.ltp.toString()}
          currentGTTTab="Intraday"
          token={`${token}`}
          exchnage={targetOrder.exchange}
          tick={stockDetails?.tick || 0}
          lotSize={stockDetails?.lotSize || 0}
          CloseGttModal={onModelClose}
        />
      </div>
    </Draggable>
  );
};

export default EditGTT;