import React, {useEffect, useState} from 'react';
import {ReactComponent as CloserIcon} from "../../assets/images/close-square2.svg";
import "./WalkThrough.scss"
import introJs from 'intro.js';
import 'intro.js/minified/introjs.min.css';
var darkTheme = "light"
export function getChartTheme(theme:string){
    darkTheme=theme
}

const WalkThrough:React.FC = () =>{
        const [walkThrough,setWalkThrough]=useState<boolean>(false)
        const [startWalkthrough,setStartWalkThrough]=useState<boolean>(false)
        const [currentIndex, setCurrentIndex] = useState<number>(0);

        useEffect(()=>{
            var cookieValue=getCookieValue("introjs-dontShowAgain")
            if(cookieValue==null || cookieValue=="false"){ 
                setWalkThrough(true)
            }else{
                setWalkThrough(false)
            }
        },[])

        function getCookieValue(key:string) {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.startsWith(`${key}=`)) {
                return cookie.substring(key.length + 1);
              }
            }
            return null;
          }

        function setCookie(key:string, value:boolean, expirationDays:number) {
            const date = new Date();
            date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${key}=${value}; ${expires}; path=/`;
          }
          

        const carouselItems = [
            {
              type: "iFrame",
              title: 'Why TradingView terminal is loved?',
              iframe: "https://www.youtube.com/embed/Qx1CeNfPsIs",
              content: ["50 Million traders worldwide use TradingView","Chart based trading helps spot uptrends, downtrends and sideways movements","100+ technical indicators to predict future moves based on past trends","50+ intelligent drawing tools to elucidate their charts and perform detailed technical analysis"]
            },
            {
              type: "iFrame",
              title: 'TV Terminal layout',
              iframe: "https://www.youtube.com/embed/U67CJH_G9EQ",
              content: ["On the top you can search symbol, compare, change time, chart type, apply  indicators, add funds, create basket order and strategy",
            "On the left you can find and use smart drawing tools","On the right you can create, save watchlists as well as check depth of market and data window",
        "On the bottom, you can check orders, positions, holdings, etc.","Right click on chart to find more options like option chain, future chain, etc."],
            },
            {
              type: "iFrame",
              title: 'Chart overview',
              iframe: "https://www.youtube.com/embed/IF_U9sw5eSA",
              content: ["Use upto 16 charts in one single screen","Option to choose between multiple timeframes","Choose between different chart layouts like Bars,Candles,Heikin Ashi,Renko,Kagi etc..","Get advanced option chain with IV and Greeks"],
              // content: ["Click on top blue or red to buy or sell respectively","Click on right + sign on chart to buy/sell order at that price","Enable instant order placement from settings to place order with one click","Right click and open option chain to place order via option chain"],
            },
            // {
            //   type: "iFrame",
            //   title: 'Advanced orders',
            //   iframe: "https://www.youtube.com/embed/ZZNi6LQqVCA",
            //   content: ["Choose Basket order to create baskets","Create multiple baskets, add scrips, analyse and execute to place multiple orders at once","Choose Strategy to create options strategy order","Select strategy to get predefined strategies, analyse payoff graph  and execute."]
            // },
          ];
          const totalItems = carouselItems.length

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, [carouselItems]);

    const handleDotClick = (index:number) => {
        if(index==totalItems-1){
            setStartWalkThrough(true)
        }else{
            setStartWalkThrough(false)
        }
        setCurrentIndex(index);
      };
    
    const handleNextButton = () => {
        if(currentIndex+1==totalItems-1){
            setStartWalkThrough(true)
        }else{
            setStartWalkThrough(false)
        }
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      };
      const elementWalkthrough=()=>{
		var frame = document.querySelectorAll('[id^=tradingview_]')[0] as HTMLIFrameElement;
		var win = frame.contentWindow
		var doc = win?.document
		const searchBar = doc?.querySelector("#header-toolbar-symbol-search") as HTMLElement
    const alerts = doc?.querySelector("#alerts-create") as HTMLElement
		const compare = doc?.querySelector("#header-toolbar-compare") as HTMLElement
		const interval = doc?.querySelector('#header-toolbar-intervals') as HTMLElement
		const differentCandles = doc?.querySelector("#header-toolbar-chart-styles") as HTMLElement
		const indicators = doc?.querySelector("#header-toolbar-indicators") as HTMLElement
		const strategy = doc?.querySelector("#strategy") as HTMLElement
		const basket_order = doc?.querySelector("#basket_order") as HTMLElement
		const add_funds = doc?.querySelector("#add_funds") as HTMLElement
		const manage_account = doc?.querySelector("#manage_account") as HTMLElement
		// const change_theme = doc?.querySelector("#change_theme")
		const feedback = doc?.querySelector("#feedback") as HTMLElement
		const chart_layout = doc?.querySelector('#header-toolbar-layouts') as HTMLElement
		const save_load = doc?.querySelector('#header-toolbar-save-load') as HTMLElement
		const setting = doc?.querySelector('[data-name="header-toolbar-properties"]') as HTMLElement
		// const fullscreen = doc?.querySelector('[data-name="header-toolbar-fullscreen"]')
		// const screenshot = doc?.querySelector('#header-toolbar-screenshot')
		const account_manager = doc?.querySelector('[data-name="paper_trading"]') as HTMLElement
		const watchlist = doc?.querySelector('[data-name="base"]') as HTMLElement
        const dom_button = doc?.querySelector('[data-name="dom-button"]') as HTMLElement
		const buy_sell = doc?.querySelector('.buttonsWrapper-hw_3o_pb') as HTMLElement
		const drawing_tools = doc?.querySelector("#drawing-toolbar") as HTMLElement

        const intro2 = introJs();
        intro2.setOptions({
            showBullets: false,
            exitOnOverlayClick:false,
            tooltipClass: darkTheme=="dark"?'customtooltipDark':'customtooltip',
            keyboardNavigation:true,
            // showStepNumbers:true,
            skipLabel:"Skip the tour",
            stepNumbersOfLabel:'/',
            hidePrev:true,
          steps: [
            {
              intro: `<div class='contentHead'>Scrip search</div>
                      <div class='contentBody'>Search scrip and filter</div>`,
              element:searchBar
            },
            {
                intro: `<div class='contentHead'>Compare symbols</div>
                <div class='contentBody'>Compare symbols to track movements simultaneously</div>`,
                element:compare
              },
              {
                intro: `<div class='contentHead'>Change interval</div>
                <div class='contentBody'>See your chart in different reference timeframes</div>`,
                element:interval
              },
              {
                intro: `<div class='contentHead'>Chart types</div>
                <div class='contentBody'>View your chart in your preferred pattern</div>`,
                element:differentCandles
              },
              {
                intro: `<div class='contentHead'>Choose indicator</div>
                <div class='contentBody'>Apply indicators and analyse your chart</div>`,
                element:indicators
              },
              {
                intro:`<div class='contentHead'>Add funds</div>
                <div class='contentBody'>Add funds to trade or place withdraw request</div>`,
                element:add_funds
              },
              {
                intro:`<div class='contentHead'>Manage account</div>
                <div class='contentBody'>Manage your account details</div>`,
                element:manage_account
              },
              {
                intro:`<div class='contentHead'>Basket orders</div>
                <div class='contentBody'>Create baskets or your own strategies and execute in one go</div>`,
                element:basket_order
              },
              {
                intro:`<div class='contentHead'>Option strategy</div>
                <div class='contentBody'>Pre defined strategies to help your option trading decisions</div>`,
                element:strategy
              },
              {
                intro: `<div class='contentHead'>Create Alert</div>
                        <div class='contentBody'>Create Alerts to get notified when a specific event occurs</div>`,
                element:alerts
              },
              {
                intro:`<div class='contentHead'>Chart layout</div>
                <div class='contentBody'>Upto 16 charts can be displayed and synchronize settings between charts</div>`,
                element:chart_layout
              },
              {
                intro:`<div class='contentHead'>Save chart</div>
                <div class='contentBody'>Save or manage chart layouts</div>`,
                element:save_load
              },
              {
                intro:`<div class='contentHead'>Settings</div>
                <div class='contentBody'>Control your trading appearance or other chart settings</div>`,
                element:setting
              },
              {
                intro:`<div class='contentHead'>Watchlists</div>
                <div class='contentBody'>Create and manage your watchlists from here</div>`,
                element:watchlist
              },
              {
                intro:`<div class='contentHead'>Market Depth</div>
                <div class='contentBody'>Check the market depth of symbol</div>`,
                element:dom_button
              },
              {
                intro:`<div class='contentHead'>Buy/Sell</div>
                <div class='contentBody'>Click on red and blue buttons to sell and buy respectively,enable instant order placement from settings to place orders in one click</div>`,
                element:buy_sell
              },
              {
                intro:`<div class='contentHead'>Feedback</div>
                <div class='contentBody'>Tell us about your experience or any concern</div>`,
                element:feedback
              },
              {
                intro:`<div class='contentHead'>Drawing tools</div>
                <div class='contentBody'>50+ drawing tools to help you draw and identify market trends</div>`,
                element:drawing_tools
              },
              {
                intro:`<div class='contentHead'>Account manager</div>
                <div class='contentBody'>See all your orders and its status </div>`,
                  element:account_manager
              },
          ]
        });
        intro2.start();
        intro2.onexit(()=>{
            window.GtmPusher({
                event: 'tv-walkthrough-elements',
                customEventProperties: {
                    status:"close",
                    step:intro2.currentStep()+1
                }
            });
            setCookie('introjs-dontShowAgain',true,365)
        })
        intro2.oncomplete(()=>{
            window.GtmPusher({
                event: 'tv-walkthrough-elements',
                customEventProperties: {
                    status:"done",
                    step:intro2.currentStep()+1
                }
            });
            setCookie('introjs-dontShowAgain',true,365)
        })
	}

    const handleOnClose = ()=>{
        window.GtmPusher({
            event: 'tv-walkthrough-main',
            customEventProperties: {
                status:"close",
                step:currentIndex+1
            }
        });
        setWalkThrough(false)
        elementWalkthrough()
    }

    const eachwalkthroughstart = ()=>{
        window.GtmPusher({
            event: 'tv-walkthrough-main',
            customEventProperties: {
                status:"done",
                step:currentIndex+1
            }
        });
        setWalkThrough(false)
        elementWalkthrough()
    }

        if(walkThrough){
            return(
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='walkthrough'>
                        <div className='header'>
                                <div className='walkheader'>Welcome Onboard </div>
                                <div className='closeWalk c-pointer' onClick={handleOnClose}><CloserIcon/></div>
                        </div>
                            <div className="text-carousel">
                                <div className="carousel-content">
                                    <div className='contentHead'>{carouselItems[currentIndex].title}</div>
                                    {<React.Fragment>
                                        <iframe className='iframe' src={carouselItems[currentIndex].iframe}
                                         title="Rupeezy Tutorial"
                                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                         allowFullScreen={true}></iframe>
                                         <ul className='mt-0 pl-4' style={{"listStyleType":"disc","height":"23vh","msOverflowY":"scroll"}}>
                                        {carouselItems[currentIndex].content.map((d)=>{
                                            return(
                                                <li key={d} className='contentBody'>{d}</li>
                                            )
                                                })
                                        }  
                                        </ul>                                 
                                    </React.Fragment>}
                                </div>
                            </div>
                        <div className='walkfooter'>
                          <div className="carousel-dots">
                                      {carouselItems.map((item, index) => (
                                      <span
                                          key={index}
                                          className={`dot ${index === currentIndex ? 'active' : ''}`}
                                          onClick={() => handleDotClick(index)}
                                      ></span>
                                      ))}
                                  </div>
                            {startWalkthrough?<div onClick={eachwalkthroughstart} className='StartWalkthrough c-pointer'>Start Walkthrough</div>:<div onClick={handleNextButton} className='nextButton c-pointer'>Next</div>}
                        </div>
                    </div>
                </React.Fragment>
            )
        }else{
          return(
            null
          )
        }
}

export default WalkThrough