import React from "react";
import { TradeDirection } from "../gttTypes";
import "./toggleButton.scss";

export type ToggleButtonDirectionProps = {
  disabled?: boolean;
  toggleTradeDirection: (direction: TradeDirection) => void;
  tradeDirection: TradeDirection;
};

const ToggleButtonDirection: React.FC<ToggleButtonDirectionProps> = ({
  disabled,
  toggleTradeDirection,
  tradeDirection,
}) => {
  return (
    <div style={{height:40, opacity : disabled ? .7 : 1,cursor: disabled ? "not-allowed" : "pointer"}} className={`toggle-background p-1 rounded-pill me-3`}>
      <div >
        <button
          style={{ fontSize: "14px",cursor: disabled ? "not-allowed" : "pointer"}}
          type="button"
          className={`btn-alert me-1 py-2 px-4 font-14 border-0 ${
            tradeDirection === "Buy" ? "button-sucess fw-bolder" : "button-unslected"
          }`}
          onClick={() => toggleTradeDirection("Buy")}
        >
          Buy
        </button>
        <button
          style={{ fontSize: "14px" ,cursor: disabled ? "not-allowed" : "pointer"}}
          type="button"
          className={`btn-alert border-0 py-2 px-4  font-14 ${
            tradeDirection === "Sell"
              ? "button-sell fw-bolder"
              : "button-unslected"
          }`}
          onClick={() => toggleTradeDirection("Sell")}
        >
          Sell
        </button>
      </div>
    </div>
  );
};

export default ToggleButtonDirection;
