import React, { useState, FormEvent, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import AlertContainer from "../AlertContainer";
import { Alerts } from "../AlertTools";
import { DataFeedInstance } from "../../../chart-datafeed/udf-compatible-datafeed";
import nextId from "react-id-generator";
import { Quotes } from "../../../models/symbolData";
import useCreateAlert from "../../../hooks/api/create/useCreateAlert";
import useScripLookUp from "../../../hooks/api/get/getScripLookUp";
import MarketSegmentDetails from "../common/MarketSegmentDetails";
import AlertNameInput from "../common/AlertNameInput";
import PropertyDropdown from "../common/PropertyDropdown";
import NoteInput from "../common/NoteInput";
import ValueInput from "../common/ValueInput";
import LastTradedPriceInput from "../common/LastTradedPriceInput";
import { SearchArrayType } from "../common/SearchResultsDropdown";
import ConditionDropdown from "../common/ConditionDropdown";
import {
  createFormHandlers,
  resetFormData,
} from "../../../utils/alertHandlers";
import {
  getConditionOptions,
  getPropertyOptions,
} from "../../../utils/alertOptions";
import { productCode } from "../../../utils/alertsProductCode";
import BrokerApi from "../../../chart-brokerapi/broker";
import SearchSection from "../common/SearchSection";

import type { IsAlertFildTouched } from "../../../models/alertTypes";
import ErrorText from "../common/ErrorText";
import { Conditions } from "../../../helper/alert/condition";
import { useDispatch } from "react-redux";

import { toggleAlertsListModel } from "../../../slices/alertSlices";

interface CreateAlertsProps {
  handleAlertsTab: (alerts: Alerts) => void;
}

const CreateAlerts: React.FC<CreateAlertsProps> = ({ handleAlertsTab }) => {
  // State management
  const [formData, setFormData] = useState(resetFormData());
  const dispatch = useDispatch();

  const [istouched, setIsTouched] = useState<IsAlertFildTouched>({
    searchQuery: false,
    alertName: false,
    property: false,
    condition: false,
    valueInr: false,
    lastTradedPrice: false,
    note: false,
  });

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedToken, setSelectedToken] = useState<number | null>(null);
  const [selectedMarketSegmentId, setSelectedMarketSegmentId] = useState<
    number | null
  >(null);
  const [isnIsIndex, setIsnIsIndex] = useState<boolean>(false);
  const [selectedMarketSegment, setSelectedMarketSegment] = useState<
    Quotes["v"] | null
  >(null);
  const [priceValue, setPriceValue] = useState<number | null>(null);
  const [volume, setVolume] = useState<number | null>(null);
  const [serchType, setSearchType] = useState<SearchArrayType>("All");
  const hasBeenMountedRef = useRef(false);

  const { data, loading, error } = useScripLookUp({
    scripName: formData.searchQuery,
    productCode: productCode(serchType),
    serchType: serchType,
  });

  const { mutate, isLoading } = useCreateAlert();

  // Create form handlers
  const {
    handleInputChange,
    handleINRChange,
    handleDropdownChange,
    onLastTradedPriceChange,
    handleSearchResultSelect,
    handleNoteClear,
  } = createFormHandlers(
    formData,
    setFormData,
    volume,
    priceValue,
    setSelectedToken,
    setSelectedMarketSegmentId,
    setIsnIsIndex,
    setShowSearchResults,
    setIsTouched
  );

  const reSetInput = () => {
    setSelectedMarketSegmentId(null);
    setSelectedToken(null);
    setFormData(resetFormData());
    hasBeenMountedRef.current = false;
  };

  // Handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();

    const payload = {
      basketId: null,
      condition: formData.condition.trim(),
      marketSegmentId: selectedMarketSegmentId!,
      name: formData.alertName.trim(),
      note: formData.note.trim(),
      property: formData.property.trim(),
      triggerValue: parseFloat(formData.valueInr),
      token: selectedToken!,
    };

    mutate(payload, {
      onSuccess: () => {
        BrokerApi.instance?._host.showNotification(
          "Alert Created Successfully",
          `${formData.alertName} has been created successfully`,
          1
        );
        setFormData(resetFormData());
        setSelectedMarketSegmentId(null);
        setSelectedToken(null);
        handleAlertsTab("all-alerts");
        dispatch(toggleAlertsListModel(false));
      },
      onError: (error) => {
        BrokerApi.instance?._host.showNotification(
          error || "Something Went Wrong",
          `${formData.alertName} has not been created successfully`,
          0
        );
      },
    });
  };

  useEffect(() => {
    let listenerGuid: string | undefined;

    if (selectedToken && selectedMarketSegmentId) {
      listenerGuid = nextId();

      DataFeedInstance?.subcscribeQuotesFromTokens(
        [{ market_segment_id: selectedMarketSegmentId, token: selectedToken }],
        (quotes: Quotes[]) => {
          const selectedSegment = quotes[0].v;
          setSelectedMarketSegment(selectedSegment);
          if (!hasBeenMountedRef.current) {
            // Initial calculation of valueInr
            if (formData.property === "VOLUME") {
              setVolume(selectedSegment.volume); // Set volume
              setPriceValue(null); // Clear priceValue
              setFormData((prevFormData) => ({
                ...prevFormData,
                valueInr: selectedSegment.volume.toString(), // Use volume for valueInr
              }));
            } else {
              setPriceValue(selectedSegment.lp); // Set priceValue
              setVolume(null); // Clear volume
              setFormData((prevFormData) => ({
                ...prevFormData,
                valueInr: selectedSegment.lp.toString(), // Use price for valueInr
              }));
            }

            hasBeenMountedRef.current = true;
          }
        },
        listenerGuid
      );
    }

    return () => {
      if (listenerGuid) {
        DataFeedInstance?.unsubscribeQuotes(listenerGuid);
      }
    };
  }, [selectedToken, selectedMarketSegmentId]);

  useEffect(() => {
    if (selectedMarketSegment) {
      if (formData.property === "VOLUME") {
        setVolume(selectedMarketSegment.volume); // Update volume
        setPriceValue(null); // Clear priceValue
        setFormData((prevFormData) => ({
          ...prevFormData,
          valueInr: selectedMarketSegment.volume.toString(), // Use volume for valueInr
        }));
      } else {
        setPriceValue(selectedMarketSegment.lp); // Update priceValue
        setVolume(null); // Clear volume
        setFormData((prevFormData) => ({
          ...prevFormData,
          valueInr: selectedMarketSegment.lp.toString(), // Use price for valueInr
        }));
      }
    }
  }, [formData.property]);

  const propertyOptions = getPropertyOptions(isnIsIndex);
  const conditionOptions = getConditionOptions(formData.property);

  const isValid = !!formData.valueInr && ((formData.property !== "VOLUME" && +formData.valueInr >= selectedMarketSegment?.lp!) || (formData.property === "VOLUME" && +formData.valueInr >= selectedMarketSegment?.volume!));

  const hasNoEmptyFields = Object.entries(formData).some(([key, field]) => {
    // Check if the field is an empty string and the key is NOT one of the excluded fields
    return field === "" && !["note"].includes(key);
  });

  const isAnyFieldEmptyFromFormData = hasNoEmptyFields || !isValid;

  return (
    <>
      <AlertContainer>
        <div className="px-5 py-4">
          <Form onSubmit={handleSubmit}>
            {!selectedToken && !selectedMarketSegmentId ? (
              <SearchSection
                formData={formData}
                handleInputChange={handleInputChange}
                setShowSearchResults={setShowSearchResults}
                showSearchResults={showSearchResults}
                reSetInput={reSetInput}
                error={error}
                serchType={serchType}
                setSearchType={setSearchType}
                loading={loading}
                data={data}
                handleSearchResultSelect={handleSearchResultSelect}
              />
            ) : (
              <MarketSegmentDetails
                data={data!}
                isEditable={true}
                formData={formData}
                selectedMarketSegment={selectedMarketSegment!}
                reSetInput={reSetInput}
              />
            )}

            <AlertNameInput
              label="Alert Name"
              value={formData.alertName}
              onChange={handleInputChange}
              disabled={false}
              error={
                !formData.alertName && istouched.alertName
                  ? "Alert name is required"
                  : undefined
              }
            />
            {!formData.alertName && istouched.alertName && (
              <ErrorText message={"Alert name is required"} />
            )}

            <PropertyDropdown
              label="Property"
              value={formData.property}
              propertyOptions={propertyOptions}
              onChange={handleDropdownChange}
              disabled={!formData.alertName || loading}
            />

            {!formData.property && istouched.property && (
              <ErrorText message={"Please select a property"} />
            )}

            <ConditionDropdown
              formData={formData}
              loading={loading}
              conditionOptions={conditionOptions}
              handleDropdownChange={handleDropdownChange}
            />

            {!formData.condition && istouched.condition && (
              <ErrorText
                message={
                  "Please select a condition eg. Greate Than or Equal or Less Than"
                }
              />
            )}

            <div className="d-flex gap-2 mt-4 align-items-center">
              <div className="flex-grow-1">
                <ValueInput
                  property={formData.property as Conditions}
                  value={formData.valueInr}
                  onChange={handleINRChange}
                  disabled={!formData.condition || loading}
                  error={
                    !formData.valueInr && istouched.valueInr
                      ? "Please enter a Value": undefined
                  }
                />
                {!formData.valueInr && istouched.valueInr ? (
                  <ErrorText message="Please enter a Value" />
                ) : formData.property === "VOLUME" ? (
                  +formData.valueInr < selectedMarketSegment?.volume! ? (
                    <ErrorText
                      message={`Volume should be > ${selectedMarketSegment?.volume}`}
                    />
                  ) : undefined
                ) : +formData.valueInr < selectedMarketSegment?.lp! ? (
                  <ErrorText
                    message={`Price should be > ${selectedMarketSegment?.lp}`}
                  />
                ) : undefined}
              </div>
              <strong
                className={`${
                  !isAnyFieldEmptyFromFormData || !istouched.valueInr ? "pt-5" : ""
                } text-color`}
              >
                Or
              </strong>
              <div className="flex-grow-1">
                <LastTradedPriceInput
                  property={formData.property as Conditions}
                  value={formData.lastTradedPrice}
                  onChange={onLastTradedPriceChange}
                  disabled={!formData.condition || loading}
                  error={
                    !formData.lastTradedPrice && istouched.lastTradedPrice
                      ? "Please enter a Value"
                      : undefined
                  }
                />
                {!formData.valueInr && istouched.valueInr ? (
                  <ErrorText message="Please enter a Value" />
                ) : formData.property === "VOLUME" ? (
                  +formData.valueInr < selectedMarketSegment?.volume! ? (
                    <ErrorText
                      message={`Volume should be > ${selectedMarketSegment?.volume}`}
                    />
                  ) : undefined
                ) : +formData.valueInr < selectedMarketSegment?.lp! ? (
                  <ErrorText
                    message={`Price should be > ${selectedMarketSegment?.lp}`}
                  />
                ) : undefined}
              </div>
            </div>

            <NoteInput
              onClear={handleNoteClear}
              value={formData.note}
              onChange={handleInputChange}
              disabled={!formData.condition || loading}
            />
          </Form>
        </div>
      </AlertContainer>
      <div
        style={{ height: 60 }}
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
      >
        <button
          type="button"
          className="btn-alert secondary"
          onClick={() => handleAlertsTab("all-alerts")}
        >
          Cancel
        </button>
        <button
          disabled={isAnyFieldEmptyFromFormData || isLoading}
          style={{
            cursor: isAnyFieldEmptyFromFormData ? "not-allowed" : "pointer",
            opacity: isAnyFieldEmptyFromFormData ? 0.7 : 1,
          }}
          type="button"
          className="btn-alert primary"
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>
    </>
  );
};

export default CreateAlerts;
