export function getSortName(name: string): string | undefined {
    const data: { [key: string]: string } = {
      EQUITIES: "EQ",
      OPTSTK: "OPT",
      FUTSTK: "FUT",
      OPTIDX: "OPT",
      OPTCUR: "OPT",
      FUTCOM: "FUT",
      FUTCUR: "FUT",
      FUTIDX: "FUT",
      OPTFUT: "OPT",
    };
  
    return data[name]; // This will return a string or undefined if name is not found
  }