import { Quotes } from "../../../models/symbolData";
import "./priceflucation.scss";

type PriceFluctuationsProbs = Pick<Quotes["v"], "lp" | "chp">;

const PriceFluctuations: React.FC<PriceFluctuationsProbs> = ({ lp, chp }) => {
  const isNegative = lp < 0 && chp < 0;
  return (
    <span
      className={
        isNegative
          ? "price-fluctuations-negative"
          : "price-fluctuations-positive"
      }
    >
      {/* {isNegative ? "-" : "+"} */}
      {lp.toFixed(2)}{' '}
      ({chp.toFixed(2)}%)
    </span>
  );
};

export default PriceFluctuations;
