import { OptionTimeframe } from "../gttTypes";


type GttBodySelectorProp = {
  marketSegmentId: string; // "Stocks" | "Index"
  choserString: string; 
};

export const GttBodyTabsSelectors = ({
  marketSegmentId,
  choserString
}: GttBodySelectorProp): OptionTimeframe[] => {
  const segmentId = Number(marketSegmentId); // Convert marketSegmentId to a number
  
  if ([1, 3].includes(segmentId)) {
    return ["Intraday", "Pay Later", "Intraweek", "Delivery"];
  } else if ([2, 4, 5].includes(segmentId) && ['OPTIDX','FUTIDX','FUTCOM'].includes(choserString)) {
    return ["Intraday", "Caryforward"];
  }else if ([2, 4].includes(segmentId) && ['OPTSTK','FUTSTK'].includes(choserString) ) {
    return [ "Caryforward"];
  }
  // Return an empty array if none of the conditions match
  return [];
};


// Define the ExchangeTypes enum
export enum ExchangeTypes {
  NSEFO = "NSE_FO",
  NSEEQUITY = "NSE_EQ",
  NSECURRENCY = "NSE_CUR",
  NSECURRENCYSpot = "NSE_CUR_SPOT",
  MCX = "MCX_FO",
  MCXSpot = "MCX_FO_SPOT",
  BSEFO = "BSE_FO",
  BSEEQUITY = "BSE_EQ"
}

// Inverse function to map ExchangeTypes to input string
export const getInstrumentType = (exchangeType: ExchangeTypes): string | undefined => {
  switch (exchangeType) {
    case ExchangeTypes.NSEEQUITY:
      return "nse";
    case ExchangeTypes.NSEFO:
      return "nfo";
    case ExchangeTypes.MCX:
      return "mcx";
    case ExchangeTypes.NSECURRENCY:
      return "cds";
    case ExchangeTypes.BSEFO:
      return "bfo";
    case ExchangeTypes.BSEEQUITY:
      return "bse";
    default:
      return undefined; // If input doesn't match any case
  }
};
