import React from 'react';
import './exchnagepill.scss';

interface ExchangePillProps {
  text?: string;        // `text` is optional and defaults to 'NSE'
  className?: string;   // `className` is optional and defaults to ''
}

const ExchangePill: React.FC<ExchangePillProps> = ({ text = 'NSE', className = '' }) => {
  return (
    <span className={`px-1 padding_y_2 exchnage_pill_bg font-10 text-contrast br-2 ${className}`}>
      {text}
    </span>
  );
}

export default ExchangePill;
