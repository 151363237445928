import React, {useEffect,useState,useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import authService from "../../services/authService";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {numDifferentiation, segmentToInstrumentType} from "../../comman/status";
import './NewOptionModel.scss'
import OptionChainHeader from "./OptionChainHeader"
import SpotPrice from './SpotPrice';
import AddFilter from "./AddFilter"
import CallData from "./CallData"
import PutData from "./PutData"
import {Table,Row,Col} from "react-bootstrap";
import Draggable from "react-draggable";
import moment from 'moment'
import {ReactComponent as CloserIcon} from "../../assets/images/close-square2.svg";
import {ReactComponent as AddFilterImage} from "../../assets/images/filter-add.svg";
import {ReactComponent as Refresh} from "../../assets/images/refresh-2.svg";
import {ReactComponent as InfoCircle} from "../../assets/images/info-circle.svg";
import {CloseOptionModel,openOptionChain} from "../../slices/optionChainSlice";
import {UserPreferenceResponse,OptionChainPreferences,UserPreferenceData,OptionDataRequest,OptionResponse,OptionResponseData} from '../../models/optionChain'
import {ResolveSymbolInfo} from '../../models/symbolData'
import {RootState} from "../../store"
import {ExchangeSegmentMap} from "../common/number_color";


import LoadingSpinner from "./LoadingSpinner"

interface OptionChainProps {
    optionData: any;
    userdata:any; // Example type, adjust based on the actual type of kraStatus
  }
interface Keys {
    key: string;
    value: string;
}

const NewOptionModel:React.FC<OptionChainProps> = ({optionData,userdata}) => {
    let userPreferenceResponse: UserPreferenceResponse
    const { new_model_open,option_key,socket_open,user} = optionData
    // const {subscribedOptions} = useSelector((state:RootState) => state.optionChain);

    const dispatch = useDispatch();
    // const user = useSelector(state => state.authUser.user);
    const [ OptionChainData, setOptionChainData ] = useState<OptionResponseData | null>(null)
    const [AllOptionChainData,setAllOptionChaindata]=useState<OptionResponseData | null>(null)
    const [ keys, setKeys ] = useState<Keys[]>([])
    const [selectedExpiryDate,setSelectedExpiryDate] = useState<string | null>(null)
    const [scripName,setScripName]=useState<string | null>(null)
    const [exchange,setExchange]=useState<string|null>(null)
    const [token,setToken]=useState<number | null>(null)
    const [InitialTargetIndex,setInitialTargetIndex]=useState<number>(0)
    const [ stockArray, setStockArray ] = useState<number[]>([])
    const [userPreferences,setUserPreferences]=useState<OptionChainPreferences | null>(null)
    const [isToggled,setIsToggled]=useState<boolean | null>(true)
    const [gAboveAtm,setGAboveAtm]=useState<number | null>(null)
    const [gBelowAtm,setGBelowAtm]=useState<number | null>(null)
    const [aboveAtmCount,setAboveAtmCount] = useState<number | null>(20)
    const [belowAtmCount,setBelowAtmCount] = useState<number | null>(20)
    const [preferenceResponse,setPreferenceResponse] = useState<UserPreferenceData | null>(null)
    const [optionDarkTheme,setOptionDarkTheme]=useState(null)
    const [darkClass,setDarkClass] = useState('light')
    const [vegaStatus,setVegaStatus]=useState<string|null>(null)
    const [thetaStatus,setThetaStatus]=useState<string|null>(null)
    const [gammaStatus,setGammaStatus]=useState<string|null>(null)
    const [deltaStatus,setDeltaStatus]=useState<string|null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [livePrice,setLivePrice] = useState<number | null>(null)
    const [optionChainAvailable,setOptionChainAvailable] = useState<number | null>(null)
    const [filteringRequired,setFilteringRequired] = useState(false)
    const [subscribedOptions,setSubscribedOptions] = useState<Set<string> | null>(null)


    useEffect(() => {
        if (option_key) showOrderDialog()
        return () => {
        }
    }, [])

    useEffect(() => {
        getOptionUserPreference()
        return () => { 
        }
    }, [])
    useEffect(()=>{
        if(aboveAtmCount) changeStateOfAbove()
    },[aboveAtmCount])
    useEffect(()=>{
        if(belowAtmCount) changeStateOfBelow()
    },[belowAtmCount])

    useEffect(()=>{
        if(AllOptionChainData && optionChainAvailable) findIndex()
        return () => {
        }
    },[AllOptionChainData])


    useEffect(()=>{
        if(OptionChainData && optionChainAvailable) getOptionChainData()

    },[OptionChainData])
    useEffect(()=>{
        if(AllOptionChainData && optionChainAvailable) filterAboveBelowATM(aboveAtmCount as number,belowAtmCount as number)
    },[InitialTargetIndex])

    useEffect(()=>{
        if(userPreferences){
            if(userPreferences.greeks.vega==false){
                setVegaStatus('hidden')
            }
            if(userPreferences.greeks.theta==false){
                setThetaStatus('hidden')
            }
            if(userPreferences.greeks.gamma==false){
                setGammaStatus('hidden')
            }
            if(userPreferences.greeks.delta==false){
                setDeltaStatus('hidden')
            }
        }
    },[userPreferences])

    function resetAtmValues(){
        setAboveAtmCount(gAboveAtm)
        setBelowAtmCount(gBelowAtm)
        filterAboveBelowATM(gAboveAtm as number,gBelowAtm as number)
    }

    function getLivePrice(price:number){
        setLivePrice(price)
    }

    function getOptionUserPreference(){
        const data = {}
        flowBackEndServices2.getOptionUserPreferences(data,userdata.access_token)
        .then( (value) => {
            if(value.status==200){
                userPreferenceResponse=value.data
                const response:UserPreferenceData = userPreferenceResponse.response
                if (response){
                    setPreferenceResponse(response)
                    if(response.optionChainPreferences){
                        setUserPreferences(response.optionChainPreferences)
                        setAboveAtmCount(response.optionChainPreferences.strikesAboveATM)
                        setBelowAtmCount(response.optionChainPreferences.strikesBelowATM)
                        setGAboveAtm(response.optionChainPreferences.strikesAboveATM)
                        setGBelowAtm(response.optionChainPreferences.strikesBelowATM)
                    }
                }
            }
        }).catch(reason=>{
            console.log("error occurred in calling user pref service - "+reason)
        })
    }

    function changeVegaStatus(show:boolean){
        if(show){
            setVegaStatus('')
        }else{
            setVegaStatus('hidden')
        }
    }
    function changeThetaStatus(show:boolean){
        if(show){
            setThetaStatus('')
        }else{
            setThetaStatus('hidden')
        }
    }
    function changeGammaStatus(show:boolean){
        if(show){
            setGammaStatus('')
        }else{
            setGammaStatus('hidden')
        }
    }
    function changeDeltaStatus(show:boolean){
        if(show){
            setDeltaStatus('')
        }else{
            setDeltaStatus('hidden')
        }
    }

    async function updateUserPreferences(showVega:boolean,showDelta:boolean,showTheta:boolean,showGamma:boolean){
        let prefdata = {...preferenceResponse}
        const element = document.getElementById("option_filter") as HTMLElement | null;
        if (element) {
            element.style.display="none"
        }
        if (prefdata.optionChainPreferences){
            prefdata.optionChainPreferences.greeks.vega = showVega
            prefdata.optionChainPreferences.greeks.theta = showTheta
            prefdata.optionChainPreferences.greeks.gamma = showGamma
            prefdata.optionChainPreferences.greeks.delta = showDelta
    
            prefdata.optionChainPreferences.strikesAboveATM = aboveAtmCount
            prefdata.optionChainPreferences.strikesBelowATM = belowAtmCount
            prefdata.optionChainPreferences.showStrikesZeroOI = isToggled
        }

        flowBackEndServices2.setUseroptionPreferences(prefdata,userdata.access_token)
        
        .then( (value) => {
            if(value.status==200){
                if(prefdata.optionChainPreferences){
                    setUserPreferences(prefdata.optionChainPreferences)
                    setGAboveAtm(prefdata.optionChainPreferences.strikesAboveATM)
                    setGBelowAtm(prefdata.optionChainPreferences.strikesBelowATM)
                }
            }
        }).catch(reason=>{
            console.log("error occurred in calling update user pref service - "+reason)
        })
        

    }


    function changeStateOfAbove(){
        const a10 = document.getElementById("A10")
        const a20 = document.getElementById("A20")
        const a30 = document.getElementById("A30")

        const a10cls = a10?.className
        const a20cls = a20?.className
        const a30cls = a30?.className

        if(aboveAtmCount==10){
            a10?.classList.replace('Atm_Values','active_filter')
            if(a20cls=='active_filter'){
                a20?.classList.replace('active_filter','Atm_Values')
            }
            if(a30cls=='active_filter'){
                a30?.classList.replace('active_filter','Atm_Values')
            }
        }else if(aboveAtmCount==20){
            a20?.classList.replace('Atm_Values','active_filter')
            if(a10cls=='active_filter'){
                a10?.classList.replace('active_filter','Atm_Values')
            }
            if(a30cls=='active_filter'){
                a30?.classList.replace('active_filter','Atm_Values')
            }
        }else if(aboveAtmCount==30){
            a30?.classList.replace('Atm_Values','active_filter')
            if(a20cls=='active_filter'){
                a20?.classList.replace('active_filter','Atm_Values')
            }
            if(a10cls=='active_filter'){
                a10?.classList.replace('active_filter','Atm_Values')
            }
        }
    }

    function changeStateOfBelow(){
        const b10 = document.getElementById("B10")
        const b20 = document.getElementById("B20")
        const b30 = document.getElementById("B30")

        const b10cls = b10?.className
        const b20cls = b20?.className
        const b30cls = b30?.className

        if(belowAtmCount==10){
            b10?.classList.replace('Atm_Values','active_filter')
            if(b20cls=='active_filter'){
                b20?.classList.replace('active_filter','Atm_Values')
            }
            if(b30cls=='active_filter'){
                b30?.classList.replace('active_filter','Atm_Values')
            }
        }else if(belowAtmCount==20){
            b20?.classList.replace('Atm_Values','active_filter')
            if(b10cls=='active_filter'){
                b10?.classList.replace('active_filter','Atm_Values')
            }
            if(b30cls=='active_filter'){
                b30?.classList.replace('active_filter','Atm_Values')
            }
        }else if(belowAtmCount==30){
            b30?.classList.replace('Atm_Values','active_filter')
            if(b20cls=='active_filter'){
                b20?.classList.replace('active_filter','Atm_Values')
            }
            if(b10cls=='active_filter'){
                b10?.classList.replace('active_filter','Atm_Values')
            }
        }
    }

    function showOrderDialog(){
        DataFeedInstance?.resolveSymbol(option_key, (symbolInfo:ResolveSymbolInfo)=>{
            var scrip_name = symbolInfo.odin_symbol
            setExchange(String(symbolInfo.odin_exchange)) 
            setToken(parseInt(String(symbolInfo.odin_token)))
            setScripName(String(scrip_name))
            getOptionData(String(symbolInfo.odin_exchange),parseInt(String(symbolInfo.odin_token)), String(scrip_name))
        }, ()=>{}, undefined)

    }

    const chooseExpDate = (selectedExpiryDate:string)=>{
        setFilteringRequired(true)
        const parsedDate = parseInt(selectedExpiryDate);
        getOptionData(exchange,token,scripName,parsedDate)
        setSelectedExpiryDate(selectedExpiryDate);
        scrollToSpotPrice()
    }
    const scrollToSpotPrice = ()=>{
        setTimeout(() => {
            let targetRow = document.getElementById("spot_price_live")
            if(targetRow){
                targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
            }
        },0)
       } 

    function getOptionData(exchange:string | null,token:number | null,scrip_name:string | null,ExpDate:number=0) {
        dispatch(openOptionChain())
        const data:OptionDataRequest = {symbol: scrip_name,token: token,exchange: exchange,expiry_date:ExpDate?ExpDate.toString():"",greeks:true}
        setIsLoading(true)
        return authService.newOptionChaindata(data,user.access_token).then(value => {
            return value.data
        }).then((data)=>{
            const finalResponse:OptionResponse=data
            if(finalResponse.status==="success"){
                const tempMyObj = Object.assign({}, finalResponse.response);
                // setOptionChainData(data)
                setAllOptionChaindata(tempMyObj)
                MakeDateFormate(data.response.expiry_dates)
                setOptionChainAvailable(1)
                setIsLoading(false)
            }else{
                setOptionChainAvailable(0)
                setVegaStatus('hidden')
                setGammaStatus('hidden')
                setDeltaStatus('hidden')
                setThetaStatus('hidden')
                setIsLoading(false)
            }


        }).catch(reason => {
            console.log("Error in option datat",reason)
            setIsLoading(false)
            // onModelClose()
        })

    }

    function findIndex(){
        let targetIndex:number=0
        if(AllOptionChainData){
            if(AllOptionChainData.options?.list){
                for (const [key, value] of Object.entries(AllOptionChainData.options.list)) {
                    if(value.strike_price && AllOptionChainData.parent?.ltp){
                        if(value.strike_price-AllOptionChainData.parent.ltp>0){
                            targetIndex = parseInt(key)
                            break;
                        }  
                    }
                  }
            }
        }
        let array: number[] = [];
          if(AllOptionChainData){
            if(AllOptionChainData.options?.list){
                AllOptionChainData.options.list.map((key,value)=>{
                    array.push(key.strike_price)
                })
            }
          }
            setStockArray(array)
            setInitialTargetIndex(targetIndex)
    }

    function changePlaceOfSpotprice(){
        let strikePrice = stockArray[InitialTargetIndex]
        let dynamic_spot_element = document.getElementById("spot_price_live")

        let targetRow = document.getElementById(strikePrice.toString())
        let table = document.getElementById("mainbody")

        if (dynamic_spot_element && targetRow){
            table?.insertBefore(dynamic_spot_element,targetRow);
        }
    }
    function filterAboveBelowATM(aboveATM:number,belowATM:number){
        setFilteringRequired(false)
        if(InitialTargetIndex==0){
            setOptionChainData(AllOptionChainData)
            setAboveAtmCount(aboveATM)
            setBelowAtmCount(belowATM)
        }else{
            if(AllOptionChainData){
                if(AllOptionChainData.options?.list){
                    if((aboveATM+belowATM)<=AllOptionChainData.options.list.length){
                        let datas = JSON.parse(JSON.stringify(AllOptionChainData))
                        datas.options.list = AllOptionChainData.options.list.slice(InitialTargetIndex-aboveATM,InitialTargetIndex+belowATM)
        
                        if(datas.options.list.length>0){
                            setOptionChainData(datas)
                        }
                    }else{
                        if(aboveATM<=Math.floor(AllOptionChainData.options.list.length/2)){
                            let datas = {...AllOptionChainData}
                            if(datas.options){
                                datas.options.list = AllOptionChainData.options.list.slice(InitialTargetIndex-aboveATM)
            
                                if(datas.options.list.length>0){
                                    setOptionChainData(datas)
                                }
                            }
                        }else if(belowATM<=Math.floor(AllOptionChainData.options.list.length/2)){
                            let datas = {...AllOptionChainData}
                            if(datas.options){
                                datas.options.list = AllOptionChainData.options.list.slice(0,InitialTargetIndex+belowATM)
            
                                if(datas.options.list.length>0){
                                    setOptionChainData(datas)
                                }
                            }
                        }else{
                            setOptionChainData(AllOptionChainData)
                        }
                    }
                }
            }

            setAboveAtmCount(aboveATM)
            setBelowAtmCount(belowATM)
        }
    
    }

    function OIToggled(){
        // getOptionChainData("from OI Toggle")
        setIsToggled(!isToggled)
    }
    const newSubscribedOptions = new Set<string>();

    function getOptionChainData(){
        if(filteringRequired)(
            filterAboveBelowATM(aboveAtmCount as number,belowAtmCount as number)
        )
        changePlaceOfSpotprice()
        if(newSubscribedOptions.size>0){
            newSubscribedOptions?.forEach((key:string) => {
                    DataFeedInstance?.unsubscribeQuotes(key);
                });
                newSubscribedOptions?.clear();
            }
        if(OptionChainData){
            OptionChainData.options?.list?.forEach((key) => {
                const pe: string = `pe-${key.PE?.token}-${ExchangeSegmentMap[OptionChainData.options?.exchange as string]}`;
                const ce: string = `ce-${key.CE?.token}-${ExchangeSegmentMap[OptionChainData.options?.exchange as string]}`;
                newSubscribedOptions?.add(pe);
                newSubscribedOptions?.add(ce);
            });
            if(OptionChainData.options){
                return OptionChainData.options.list?.map((key)=>{
                        var strikePrice = key.strike_price
                        return(
                                <tr key={strikePrice} id={strikePrice.toString()} data-name="optionRows">
                                    <CallData data={{symbol:OptionChainData.parent?.symbol,exchange: OptionChainData.options?.exchange,  option: key.CE, greeks: {gamma: key.gamma, vega: key.vega, theta: key.theta, iv: key.iv}}} sp={strikePrice} showZeroOi={isToggled} vega={vegaStatus} theta={thetaStatus} gamma={gammaStatus} delta={deltaStatus}/>
                                    <td id="strike_price_border" className={`${darkClass}`}>
                                        <Row className='flex-nowrap'>
                                            <Col className={`chainValue ${darkClass}`} style={{"width":"95px","textAlign":"center"}}>{strikePrice}</Col>
                                        </Row>
                                    </td>
                                    <td id="strike_price_border" className={`${darkClass}`}>
                                        <Row className='flex-nowrap'>
                                            <Col className={`chainValue ${darkClass}`}  style={{"width":"95px","textAlign":"center"}}>{key.iv?.toFixed(2)}</Col> 
                                        </Row>
                                    </td>
                                    <PutData data={{symbol:OptionChainData.parent?.symbol,exchange: OptionChainData.options?.exchange,  option: key.PE, greeks: {gamma: key.gamma, vega: key.vega, theta: key.theta, iv: key.iv}}} sp={strikePrice} showZeroOi={isToggled} vega={vegaStatus} theta={thetaStatus} gamma={gammaStatus} delta={deltaStatus}/>
                                </tr>
                        )
                })
            }
        }
    }

    function MakeDateFormate(keysData: string[] = []) {
        const val:Keys[] = keysData.map(d => {
               let now = moment(d, "YYYYMMDD").format('DD MMM');
               return {
                   key: now,
                   value: d,
               }
           })
           setKeys(val)
           return 
       }

    function getStyle() {
        let data = {}
         new_model_open ?  data = { display: 'block', visibility: 'visible' } : data = { display: 'none', visibility: 'hidden' }
       return data
     }

    function getExpiryDates(){
        return(
                <form className="d-flex flex-row w-100 justify-content-start mt-3">
                    <div className={`selectDate ${darkClass}`}>Select Date: </div>
                    <div className='ml-1'>
                        <select className={`form-select form-select-sm ${darkClass}`} onChange={handleChange}>
                        {
                            keys.map(d => {
                                        return(
                                            <option key={d.value} value={d.value} className='option'>{d.key}</option>
                                        )
                                    })
                                }
                        </select>
                    </div>
                </form>
        )
    }

    function openFilter(){
        const element = document.getElementById("option_filter") as HTMLElement | null;
        if (element){
            element.style.display="block"
        }
        return element
    }

    const handleChange = (e:any) => {
        chooseExpDate(e.target.value)
    }

    const onModelClose = () => {
        if(newSubscribedOptions.size>0){
            newSubscribedOptions?.forEach((key:string) => {
                    DataFeedInstance?.unsubscribeQuotes(key);
                });
                newSubscribedOptions?.clear();
            }
        dispatch(CloseOptionModel())
    }

    function getspotPrice(){
        return(
            <SpotPrice stockarray={stockArray} darkLightClass={darkClass}/>
        )
    }

    function getrefreshedData(){
        getOptionData(exchange,token,scripName,parseInt(selectedExpiryDate as string)).then(()=>{
            filterAboveBelowATM(aboveAtmCount as number,belowAtmCount as number)
        }).then(()=>{
            setTimeout(() => {
                let targetRow = document.getElementById("spot_price_live")
                if(targetRow){
                    targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
                }
            },2000)
        })
        
        
    }

    function getOptionChainBody(){
        if(optionChainAvailable===1){
            return(
                <tbody id="mainbody">
                    {OptionChainData && getspotPrice()}
                    {OptionChainData && getOptionChainData()}
                </tbody>
            )
        }else{
            return(
                <tbody>
                    <tr>
                        <td colSpan={4}>
                            <div className='nodata'>Options are not available for {scripName}</div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td> 
                    </tr>
                </tbody>
            )
        }
    }

    function getEmptyHeader(){
        return(
            <div className='col-4 pl-4 py-2' style={{textAlign:'left'}}>
            <div>
                <div className='topSymbolName dark_theme'>{scripName}</div>
                <div className='exchange'>{''}</div>
            </div>
            <div>
                <div className='ltp'>{''}</div>
                <div className='ltp'></div>
                <div className='ltp'></div>
            </div>
        </div>
        )
    }


    return(
        <Draggable
        handle=".draggable"
        bounds="parent"
        allowAnyClick={true}>
        <div className={`${darkClass} optionChain`} style={{"display":"block"}} id="optionChainNew">
            <div className='draggable'>
                <div className='container'>
                    <div className={`row header justify-content-between ${darkClass}`}>
                    {/* { OptionChainData  ? <OptionChainHeader getlivePrice={getLivePrice} data={OptionChainData.parent} expDates={keys} chooseExpDate={chooseExpDate} optionChainData={getOptionData} scrip_name={scripName} token={token} exchange={exchange} ExpiryDate={selectedExpiryDate} darkLightClass={darkClass}/> : getEmptyHeader()} */}

                        { OptionChainData  ? <OptionChainHeader data={OptionChainData.parent} darkLightClass={darkClass}/> : getEmptyHeader()}
                        <div className='col-6 py-2' style={{textAlign:'left'}}>
                            <div className='row'>
                                <div className='col-7' style={{"paddingLeft":"20%"}}>
                                    {OptionChainData  ? OptionChainData.parent && getExpiryDates() : null}
                                </div>
                                <div className='col-5'>
                                    <div className='row mt-3'>
                                        <div className='col-3 c-pointer'><span className='pl-3 addFilter'>{optionChainAvailable?<AddFilterImage onClick={openFilter} />:''}</span></div>
                                        <div className='col-3 c-pointer'><span className='pl-2'>{<Refresh onClick={()=>getrefreshedData()}/>}</span></div>
                                        <div className='col-3'>
                                            <div className='tooltip1 '><span className='c-pointer'>{<InfoCircle />}</span>
                                                <div className="tooltiptext">
                                                    <div style={{"margin":"9px"}}>Know how we calculate IV and Greeks?</div>
                                                    <div style={{"marginBottom":"8px","paddingLeft":"14px","textAlign":"right"}}>
                                                        <span style={{"paddingRight":"24px"}} className='c-pointer'><a href='https://support.rupeezy.in/support/solutions/folders/21000000159' target={"blank"}><span className={`link ${darkClass}`}>Learn More</span></a></span> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 c-pointer'><span onClick={onModelClose} >{<CloserIcon/>}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 optionchaintable'>
                        <Table id="MAINTABLE" className={`${darkClass}`}>
                            <thead className={`${darkClass}`}>
                                <tr id="topHead">
                                    <th className={`${darkClass}`}>CALL</th>
                                    <th></th>
                                    <th></th>
                                    <th className={`${darkClass}`}>PUT</th>
                                </tr>
                                <tr>
                                    <th id="head_border">
                                        <Row className='flex-nowrap'>
                                            <Col className={`${vegaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 callheader`} name="vega" id="vega">Vega</Col>
                                            <Col className={`${thetaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 callheader`} name="theta" id="theta">Theta</Col>
                                            <Col className={`${gammaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 callheader`} name="gamma" id="gamma">Gamma</Col>
                                            <Col className={`${deltaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 callheader`} name="delta" id="delta">Delta</Col>
                                            <Col className={`optionChainHeader optionHeaderFormat p-0 callheader ${darkClass}`}>OI</Col>
                                            <Col className={`optionChainHeader optionHeaderFormat p-0 callheader ${darkClass}`} style={{"width":"115px"}}>OI (Chg%)</Col>
                                            <Col className={`optionChainHeader optionHeaderFormat p-0 callheader ${darkClass}`}>Volume</Col>
                                            <Col className={`optionChainHeader optionHeaderFormat p-0 callheader ${darkClass}`} style={{"width":"115px"}}>LTP (Chg%)</Col>
                                        </Row>
                                    </th>
                                    <th id="strike_price_border" className={`${darkClass}`}>
                                        <Row className='flex-nowrap'>
                                            <Col className={`optionChainHeader p-0 optionHeaderFormat ${darkClass}`}>Strike Price</Col>
                                        </Row>
                                    </th>
                                    <th id="strike_price_border" className={`${darkClass}`}>
                                        <Row className='flex-nowrap'>
                                            <Col className={`optionChainHeader optionHeaderFormat p-0 ${darkClass}`}>IV</Col>
                                        </Row>
                                    </th>
                                    <th id="head_border" className={`${darkClass}`}>
                                        <Row className='flex-nowrap'>
                                            <Col className={`${darkClass} optionChainHeader optionHeaderFormat p-0 putheader`} style={{"width":"115px"}}>LTP (Chg%)</Col>
                                            <Col className={`${darkClass} optionChainHeader optionHeaderFormat p-0 putheader`} >Volume</Col>
                                            <Col className={`${darkClass} optionChainHeader optionHeaderFormat p-0 putheader`} style={{"width":"115px"}}>OI (Chg%)</Col>
                                            <Col className={`${darkClass} optionChainHeader optionHeaderFormat p-0 putheader`} >OI</Col>
                                            <Col className={`${deltaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 putheader`} name="delta"  id="delta">Delta</Col>
                                            <Col className={`${gammaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 putheader`} name="gamma"  id="gamma">Gamma</Col>
                                            <Col className={`${thetaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 putheader`} name="theta"  id="theta">Theta</Col>
                                            <Col className={`${vegaStatus} optionChainHeader optionHeaderFormat ${darkClass} p-0 putheader`} name="vega"  id="vega">Vega</Col>
                                        </Row>
                                    </th>
                                </tr>
                            </thead>
                            {isLoading?<LoadingSpinner/>:getOptionChainBody()}
                            {/* {getOptionChainBody()} */}
                        </Table>
                    </div>
                </div>
            </div>
            <div id="option_filter" style={{"position":"absolute","top":"0px","zIndex":"100","right":"0px","display":"none"}} className={`${darkClass}`}>
                <AddFilter  preferences={userPreferences} filterFunction={filterAboveBelowATM} AboveATMCount={aboveAtmCount} BelowATMCount={belowAtmCount} updatePref={updateUserPreferences} reset={resetAtmValues} vegaFunction={changeVegaStatus} thetaFunction={changeThetaStatus} gammaFunction={changeGammaStatus} deltaFunction={changeDeltaStatus}/>
                {/* <div className="row mt-6">
                        <div className="col-8">
                            <div className={`showStrikes ${darkClass}`}>Show Strikes with Zero OI</div>
                        </div>
                        <div className="col-4" style={{"position":"relative"}}>
                            <div className="OIToggle">
                            <label>
                                <input type="checkbox" defaultChecked={isToggled} onClick={OIToggled} id="showOI"/>
                                <span className="toggle"/>
                            </label>
                            </div>
                        </div>
                    </div> */}
            </div>
        </div>
        </Draggable>
    )

}

export default NewOptionModel;