import { logMessage } from '../utils/helpers';
interface Headers {
    [key: string]: string; // Type for headers object (key-value pairs)
}
export class Requester {
    private _headers?: Headers; 
    private logoutFunction: () => void; 
    constructor(headers:Headers, logout_function:()=>void) {
        if (headers) {
            this._headers = headers;
        }
        this.logoutFunction = logout_function
    }
    async sendRequest(datafeedUrl:string, urlPath:string, params:any) {
        if (params) {
            const paramKeys = Object.keys(params);
            if (paramKeys.length !== 0) {
                urlPath += '?';
            }
            urlPath += paramKeys.map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`;
            }).join('&');
        }
        logMessage('New request: ' + urlPath);
        // Send user cookies if the URL is on the same origin as the calling script.
        const options: RequestInit = { credentials: 'same-origin'};
        if (this._headers) {
            options.headers = this._headers;
        }
        const response = await fetch(`${datafeedUrl}${urlPath}`, options);
        const responseTest = await response.text();
        if( response.status == 401){
            console.log("i am in 401")
            this.logoutFunction()
            return
        }
        return JSON.parse(responseTest);
    }
}
