import { logMessage } from '../utils/helpers';

// Define the type for headers (if applicable).
interface Headers {
    [key: string]: string;
}

// Define the shape of the parameters for the request
interface RequestParams {
    [key: string]: string;
}

export class InHouseRequester {
    private _headers: Headers | undefined;
    private logout_function: () => void;

    constructor(headers: Headers | undefined, logout_function: () => void) {
        if (headers) {
            this._headers = headers;
        }
        this.logout_function = logout_function;
    }

    // Define the type for the response of the sendRequest method
    async sendRequest(url: string, urlPath: string, body?: any, params: RequestParams = {}, method: "GET" | "POST" | "PUT" | "DELETE" = "GET"): Promise<any> {
        logMessage('New request: ' + urlPath);
        
        const options: RequestInit = { method }; // Start with the HTTP method (default to GET)
        
        if (this._headers) {
            options.headers = this._headers;
        }
        
        if (body && method !== "GET") {
            options.body = JSON.stringify(body);
        }

        const fullUrl = new URL(`${url}${urlPath}`);
        if (params && Object.keys(params).length > 0) {
            fullUrl.search = new URLSearchParams(params as Record<string, string>).toString();
        }

        // fullUrl.search = new URLSearchParams(params).toString(); // Attach query parameters
        
        return fetch(fullUrl.toString(), options)
            .then((response) => {
                if (response.status === 401) {
                    this.logout_function();
                    return ""; // Return an empty string or handle as needed
                }
                return response.text();
            })
            .then((responseText) => {
                try {
                    return JSON.parse(responseText);
                } catch (error) {
                    console.error("Failed to parse response:", error);
                    return null;
                }
            })
            .catch((error) => {
                console.error("Request failed:", error);
                return null;
            });
    }

    async getQuotes(url: string, queryString: string): Promise<any> {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: this._headers,
        };

        return fetch(`${url}analytics/quote?${queryString}`, requestOptions)
            .then((response) => response.text())
            .then((responseText) => {
                try {
                    return JSON.parse(responseText);
                } catch (error) {
                    console.error("Failed to parse quotes response:", error);
                    return null;
                }
            })
            .catch((error) => {
                console.error("Failed to fetch quotes:", error);
                return null;
            });
    }
}
