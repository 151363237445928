import React from "react";
// import { Status } from "../../components/Alerts/AlertTile";

export interface BadgeProps {
  Status: "triggered" | "active" | 'deleted';
}

const Badge: React.FC<BadgeProps> = ({ Status }) => {
  // Define the styles inside the component
  const badgeStyles = {
    active: {
      backgroundColor: "#a5f6d838",
      color: "#089981",
    },
    triggered: {
      backgroundColor: "#69b9ff30",
      color: "#048df4",
    },
    deleted: {
      backgroundColor: "#ec959c3d",
      color: "#f23645",
    },
  };

  const commonStyles = {
    padding: "0.25rem 0.5rem", // Equivalent to py-1 px-2
    borderRadius: "0.375rem", // Equivalent to br-6
    fontSize: "10px", // Equivalent to font-10
    lineHeight: "12px",
  };

  return (
    <span
      style={{
        ...commonStyles,
        ...badgeStyles[Status], // Apply the appropriate status styles
      }}
    >
      {Status === "active" ? "Active" : Status === "triggered" ? "Triggered" : Status === 'deleted' ? "Deleted" : ""}
    </span>
  );
};

export default Badge;
