import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Conditions, propertyToStringForEdit } from '../../../helper/alert/condition';

interface ValueInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  property: Conditions;
  error?: string; // Optional error message
}

const ValueInput: React.FC<ValueInputProps> = ({ value, onChange, disabled, property, error }) => {
  return (
    <div className="value-input">
      <Form.Label className="text-start w-100 text-color font-16" htmlFor="valueInr">
        <strong className="text-color">Value ({propertyToStringForEdit(property)})</strong>
      </Form.Label>

      <InputGroup>
        <Form.Control
          id="valueInr"
          className={`create-or-edit-dropdown pe-4 ${disabled && 'cursor-not-allowed alerts-disabled'} ${error ? 'is-invalid' : ''}`} // Apply is-invalid if there's an error
          aria-describedby="Value Input"
          placeholder="Enter value"
          maxLength={20}
          disabled={disabled}
          type="number"
          value={value}
          onChange={onChange}
        />
      </InputGroup>

      {/* Display error message if error exists */}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default ValueInput;
