import React from 'react';
import './ErrorText.scss';  // Assuming you have a CSS file for styles

interface ErrorTextProps {
  message: string;
  className?: string;  // Optional class name for additional styling
}

const ErrorText: React.FC<ErrorTextProps> = ({ message, className = '' }) => {
  return (
    <div className={`error-text ${className}`}>
      {message}
    </div>
  );
}

export default ErrorText;
