import Draggable from "react-draggable";
import React, {useEffect, useState} from "react";
import './draggableWidget.scss'
import {useDispatch} from "react-redux";
import {BuySellServices, ceService} from "../../sub";
import {ChartBuySell} from "../ChartBuySell/index";
import { Subscription } from 'rxjs';
import Subscribed from '../../models/symbolData'
import { Order } from "../../models/orders";


const DraggableWidget:React.FC = ()=> {

    const [ modelOpen, setModelOpen ] = useState(false)
    const [ buySellData, setBuySellData ] = useState<Subscribed | null>(null)


   let subscription:Subscription | undefined = undefined
    useEffect(() => {
        subscription = BuySellServices.getSub().subscribe(value => {
            setModelOpen(value.model_open)
            setBuySellData(value.data)
        });
        return () => {
            subscription?.unsubscribe();
        };
    }, []);


    useEffect(() => {
        if(buySellData){
            let data:Order={
                symbol:buySellData.buySellSymbol,
                limitPrice: buySellData.lp as number,
                side: buySellData.type === "B" ? 1 : -1,
            }
            ChartBuySell.instance?.ShowOrderDialog(data,()=>{})
        }
    }, [buySellData]);
    
    useEffect(() => { 
        return () => {
            BuySellServices.clearBuySell();
        }
    }, [])


    if(modelOpen === false){
        return null
    }

    return null;
    

}
export default DraggableWidget;
