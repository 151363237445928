import { createSlice,PayloadAction } from "@reduxjs/toolkit";


interface AlertState{
    model_open_alert:boolean;
}

const initialState: AlertState = {
    model_open_alert: false,
}

const AlertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        toggleAlertsListModel(state, action: PayloadAction<boolean>) {
            state.model_open_alert= action.payload;
          },
    }
});

export const {
    toggleAlertsListModel
} = AlertSlice.actions;

export default AlertSlice.reducer;