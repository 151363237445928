import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { ReactComponent as DropDownArrow } from "../../../assets/icons/dropdownArrow.svg";

interface ConditionDropdownProps {
  formData: {
    property: string;
    condition: string;
  };
  loading: boolean;
  conditionOptions: { value: string; label: string }[];
  handleDropdownChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ConditionDropdown: React.FC<ConditionDropdownProps> = ({
  formData,
  loading,
  conditionOptions,
  handleDropdownChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    const isDisabled = !formData.property || loading;
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const isDisabled = !formData.property || loading;

  return (
    <div className="condition-dropdown position-relative">
      <Form.Label
        htmlFor="condition"
        className="text-start w-100 text-color font-16 mb-1 mt-4"
      >
        <strong className="text-color">Condition</strong>
      </Form.Label>

      <InputGroup>
        <div className="position-relative w-100">
          <Form.Select
            name="condition"
            id="condition"
            aria-label="Select an option"
            disabled={isDisabled}
            className={`create-or-edit-dropdown pe-4 ${isDisabled ? 'cursor-not-allowed alerts-disabled' : ''}`}
            value={formData.condition}
            onChange={handleDropdownChange}
            onClick={toggleDropdown}
          >
            {conditionOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          
          {/* {!isDisabled && ( */}
            <DropDownArrow
            className="position-absolute top-50 end-0 translate-middle-y me-2 pointer-events-none text-color"
            style={{
              transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
              transition: "transform 0.3s ease",
            }}
          />
          {/* )} */}
        </div>
      </InputGroup>
    </div>
  );
};

export default ConditionDropdown;