import React, { useEffect } from 'react';
import {ReactComponent as CloserIcon} from "../../assets/images/close-square2.svg";
import {useDispatch} from "react-redux";
import {setBuilderSortBy,setGlobalStrategyTypeFilters,setGlobalStrategyTypeList,setBuilderDetails,CloseOptionStrategy,setStrategyState,setBuilderStrategyDetails,setGlobalStrategyNameFilters,setBuilderStrategyList,setRequiredGlobalMargin,setInitialGlobalMargin, setExpiryDates, setCurrentExpiryDate} from "../../slices/optionStrategySlice";
import { StrategyState } from "../../models/strategy"
const OptionStrategyHeader:React.FC<React.PropsWithChildren<{}>>=({ children })=> {

    const dispatch = useDispatch();

    const onModelClose = () => {
        let state:StrategyState = {id:1,url:'tools'}
        dispatch(setStrategyState(state))
        dispatch(CloseOptionStrategy())
        dispatch(setBuilderStrategyDetails(null))
        dispatch(setGlobalStrategyNameFilters(null))
        dispatch(setBuilderStrategyList(null))
        dispatch(setBuilderDetails(null))
        dispatch(setGlobalStrategyTypeList(null))
        dispatch(setGlobalStrategyTypeFilters(null))
        dispatch(setBuilderSortBy(null))
        dispatch(setExpiryDates(null))
        dispatch(setCurrentExpiryDate(null))
        dispatch(setRequiredGlobalMargin(null))
        dispatch(setInitialGlobalMargin(null))
    }

return(
    <div className='container optionStartegyHead handle_b'>
        {children}
        <div className='flex c-pointer' onClick={onModelClose}><CloserIcon /></div>
    </div>
)
}

export default OptionStrategyHeader;