import React, {useEffect, useState} from "react";
import {SpotService} from "../../sub";
import { Subscription } from 'rxjs';
import Subscribed from '../../models/symbolData'


interface SpotPriceProps{
    stockarray:number[];
    darkLightClass:string;
}

const SpotPrice:React.FC<SpotPriceProps> = ({stockarray,darkLightClass})=>{
    const [ dynamic_spot, setDynamic_spot ] = useState<Subscribed | null>(null)
    const [livePosition,setLivePosition] = useState<number | null>(null)
    const [strikeDifference,setStrikeDifference] = useState<number | null>(null)
    const [targetIndex,setTargetIndex]=useState<number | null>(null)
    const [Safari,setSafari] = useState<boolean | null>(false)

    let subscription:Subscription | undefined = undefined;
    useEffect(() => { 
        subscription = SpotService.getSpot().subscribe((value:Subscribed) => {
            setDynamic_spot(value)
        });
        return () => {
            subscription?.unsubscribe(); 
        }
    }, [])

    useEffect(()=>{
        appendDynamicSpotPrice()
        // if(dynamic_spot) executeToScroll()
    },[dynamic_spot])

    useEffect(()=>{
        isSafari()
    },[])

    useEffect(()=>{
            placeInitially()
    },[dynamic_spot])

    useEffect(()=>{
        if(livePosition) scrollToSpotPrice()
    },[livePosition])

    function isSafari() {
        // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        // setSafari(isSafari)
        const userAgent = navigator.userAgent;
        const isSafari = /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent);
        setSafari(isSafari);
    }

    function appendDynamicSpotPrice(){
            if(dynamic_spot){
                let strikePrice = (Math.floor(dynamic_spot.lp/(strikeDifference as number))+1)*(strikeDifference as number)
                setLivePosition(strikePrice)
                let dynamic_spot_element = document.getElementById("spot_price_live")
                let targetRow = document.getElementById(strikePrice.toString())
                let table = document.getElementById("mainbody")
        
                if (dynamic_spot_element && targetRow){
                    table?.insertBefore(dynamic_spot_element,targetRow);
                }
            }
    }
    function placeInitially(){
        if(targetIndex===null){
            for (let i = 0; i < stockarray.length; i++){
                if(dynamic_spot?.lp){
                    if(stockarray[i]-dynamic_spot?.lp>0){
                        setTargetIndex(i)
                        break;
                }
            }  
          }
        }
        let strikePrice = stockarray[targetIndex as number]
        let strike_diff = stockarray[((targetIndex as number)+1)]-stockarray[targetIndex as number]

        setStrikeDifference(strike_diff)
        setLivePosition(strikePrice)
    }


    const scrollToSpotPrice = ()=>{ 
        setTimeout(() => {
            let targetRow = document.getElementById("spot_price_live")
            if(targetRow){
                targetRow.scrollIntoView({behavior:"smooth",block: 'nearest', inline: 'center'});
            }
        },1000)
       } 

    if(dynamic_spot==null){
        return(
                <tr id="spot_price_live">
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                    <td className='p-0' colSpan={2}><div className={`${Safari?'safari_strike_price_live':'strike_price_live'}`}>Spot Price</div></td>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                </tr>    
        )
    }else{
        return (
                <tr id="spot_price_live" className={`${darkLightClass}`}>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                    <td className='p-0' colSpan={2}><div className={`${Safari?'safari_strike_price_live':'strike_price_live'} ${darkLightClass}`}>Spot Price {dynamic_spot?dynamic_spot.lp:''}  ({dynamic_spot?dynamic_spot.chp==0?0:dynamic_spot.chp.toFixed(2):0}%) </div></td>
                    <td className='p-0' ><div className={`${Safari?'safari_line':'line'}`}/></td>
                </tr>
        )
    }
}

export default SpotPrice 