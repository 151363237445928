import React from 'react';

interface StockSymbolIconProps {
  hasIcon: boolean;
  marketSegmentId: number;
  symbol: string;
  className?: string;
  style?: React.CSSProperties;
}

const Image: React.FC<StockSymbolIconProps> = ({ 
  hasIcon,
  marketSegmentId,
  symbol, 
  className = '', 
  style = { height: 16, width: 16 } 
}) => {
  // Determine the base image URL based on hasIcon and marketSegmentId
  let baseImageURL = '';

  if (hasIcon) {
    // Append the symbol dynamically when hasIcon is true
    baseImageURL = `https://static.asthatrade.com/stock_images/svgs/${symbol}.svg`;
  } else if ([1, 2].includes(marketSegmentId)) {
    // If marketSegmentId is 1 or 2, use NSE.svg
    baseImageURL = 'https://static.rupeezy.in/stock_images/svgs/NSE.svg';
  } else if ([3, 4, 8].includes(marketSegmentId)) {
    // If marketSegmentId is 3, 4, or 8, use BSE.svg
    baseImageURL = 'https://static.rupeezy.in/stock_images/svgs/BSE.svg';
  } else {
    // For other cases, default to the symbol as the file name
    baseImageURL = `https://static.asthatrade.com/stock_images/svgs/${symbol}.svg`;
  }

  return (
    <img
      src={baseImageURL}
      alt={symbol}
      className={className}
      style={style}
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.onerror = null; // Prevent infinite error loop
        imgElement.style.display = 'none'; // Hide broken image
        
        // Insert fallback element
        const fallbackElement = document.createElement('span');
        fallbackElement.textContent = symbol[0].toUpperCase();
        fallbackElement.style.cssText = `
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: ${style.height}px;
          width: ${style.width}px;
          background-color: #f0f0f0;
          border-radius: 50%;
          font-weight: bold;
          color: #333;
        `;
        
        imgElement.parentNode?.insertBefore(fallbackElement, imgElement);
      }}
    />
  );
};

export default Image;
