import * as React from "react";
import './background.css'
import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {setThemeData} from "../../slices/authSlice";
import Asthalogo from "../../assets/images/asthalogo.svg";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {RootState} from "../../store"
import { Application,ApplicationResponse } from "../../models/common";


const Background = (props:any) => {
    const { state,pathname } = useLocation();
    const [boxCss, setBoxCss] = useState('');

    const dispatch = useDispatch();
    const [theamDataBG, setThemeDataBG] = useState<Application | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { data,themeData } = useSelector((state:RootState) => state.authUser);



    function setBoxWidhHightCss() {
        if(pathname === '/loginOTPVerify'){
            setBoxCss('otp_box')
        }else if(pathname === '/EnableTOtp'){
            setBoxCss('enable_totp_box')
        }else if(pathname === '/PasswordNeedChange'){
            setBoxCss('passwordNeedChange')
        }
    }

    useEffect(() => {
        setBoxWidhHightCss()
    }, [pathname])

    useEffect(() => {
       if(data?.applicationId) getThemeFromApplicationID(data.applicationId)
    }, [data?.applicationId])

    useEffect(() => {
         setApplicationID()
    }, [])

    function setDataToStore() {
        dispatch(setThemeData(theamDataBG))
    }

    useEffect(() => {
        if(theamDataBG) setDataToStore()
    }, [theamDataBG])


    function setApplicationID() {
        if(searchParams.get('applicationId')){
            getThemeFromApplicationID(searchParams.get('applicationId') as string)
        }else if(themeData){
            setThemeDataBG(themeData)
        }
    }


    function getThemeFromApplicationID(applicationId:string) {
        flowBackEndServices2.getApplicationDetails({applicationId: applicationId}).then((result)=>{
            if( result.status === 200){
                let data:ApplicationResponse=result.data

                if (data.status === "success"){
                    data.application.themeNeed = result.data.application.ssoAuth
                    data.application.verifySSO = result.data.application.ssoAuth
                    data.application.display_name = result.data.application.displayName
                    data.application.name = result.data.application.applicationName
                    data.application.permission_needed = result.data.application.permissionNeeded
                    data.application.developer_id = result.data.application.developerId
                    data.application.developer_name = result.data.application.developerName
                    data.application.scope = result.data.application.scope
                    setThemeDataBG(data.application)
                    return 
                }
            }
            let data:Application={
                applicationId:'at_EJ02vWKDwS',
                name: 'Prod Tv Terminal',
                themeNeed: false,
                verifySSO: false,
                key:'TV_TERMINAL',
                display_name:'Trading View',
            }
            setThemeDataBG(data)
        })

    }


    function getCss() {
        let cssclass = '';
        if(theamDataBG){
            theamDataBG.themeNeed ? cssclass = 'my_bg_themed': cssclass = 'my_bg'
        }else if(pathname === '/MPin'){
            cssclass = 'my_bg';
        }
        return cssclass
    }

    function getFooter() {
        if(theamDataBG && !theamDataBG.themeNeed){
            return (<div id="footer" className="mb-5 footer_text">
                <div className="">NSE & MCX Sebi Reg No: INZ000187932 SEBI Depository Participant Reg No: IN-DP-611-2021 NSE Membership ID: 12227 MCX Membership ID: 40000
                    CIN: U65929MP2003PTC016241</div>
                <div className="">By proceeding ahead, you acknowledge that you have read, understood and agree to the Terms & Conditions, Disclaimers and Privacy Policy of Astha Credit & Securities.</div>
            </div>)
        }
    }

    function getCenterLog() {

        if(theamDataBG && theamDataBG.themeNeed){
            return(
                <div className="container">
                    <div className="heading1"><img src={theamDataBG.logo} width={55} alt={''} className="mr-0"/>&nbsp;{theamDataBG.display_name}</div>
                    
                    {themeData && themeData.permission_needed ? (<div className="heading3">Developed by {theamDataBG.developer_name}</div>) : ("")}
                    
                </div>
                
            )
        }

    }

    function getImage(){
        if(theamDataBG){
            if(!theamDataBG.themeNeed && isMobile){
                return(
                    <div className=""><img src="https://static.rupeezy.in/branding/blue-full-for-light-background-highres-154x45.svg" alt='Asthalogo' className="mx-1" style={{height: "21px"}}/></div>
                )
            }
            else if(!theamDataBG.themeNeed){
                return(
                    <div className=""><img src="https://static.rupeezy.in/branding/blue-full-for-dark-background-highres-154x45.svg" alt='Asthalogo' className="mx-1" style={{height: "21px"}}/></div>
                )
            }else{
                return(
                    <div className=""><img src="https://static.rupeezy.in/branding/blue-full-for-light-background-highres-154x45.svg" alt='Asthalogo' className="mx-1" style={{height: "21px"}}/></div>
                )
            }
        }
    }   

    return (
        <div className={`${getCss()}`}>
            <div className="topHeader">
                <div className=""/>
                <div className="align-items-center">
                    {getCenterLog()}
                </div>
                <div className=""/>
            </div>

            <div className={`box_body ${boxCss} border_and_shadow`}>
                <div className="position-static">
                    {props.children}
                </div>
            </div>

            

            {getFooter()}

            <div className="" id="footer_m">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="footer_text_m">Powered by&nbsp;</div>
                    {getImage()}
                </div>
            </div>

        </div>
    )
}


export default Background;
