import React, {useRef, useState} from 'react';
import Spinner from "react-bootstrap/Spinner";
import nextId from "react-id-generator";
import { Source,ScripSearchedData } from "../../../models/symbolData"
import { FormState } from "../../../models/strategy"


interface ScripSearchDataProps {
    data: ScripSearchedData[];  // Replace `any[]` with the actual type of the data array if known
    isLoading: boolean;
    formState: FormState;
    formStateChange: (key: string, value: string) => void;
    searchNameChange: (newName: Source) => void;
  }

const ScripSearchData:React.FC<ScripSearchDataProps>=({data,formState,isLoading,formStateChange,searchNameChange})=> {

    function onSymbleChange(stypevalue:string){
        formStateChange('symbolType',stypevalue)
    }

    function getSymbolInfo(data:Source){
        searchNameChange(data)
    }

    function decoratedExchange(exchange:string){
        return exchange
    }

    function getsData() {

        if(isLoading){
            return(
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
                </div>
            )
        }

        return (
            <div  className="d-flex srollbox flex-column justify-content-start align-items-start pl-4">

                { isLoading && (
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
                    </div>
                )
                }

                {
                    data.map(d => {
                        const id1 = nextId("B-");
                        return(
                            <div key={id1} onClick={() => getSymbolInfo(d._source)} className="d-flex flex-row my-2 datarow w-100">
                                <div className="tv-circle-logo">
                            <img 
                                className="tv-circle-logo" 
                                src={`https://static.rupeezy.in/stock_images/svgs/${d.symbol}.svg`} 
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => { 
                                    const img = e.target as HTMLImageElement; // Cast e.target to HTMLImageElement
                                    img.onerror = null; // Prevent infinite loop
                                    if(d.exchange?.includes("NSE")) {
                                        img.src = "https://static.rupeezy.in/stock_images/svgs/NSE.svg";
                                    } else if(d.exchange?.includes("BSE")) {
                                        img.src = "https://static.rupeezy.in/stock_images/svgs/BSE.svg";
                                    } else {
                                        img.src = "https://static.rupeezy.in/stock_images/svgs/MCX.svg";
                                    }
                                }} 
                                alt="" 
                            />
                        </div>
                                <div className="pl-3 mt-1 d-flex flex-column justify-content-start align-items-start">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="currentText">{d._source.sSymbol}</div>
                                        <div className="badge_bg" id="badge_exch">{decoratedExchange(d._source.ExchangeSegment as string)}</div>
                                    </div>
                                    <div className="text_low currentText2">{d.description}</div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )

    }

    return(
        <div className="searchData card_bg">

            <div className="d-flex flex-row justify-content-start align-items-center p-4">
                <div onClick={() => onSymbleChange('6')} className={`my_badge_topic ${formState.symbolType === '6' ? 'active':''}`}>All</div>
                <div onClick={() => onSymbleChange('1')}  className={`my_badge_topic ${formState.symbolType === '1' ? 'active':''}`}>Stocks</div>
                <div onClick={() => onSymbleChange('4')}  className={`my_badge_topic ${formState.symbolType === '4' ? 'active':''}`}>Indices</div>
            </div>

            {getsData()}
        </div>
    )
}


export default ScripSearchData;
