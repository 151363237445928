import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Source } from "../models/symbolData"
import { RecentSearchStrategy,StrategyState,TradingOpportunity,ExecutableLegs,Strategies,FinalData } from "../models/strategy"


interface OptionStrategyState {
  model_open_option_strategy: boolean;
  current_strategy_state: StrategyState;
  strategy_state: StrategyState[];
  recent_searches: RecentSearchStrategy[];  // Define the type for recent searches if known
  current_strategy: any | null;  // Define the type for the strategy if known
  current_symbol_details: Source | null;  // Define the type for symbol details if known
  expiryDates: string[] | null;  // Define the type for expiryDates if known
  current_expiry_date: string | null;  // Define the type for expiry date if known
  strategy_name_details: any | null;  // Define the type for strategy name details if known
  analyse_data: TradingOpportunity | null;  // Define the type for analyse data if known
  current_multiplier: number;
  active_index: number | null;
  available_funds: number | null;  // Define the type for available funds if known
  required_margin: number | null;  // Define the type for required margin if known
  initial_margin: number | null;  // Define the type for initial margin if known
  executable_options: ExecutableLegs | null;  // Define the type for executable options if known
  keep_default_index: boolean;
  builder_input: any | null;  // Define the type for builder input if known
  builder_strategy_details: any | null;  // Define the type for builder strategy details if known
  predict_range: any | null;  // Define the type for predict range if known
  strategy_name_filters: string[] | null;  // Define the type for strategy name filters if known
  builder_strategy_list: any | null;  // Define the type for builder strategy list if known
  builder_details: any | null;  // Define the type for builder details if known
  strategy_type_list: any | null;  // Define the type for strategy type list if known
  strategy_type_filters: string[] | null;  // Define the type for strategy type filters if known
  builder_sort_by: string | null;  // Define the type for builder sort by if known
}

const initialState: OptionStrategyState = {
  model_open_option_strategy: false,
  current_strategy_state: { id: 1, url: "tools" },
  strategy_state: [
    { id: 1, url: "tools" },
    { id: 2, url: "store/builder" },
    { id: 3, url: "allStrategies" },
    { id: 4, url: "strategyDetails" },
    { id: 5, url: "analyse" },
    { id: 6, url: "execution" },
    { id: 7, url: "builderStrategyDetails" },
  ],
  recent_searches: [],
  current_strategy: null,
  current_symbol_details: null,
  expiryDates: null,
  current_expiry_date: null,
  strategy_name_details: null,
  analyse_data: null,
  current_multiplier: 1,
  active_index: 0,
  available_funds: null,
  required_margin: null,
  initial_margin: null,
  executable_options: null,
  keep_default_index: true,
  builder_input: null,
  builder_strategy_details: null,
  predict_range: null,
  strategy_name_filters: null,
  builder_strategy_list: null,
  builder_details: null,
  strategy_type_list: null,
  strategy_type_filters: null,
  builder_sort_by: null,
};

const optionStrategySlice = createSlice({
  name: "optionStrategy",
  initialState,
  reducers: {
    OptionStrategyToggle: (state, action: PayloadAction<boolean>) => {
      state.model_open_option_strategy = action.payload;
    },
    CloseOptionStrategy: (state) => {
      state.model_open_option_strategy = false;
    },
    setStrategyState: (state, action: PayloadAction<StrategyState>) => {
      state.current_strategy_state = action.payload;
    },
    setRecentSearches: (state, action: PayloadAction<any[]>) => {
      state.recent_searches = action.payload;
    },
    setCurrentStrategy: (state, action: PayloadAction<any>) => {
      state.current_strategy = action.payload;
    },
    setCurrentSymbolDetails: (state, action: PayloadAction<any>) => {
      state.current_symbol_details = action.payload;
    },
    setExpiryDates: (state, action: PayloadAction<any>) => {
      state.expiryDates = action.payload;
    },
    setCurrentExpiryDate: (state, action: PayloadAction<any>) => {
      state.current_expiry_date = action.payload;
    },
    setStrategyNameDetails: (state, action: PayloadAction<any>) => {
      state.strategy_name_details = action.payload;
    },
    setAnalyseData: (state, action: PayloadAction<any>) => {
      state.analyse_data = action.payload;
    },
    setCurrentMultilier: (state, action: PayloadAction<number>) => {
      state.current_multiplier = action.payload;
    },
    setActiveIndexGlobal: (state, action: PayloadAction<number | null>) => {
      state.active_index = action.payload;
    },
    setGlobalFunds: (state, action: PayloadAction<any>) => {
      state.available_funds = action.payload;
    },
    setRequiredGlobalMargin: (state, action: PayloadAction<any>) => {
      state.required_margin = action.payload;
    },
    setInitialGlobalMargin: (state, action: PayloadAction<any>) => {
      state.initial_margin = action.payload;
    },
    setExecutableOptions: (state, action: PayloadAction<any>) => {
      state.executable_options = action.payload;
    },
    setKeepDefaultindex: (state, action: PayloadAction<boolean>) => {
      state.keep_default_index = action.payload;
    },
    setBuilderInput: (state, action: PayloadAction<any>) => {
      state.builder_input = action.payload;
    },
    setBuilderStrategyDetails: (state, action: PayloadAction<any>) => {
      state.builder_strategy_details = action.payload;
    },
    setPredictRange: (state, action: PayloadAction<any>) => {
      state.predict_range = action.payload;
    },
    setGlobalStrategyNameFilters: (state, action: PayloadAction<any>) => {
      state.strategy_name_filters = action.payload;
    },
    setBuilderStrategyList: (state, action: PayloadAction<any>) => {
      state.builder_strategy_list = action.payload;
    },
    setBuilderDetails: (state, action: PayloadAction<any>) => {
      state.builder_details = action.payload;
    },
    setGlobalStrategyTypeList: (state, action: PayloadAction<any>) => {
      state.strategy_type_list = action.payload;
    },
    setGlobalStrategyTypeFilters: (state, action: PayloadAction<any>) => {
      state.strategy_type_filters = action.payload;
    },
    setBuilderSortBy: (state, action: PayloadAction<any>) => {
      state.builder_sort_by = action.payload;
    },
    openOptionStrategy(state) {
      window.GtmPusher({
        event: 'tv-strategy',
      });
    },
    openOptionStrategyStore(state) {
      window.GtmPusher({
        event: 'tv-strategy-store',
      });
    },
    openOptionStrategyBuilder(state) {
      window.GtmPusher({
        event: 'tv-strategy-builder',
      });
    },
    optionBuilderAnalyse(state) {
      window.GtmPusher({
        event: 'tv-strategy-builder-analyse',
      });
    },
    optionStoreAnalyse(state) {
      window.GtmPusher({
        event: 'tv-strategy-store-analyse',
      });
    },
    optionStoreExecute(state) {
      window.GtmPusher({
        event: 'tv-strategy-store-execute',
      });
    },
    optionBuilderExecute(state) {
      window.GtmPusher({
        event: 'tv-strategy-builder-execute',
      });
    }
  },
});

export const {
  OptionStrategyToggle,
  CloseOptionStrategy,
  setStrategyState,
  setRecentSearches,
  setCurrentStrategy,
  setCurrentSymbolDetails,
  setExpiryDates,
  setCurrentExpiryDate,
  setStrategyNameDetails,
  setAnalyseData,
  setCurrentMultilier,
  setActiveIndexGlobal,
  setGlobalFunds,
  setRequiredGlobalMargin,
  setExecutableOptions,
  setKeepDefaultindex,
  setBuilderInput,
  setBuilderStrategyDetails,
  setPredictRange,
  setGlobalStrategyNameFilters,
  setBuilderStrategyList,
  setBuilderDetails,
  setGlobalStrategyTypeList,
  setGlobalStrategyTypeFilters,
  setBuilderSortBy,
  openOptionStrategy,
  openOptionStrategyStore,
  openOptionStrategyBuilder,
  optionBuilderAnalyse,
  optionStoreAnalyse,
  optionStoreExecute,
  optionBuilderExecute,
  setInitialGlobalMargin,
} = optionStrategySlice.actions;

export default optionStrategySlice.reducer;
