import React, { useEffect, useState } from 'react';
import "./rebrandingPopup.scss"

const RebrandingPopUp: React.FC = () =>{
    const [visible, setVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const queryParams = new URLSearchParams(window.location.search);
    const param1 = queryParams.get('loc');
    function hidePopUp(){
        setVisible(false);
    }


    if(param1 && (param1.includes('asthatrade.com') || param1.includes('asthatrade.dev')) && visible){
        if(!isMobile){
            return (
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='disclosure' style={{"width":"872px"}}>
                        <div><img src="https://static.rupeezy.in/Aero_Login/rebranding.svg" alt="alt text" /></div>
                        <div className='rebrandingtext mt-4'>Investment & trading made amazingly easy with <span style={{"color":"#064AD3"}}>Rupeezy</span></div>
                        <div className='noteSection mt-4'>
                            <div className='noteHeading'>Important url to note:</div>
                            <div style={{"display":"flex","flexDirection":"row","marginTop":"10px","gap":"60px"}}>
                                <div style={{"display":"flex","flexDirection":"column","marginLeft":"16px"}}>
                                    <div className='urlLayout'>
                                        <div><img src="https://static.rupeezy.in/Aero_Login/tv_mini_logo.svg" alt="alt" /></div>
                                        <div className='urls'><a href='https://flow.rupeezy.in' target="_blank">https://flow.rupeezy.in</a></div>
                                    </div>
                                </div>
                                <div style={{"display":"flex","flexDirection":"column"}}>
                                    <div className='urlLayout'>
                                        <div><img src="https://static.rupeezy.in/Aero_Login/dock_mini_logo.svg" alt="alt" /></div>
                                        <div className='urls'><a href='https://dock.rupeezy.in' target="_blank">https://dock.rupeezy.in</a></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{"display":"flex","flexDirection":"row","marginTop":"7px","gap":"20px"}}>
                                <div style={{"display":"flex","flexDirection":"column","marginLeft":"16px"}}>
                                    <div className='urlLayout'>
                                        <div><img src="https://static.rupeezy.in/Aero_Login/aero_mini_logo.svg" alt="alt" /></div>
                                        <div className='urls'><a href='https://aero.rupeezy.in/Aero/Login' target="_blank">https://aero.rupeezy.in/Aero</a></div>
                                    </div>
                                </div>
                                <div style={{"display":"flex","flexDirection":"column"}}>
                                    <div className='urlLayout'>
                                        <div><img src="https://static.rupeezy.in/Aero_Login/vortex_mini_logo.svg" alt="alt" /></div>
                                        <div className='urls'><a href='https://vortex.rupeezy.in' target="_blank">https://vortex.rupeezy.in</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bottom mt-4'>Explore our rebranding journey. <a href='https://rupeezy.in/blogs/asthatrade-is-now-rupeezy-tradeeasywithrupeezy/' target="_blank" style={{"color":"#064AD3"}}>Click here!</a></div>
                        <div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={hidePopUp}>Okay! got it</div>
                        </div>
                    </div>
                </React.Fragment>
              );
        }else{
            return(
                <React.Fragment>
                    <div className='mobileLayer'></div>
                    <div className='mobilePopup'>
                        <div><img src="https://static.rupeezy.in/Aero_Login/rebranding.svg" alt="changeBanner" /></div>
                        <div className='mobileHead mt-4 px-3'>Asthatrade is now <span style={{"color":"#064AD3"}}>Rupeezy</span></div>
                        <div className='mobilenoteSection mt-4 mx-3 pb-3'>
                            <div className='noteHeading'>Important url to note:</div>
                            <div className='urlLayout pl-4 mt-1' style={{"justifyContent":"left"}}>
                                <div><img src="https://static.rupeezy.in/Aero_Login/tv_mini_logo.svg" alt="alt" /></div>
                                <div className='urls'><a href='https://flow.rupeezy.in' target="_blank">https://flow.rupeezy.in</a></div>
                            </div>
                            <div className='urlLayout pl-4 mt-1' style={{"justifyContent":"left"}}>
                                <div><img src="https://static.rupeezy.in/Aero_Login/dock_mini_logo.svg" alt="alt" /></div>
                                <div className='urls'><a href='https://dock.rupeezy.in' target="_blank">https://dock.rupeezy.in</a></div>
                            </div>
                            <div className='urlLayout pl-4 mt-1' style={{"justifyContent":"left"}}>
                                <div><img src="https://static.rupeezy.in/Aero_Login/aero_mini_logo.svg" alt="alt" /></div>
                                <div className='urls'><a href='https://aero.rupeezy.in/Aero/Login' target="_blank">https://aero.rupeezy.in/Aero</a></div>
                            </div>
                            <div className='urlLayout pl-4 mt-1' style={{"justifyContent":"left"}}>
                                <div><img src="https://static.rupeezy.in/Aero_Login/vortex_mini_logo.svg" alt="alt" /></div>
                                <div className='urls'><a href='https://vortex.rupeezy.in' target="_blank">https://vortex.rupeezy.in</a></div>
                            </div>
                        </div>
                        <div className='bottom mt-4 px-3'>Explore our rebranding journey. <a href='https://rupeezy.in/blogs/asthatrade-is-now-rupeezy-trade-easy-with-rupeezy/' target="_blank" style={{"color":"#064AD3"}}>Click here!</a></div>
                        <div className='mobileFooter p-3'>
                            <div className='gotit' onClick={hidePopUp}>Okay! got it</div>
                        </div>
                    </div>
                </React.Fragment>
            )

        }
    }
    return null;
}   

export default RebrandingPopUp