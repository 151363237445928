import Draggable from "react-draggable";
import { ReactComponent as CloserIcon } from "../../../assets/images/close-square2.svg";
import GttHeaders from "./GttHeader";
import { OptionTimeframe, TradeDirection } from "./gttTypes";
import { useEffect, useState } from "react";
import {
  GttBodyTabsSelectors,
} from "./GTTBody/gtt.body.selector.helper";
import GTTTabs from "./GTTTabs";


import {
  assetClasspicker,
  getInstrumentName,
  securityTypepicker,
} from "./gtt.helper";
import GttBody from "./GTTBody";
import useStockDetails from "../../../hooks/api/get/getScripDetails";

import './common/scss/gtt.scss'

export type GTTProbs = {
  CloseGttModal: () => void;
  exchnage: string;
  ltp: string;
  ch: number;
  chp: string;
  segMentId: number | string;
  token: string;
};

const GTT: React.FC<GTTProbs> = ({ CloseGttModal, ...probs }) => {
  // Fetch the valid timeframes based on selected assetClass and securityType
  const InstrumentName = getInstrumentName(probs.exchnage);
  const { stockDetails } = useStockDetails(+probs.token, InstrumentName);
  const [conditionalWidth,setConditionalWidth] = useState<boolean>(false)

  // const gttTabsArray = GttBodyTabsSelector({
  //   assetClass: assetClasspicker(stockDetails?.instrumentName ?? ""),
  //   securityType: securityTypepicker(+probs.segMentId),
  // }); // TODO: we will get the probs and we will calcuate the probs based on this

  const gttTabsArray = GttBodyTabsSelectors({
    marketSegmentId: stockDetails?.marketSegmentID ?? "",
    choserString: stockDetails?.instrumentName ?? "",
  });

  const [tradeDirection, setTradeDirection] = useState<TradeDirection>("Sell");
  const [currentGttTab, setCurrentGttTab] = useState<OptionTimeframe>();

  useEffect(() => {
    if (gttTabsArray.length > 0 && !currentGttTab) {
      setCurrentGttTab(gttTabsArray[0]);
    }
  }, [gttTabsArray,currentGttTab]);

  if (!currentGttTab) {
    return null; // or a loading state if needed
  }

  // Update current GTT tab based on selected option
  const currentGttSwitcher = (newGttTab: OptionTimeframe) => {
    setCurrentGttTab(newGttTab);
  };

  // Toggle between "Buy" and "Sell"
  const toggleTradeDirection = () => {
    setTradeDirection((prev) => (prev === "Buy" ? "Sell" : "Buy"));
  };

  return (
    <Draggable handle=".handle_b" bounds="parent" allowAnyClick>
      <div style={{maxWidth:conditionalWidth?"960px":"483px"}} className="custom-order-dialog_basket currentBg draggable-border">
        <div
          style={{ height: 80 }}
          className="container optionStartegyHead handle_b"
        >
          {/* GTT Header */}
          <GttHeaders
            toggleTradeDirection={toggleTradeDirection}
            tradeDirection={tradeDirection}
            {...probs}
            exchnage={InstrumentName.toUpperCase()}
            scriptName={stockDetails?.symbol || ""}
            securityDesc={stockDetails?.securityDesc || ""}
          />

          {/* Close Modal Button */}
          <div className="flex c-pointer" onClick={CloseGttModal}>
            <CloserIcon />
          </div>
        </div>

        {/* Tab Selector Component */}
        {gttTabsArray.length > 0 && (
          <GTTTabs
            optionTimeframe={gttTabsArray}
            selected={currentGttTab}
            toggleOptionTimeframe={currentGttSwitcher}
          />
        )}

        <GttBody
          setConditionalWidth={()=>setConditionalWidth(!conditionalWidth)}
          isCondtionalWidthNeeded={conditionalWidth}
          currentGTTTab={currentGttTab}
          token={probs.token}
          exchnage={probs.exchnage}
          ltp={probs.ltp}
          tradeDirection={tradeDirection}
          assetClass={assetClasspicker(stockDetails?.instrumentName ?? "")}
          securityType={securityTypepicker(+probs.segMentId)}
          tick={stockDetails?.tick ?? 0}
          lotSize={stockDetails?.lotSize ?? 0}
          CloseGttModal={CloseGttModal}
          securityDesc={stockDetails?.securityDesc || ""}
        />
      </div>
    </Draggable>
  );
};

export default GTT;
