import React, {useEffect, useState} from "react";
import {ReactComponent as BuyButton} from "../../assets/images/BuyButton.svg";
import {ReactComponent as SellButton} from "../../assets/images/SellButton.svg";
import {ReactComponent as ChartButton} from "../../assets/images/ChartButton.svg";

interface BuySellButtonProps{
    buy: () => void;
    sell: () => void;
    chart: () => void;
}

const BuySellButton:React.FC<BuySellButtonProps> = ({buy,sell,chart})=>{

    return(
        <React.Fragment>
            <span style={{"cursor":"pointer"}}><BuyButton onClick={()=>buy()} /></span>
            <span style={{"cursor":"pointer"}}><ChartButton onClick={()=>chart()}/></span>
            <span style={{"cursor":"pointer"}}><SellButton onClick={()=>sell()}/></span>
        </React.Fragment>
    )
}

export default BuySellButton