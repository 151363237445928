import Badge from "../../Badge";
// import { ReactComponent as HamburgerMenu } from "../../../assets/icons/hamburger.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit-2.svg";
import { ReactComponent as Delete } from "../../../assets/icons/trash.svg";
import "./alert-tile.scss";
import { AlertData } from "../../../hooks/api/get/getAlerts";
import Image from "../Image";
import { propertyToString } from "../../../helper/alert/property";
import { conditionToString } from "../../../helper/alert/condition";
import ExchangePill from "../ExchangePill";
import { formatDate } from "../../../utils/dateformater";

export type Status = "active" | "inactive";

export type AlertTileProps = {
  className?: string;
  onDeleteAlert: (id: number) => void;
  onEditAlert: (alert: Omit<AlertData, "status">) => void;
} & Omit<AlertData, "status">;

const AlertTile: React.FC<AlertTileProps> = (alertTileData) => {
  return (
    <div className="py-2">
      <div className="d-flex gap-3 alert-bg-color br-12 py-2 px-4">
        {/* Ones we had this on API level we can uncomment this */}
        {/* <HamburgerMenu
          style={{ height: 24, width: 24 }}
          className="hamburger-menu my-auto"
        /> */}

        <div className="flex-grow-1">
          <div className="text-start">
            <p style={{textTransform: 'capitalize'}} className="alert-heading font-14 my-0">{alertTileData.name}</p>
            <span>
              <Image
                hasIcon={alertTileData.stock_data.has_icon}
                marketSegmentId={alertTileData.stock_data.marketSegmentId}
                style={{ height: 16, width: 16, borderRadius: 25 }}
                symbol={alertTileData.stock_data.symbol}
              />
              <h2 className="font-weight-700 mx-2 d-inline alert-heading font-14 my-0 ">
                {alertTileData.stock_data.symbol}{" "}
                <small>
                  {alertTileData?.stock_data?.strikePrice &&
                  Math.max(0, alertTileData.stock_data.strikePrice) / 100 > 0
                    ? (
                        Math.max(0, alertTileData.stock_data.strikePrice) / 100
                      ).toFixed(2)
                    : null}
                </small>
                {alertTileData?.stock_data?.optionType &&
                  ["CE", "PE"].includes(
                    alertTileData.stock_data.optionType
                  ) && (
                    <ExchangePill
                      className="mx-1"
                      text={alertTileData.stock_data.optionType}
                    />
                  )}
                <small className="mx-1">
                  {alertTileData?.stock_data?.expYYYYMMDD
                    ? formatDate(alertTileData?.stock_data?.expYYYYMMDD)
                    : null}
                </small>
                <Badge
                  Status={
                    alertTileData.isExecuted === true ? "inactive" : "active"
                  }
                />
              </h2>
            </span>
          </div>
          <p className="text-start alert-description">
            {/* {alertTileData.property} */}
            {propertyToString(alertTileData.property)}{" "}
            {conditionToString(alertTileData.condition)}{" "}
            {alertTileData.triggerValue.toFixed(2)}
          </p>
        </div>
        <div className="d-flex gap-3 my-auto">
          <Edit
            className="edit-alert"
            onClick={() => alertTileData.onEditAlert(alertTileData)}
          />
          <Delete
            className="delete-alert "
            onClick={() => alertTileData.onDeleteAlert(alertTileData.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertTile;
