import { AlertData } from "../../hooks/api/get/getAlerts";
export type Conditions = 'LTP' | 'AVGPRICE' | 'VOLUME' | ''

export const conditionToString = (condition: AlertData["condition"] | '') => {
  if (condition === "LE") {
    return "Less than ( < )";
  }

  if (condition === "EQ") {
    return "Equal to ( = )";
  }

  if (condition === "GE") {
    return "Greater than ( > )";
  }

  return "Select Condition";
};


export const propertyToStringForEdit = (condition: Conditions) => {
  if (condition === "LTP") {
    return "Last Traded Price";
  }

  if (condition === "AVGPRICE") {
    return "Average Trade Price";
  }

  if (condition === "VOLUME") {
    return "Volume";
  }

  return "Select Property";
};