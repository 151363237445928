import React, { useMemo, useRef, useState } from "react";
import { ListGroup } from "react-bootstrap";
import ExchangePill from "../Alerts/ExchangePill";
import { parseDate } from "../../utils/dateformater";
import LoadingSpinner from "../FutureChain/LoadingSpinner";
import { ScripLookUpHitSource } from "../../hooks/api/get/getScripLookUp";
import { SearchArrayType } from "../Alerts/common/SearchResultsDropdown";
import { getSortName } from "../../utils/getShortName";
import "./virtualizedList.scss";

const VirtualizedList = ({
  loading,
  items,
  itemHeight = 50,
  visibleHeight = 200,
  onItemSelect,
  searchType
}: {
  items: ScripLookUpHitSource[];
  itemHeight?: number;
  visibleHeight?: number;
  onItemSelect: (item: any) => void;
  loading: boolean;
  searchType: SearchArrayType;
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Calculate visible items based on scroll position
  const virtualizedItems = useMemo(() => {
    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(
      startIndex + Math.ceil(visibleHeight / itemHeight) + 1,
      items.length
    );

    return items.slice(startIndex, endIndex).map((item, index) => ({
      item,
      index: startIndex + index,
      style: {
        position: "absolute",
        top: `${(startIndex + index) * itemHeight}px`,
        width: "100%",
        height: `${itemHeight}px`,
      },
    }));
  }, [items, scrollTop, itemHeight, visibleHeight]);

  // Handle scroll event
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  return (
    <div
      ref={containerRef}
      className="overallSearch"
      style={{
        height: `${visibleHeight}px`,
        overflowY: "auto",
        position: "relative",
        borderRight:0
      }}
      onScroll={handleScroll}
    >
      <div
        style={{
          height: `${items.length * itemHeight}px`,
          position: "relative",
        }}
      >
       {
        loading ? <div className="text-center">
          <LoadingSpinner/>
        </div> : virtualizedItems.map(({ item, index, style }) => (
          <ListGroup.Item
            key={index}
            className="datarow overallSearch vl-border-bottom"
            style={{...style as React.CSSProperties, borderRight: 0, borderLeft: 0, borderTop: 0}}
            action
            onClick={() => onItemSelect(item)}
          >
            <div className="text-start d-flex  gap-2 ">
              <div className="NameLable">
                {getSortName(item?.sInstrumentName)}
              </div>
              <div>
                <div className="currentText">{item.sSymbol} <ExchangePill text={item?.sExchange} className="mx-1" /></div>
                <div className="">
                  <div className="currentText text_low currentText2">{item?.sSecurityDesc} {['OPT','FUT'].includes(getSortName(item?.sInstrumentName) ?? 'N/A') && parseDate(item?.nExpiryDate1)} {searchType === 'Options' && (+item.nStrikePrice /100)} {searchType === 'Options' && item.sOptionType}</div>
                  {/* {item.sSecurityDesc} */}
                </div>
              </div>
            </div>
          </ListGroup.Item>
        ))
       }
      </div>
    </div>
  );
};

export default VirtualizedList;
