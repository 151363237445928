// components/alerts/SearchSection.tsx

import React from 'react';
import { Form, InputGroup } from "react-bootstrap";
import { ReactComponent as Clear } from "../../../assets/images/close-square2.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search-icon.svg";
import { SearchArrayType } from "../common/SearchResultsDropdown";
import SearchResultsDropdown from "../common/SearchResultsDropdown";
import { ScripLookUpHitSource } from '../../../hooks/api/get/getScripLookUp';
import { AlertFormData } from '../../../models/alertTypes';

interface SearchSectionProps {
  formData: AlertFormData;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setShowSearchResults: (show: boolean) => void;
  showSearchResults: boolean;
  reSetInput: () => void;
  error: any;
  serchType: SearchArrayType;
  setSearchType: (type: SearchArrayType) => void;
  loading: boolean;
  data: ScripLookUpHitSource[] | null;  // Updated type to match useScripLookUp return type
  handleSearchResultSelect: (item: ScripLookUpHitSource) => void;
}

const SearchSection: React.FC<SearchSectionProps> = ({
  formData,
  handleInputChange,
  setShowSearchResults,
  showSearchResults,
  reSetInput,
  error,
  serchType,
  setSearchType,
  loading,
  data,
  handleSearchResultSelect
}) => {
  return (
    <div className="position-relative w-100">
      <InputGroup>
        <Form.Control
          id="searchQuery"
          type="text"
          className="alert-main-input"
          placeholder={'Search Nifty, BankNifty 36500, NIFTY FUT'}
          aria-label="Search stock alerts"
          aria-autocomplete="list"
          autoComplete="off"
          value={formData.searchQuery}
          onChange={handleInputChange}
          onClick={() => setShowSearchResults(true)}
          style={{borderRight: 0}}
        />
        <InputGroup.Text
          className="bg-transparent search-bar"
          aria-label="Search icon"
        >
          {formData.searchQuery.length ? (
            <Clear onClick={reSetInput} />
          ) : (
            <SearchIcon />
          )}
        </InputGroup.Text>
      </InputGroup>

      <SearchResultsDropdown
        isError={!!error}
        searchType={serchType}
        setSearchType={setSearchType}
        showSearchResults={showSearchResults}
        searchQuery={formData.searchQuery}
        loading={loading && !!formData.searchQuery}
        data={data || []}  // Provide empty array as fallback when data is null
        handleSearchResultSelect={handleSearchResultSelect}
      />
    </div>
  );
};

export default SearchSection;