import { ChangeEvent } from 'react';
import { ScripLookUpHitSource } from '../hooks/api/get/getScripLookUp';
import { FormHandlers ,AlertFormData, IsAlertFildTouched} from '../models/alertTypes';

export const createFormHandlers = (
  formData: AlertFormData,
  setFormData: React.Dispatch<React.SetStateAction<AlertFormData>>,
  volume: number | null,
  priceValue: number | null,
  setSelectedToken: (token: number | null) => void,
  setSelectedMarketSegmentId: (id: number | null) => void,
  setIsnIsIndex: (isIndex: boolean) => void,
  setShowSearchResults: (show: boolean) => void,
  setIsTouched: React.Dispatch<React.SetStateAction<IsAlertFildTouched>>
): FormHandlers => ({
  handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setIsTouched(prev => ({ ...prev, [id]: true }));
  },

  handleINRChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    const changeValue = parseFloat(value) || 0;
    const initialValue = parseFloat(
      formData.property === "VOLUME"
        ? volume?.toString() ?? "0"
        : priceValue?.toString() ?? "0"
    ) || 0;

    const alertPercentValueChange =
      formData.property === "VOLUME"
        ? ((changeValue - initialValue) / initialValue) * 100
        : ((changeValue - initialValue) / initialValue) * 100;

    const formattedPercentChange = alertPercentValueChange.toFixed(2);

    setFormData(prevState => ({
      ...prevState,
      [id]: value,
      lastTradedPrice: formattedPercentChange,
    }));
    setIsTouched(prev => ({ ...prev, [id]: true }));
  },

  handleDropdownChange: (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setIsTouched(prev => ({ ...prev, [name]: true }));
  },

  handleSearchResultSelect: (item: ScripLookUpHitSource) => {
    setFormData(prev => ({
      ...prev,
      searchQuery: item.sSymbol,
    }));

    setSelectedToken(item.nToken);
    setSelectedMarketSegmentId(item.MktSegId === 8 ? 3 : item.MktSegId);
    setIsnIsIndex(!!item.nIsIndex);
    setShowSearchResults(false);
    setIsTouched(prev => ({ ...prev, searchQuery: true }));
  },

  onLastTradedPriceChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    const changeValue = parseFloat(value) || 0;
    const initialValue = parseFloat(
      formData.property === "VOLUME"
        ? volume?.toString() ?? "0"
        : priceValue?.toString() ?? "0"
    ) || 0;

    const alertPercentValueChange = formData.property === "VOLUME"
      ? (initialValue * (1 + changeValue / 100)).toFixed(2)
      : (initialValue * (1 + changeValue / 100)).toFixed(2);

    setFormData(prevState => ({
      ...prevState,
      [id]: value,
      valueInr: alertPercentValueChange,
    }));
  },

  handleNoteClear: () => {
    setFormData(prevState => ({
      ...prevState,
      note: "",
    }));
  },
});

// export const validateAlertSubmission = (
//   formData: AlertFormData,
//   selectedMarketSegmentId: number | null,
//   selectedToken: number | null
// ): string | null => {
//   if (!selectedMarketSegmentId || !selectedToken) {
//     return "Please select a market segment (e.g., Stock, Index, Nifty, Bank Nifty) to proceed.";
//   }

//   const triggerValue = parseFloat(formData.valueInr);
//   if (isNaN(triggerValue)) {
//     return "Please enter a valid numeric value";
//   }

//   const requiredFields: (keyof CreateAlertPayload)[] = ["name", "condition", "property"];
//   const emptyFields = requiredFields.filter(field => !formData[field as keyof AlertFormData]);

//   if (emptyFields.length > 0) {
//     return `Please fill in all required fields: ${emptyFields.join(", ").replace('name', 'Alert Name')}`;
//   }

//   return null;
// };

export const resetFormData = (): AlertFormData => ({
  searchQuery: "",
  alertName: "",
  property: "",
  condition: "",
  valueInr: "",
  lastTradedPrice: "",
  note: "",
});