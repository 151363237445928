import { ReactComponent as CrossIcon } from "../../../assets/icons/search-icon.svg";
// import { ReactComponent as CrossIcon } from "../../../assets/images/close-square2.svg";
// import { ReactComponent as CrossIcon } from "../../../assets/images/close-square2.svg";

import PriceFluctuations from "../PriceFluctuations";
import ExchangePill from "../ExchangePill";
import Subscribed from "../../../models/symbolData";
import { parseDate } from "../../../utils/dateformater";
import { ScripLookUpHitSource } from "../../../hooks/api/get/getScripLookUp";
import { getSortName } from "../../../utils/getShortName";

interface MarketSegmentDetailsProps {
  data?: ScripLookUpHitSource[];
  formData: {
    searchQuery: string;
  };

  isEditable: boolean;
  selectedMarketSegment?: Subscribed;
  reSetInput: () => void;
}

const MarketSegmentDetails: React.FC<MarketSegmentDetailsProps> = ({
  data,
  formData,
  selectedMarketSegment,
  isEditable,
  reSetInput,
}) => {
  if (!selectedMarketSegment) {
    return null;
  }
  const dataForMaretingSegment = data?.find(
    (item) => item.nToken === +Number(selectedMarketSegment.token)
  );
  const shortName = getSortName(dataForMaretingSegment?.sInstrumentName ?? "");

  return (
    <div className="bg-gray p-2 text-start br_12 position-relative">
      <div className="d-flex">
        <div className="flex-grow-1 text-color fw_700 text-black">
          {formData.searchQuery}{" "}
          <small>
            {dataForMaretingSegment?.nStrikePrice &&
            Math.max(0, +dataForMaretingSegment?.nStrikePrice) / 100 > 0
              ? (
                  Math.max(0, +dataForMaretingSegment?.nStrikePrice) / 100
                ).toFixed(2)
              : null}
          </small>
          {dataForMaretingSegment?.sOptionType &&
            ["CE", "PE"].includes(dataForMaretingSegment?.sOptionType) && (
              <ExchangePill
                className="mx-1"
                text={dataForMaretingSegment?.sOptionType}
              />
            )}
          <small className="mx-1">
            {dataForMaretingSegment?.nExpiryDate1
              ? parseDate(dataForMaretingSegment?.nExpiryDate1)
              : null}
          </small>
          <ExchangePill
            text={selectedMarketSegment?.exchange}
            className="mx-1"
          />
          {["FUT", "OPT"].includes(shortName ?? "") && (
            <ExchangePill text={shortName} className="mx-1" />
          )}
        </div>
        {isEditable && (
          <CrossIcon
            style={{ height: 24, top: "30%" }}
            className="c-pointer text-color mb-4 me-3 position-absolute end-0"
            onClick={reSetInput}
          />
        )}
      </div>
      <span className="fs-14 text-color fw_700">
        {selectedMarketSegment?.lp.toFixed(2)} {"  "}
        {selectedMarketSegment?.ch && selectedMarketSegment?.lp && (
          <PriceFluctuations
            chp={selectedMarketSegment?.chp}
            lp={selectedMarketSegment?.ch}
          />
        )}
      </span>
      <div className="d-flex">
        <div className="flex-grow-1">
          {selectedMarketSegment?.volume > 0 &&
            selectedMarketSegment?.average_trade_price && (
              <div>
                <small className="text-color">Average Trading Price</small>
                <div className="text-color fw_700 fs-14">
                  {selectedMarketSegment?.average_trade_price?.toFixed(2)}
                </div>
              </div>
            )}
        </div>
        <div className="flex-grow-1">
          {selectedMarketSegment?.volume > 0 && (
            <div>
              <small className="text-color">Volume</small>
              <div className="text-color fw_700 fs-14">
                {selectedMarketSegment?.volume}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketSegmentDetails;
