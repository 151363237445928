import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { logMessage } from '../../utils/helpers';
import { RootState } from '../../store';
import './LoginForm.css';
import {useNavigate, useSearchParams} from "react-router-dom";
import {STATUSES} from "../../comman/status";
import Background from "../BackGround/background";
import logo from "../../assets/images/Frame.svg";
import Spinner from "react-bootstrap/Spinner";
import MadeInLoveIndia from "../common/madeInLoveIndia";
import flowBackEndServices2 from "../../services/flowBackendServices";
import {DEVICE_ID_KEY, QUERY_PARAM, TV_APPLICATION_ID,QR_SOCKET_URL} from "../../utils/config";
import {toast} from "react-toastify";
import {setOtpData,setQrData , setStatus,loginEvent, setEmailAndMobile} from "../../slices/authReducer";
import authService from "../../services/authService";
import RiskDisclosure from './RiskDisclosure';
import KraStatus from "./kraStatus"
import SupportDesktopView from './DesktopViewSupport';
import { getBrowserDetails } from "../../comman/status";
import { getExtraQueryparam } from '../../components/common/parseQueryParam';
import {VerifyMfaResponse,LoginInfo,RedirectPayload, RefreshData} from '../../models/login'
import { getDeviceID,scopeParse } from '../common/parseQueryParam'

const LoginWithQr: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoggedIn,status,tokenExp,user_id, user,themeData,data,theamDataBG } = useSelector((state: RootState) => state.authUser);

  const [formState, setFormState] = useState({user_id: '', password: '', second_auth: '',ApplicationId: TV_APPLICATION_ID});
//   const [DEVICE_ID,setDEVICE_ID]=useState('')
    var DEVICE_ID : string=""
  const [ errors, setErrors ] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [showDisclosure,setShowDisclosure] = useState(false)
  const [aeroUrl,setAeroUrl]=useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [kra,setKra]=useState(0)
  const [showNext,setShowNext] = useState(false)
  const [showDot,setShowDot] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [qrImageString, setQrImageString] = useState('');
    const [isPermissionGranted,changePermissionGrant] = useState(false)
    function setPermission(){
        changePermissionGrant(!isPermissionGranted)
    }
    useEffect(() =>{
            setApplicationID()
    },[])

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    var socket : any

    function setApplicationID() {
        let applicationId:string = formState.ApplicationId ?? '';
        const applicationIdFromSearch = searchParams.get('applicationId')
        if(applicationIdFromSearch){
            applicationId = applicationIdFromSearch
            localStorage.setItem(QUERY_PARAM,JSON.stringify(searchParams.toString()))
        }else if(themeData?.applicationId){
            applicationId = themeData.applicationId
        }
        let dataSet = {data: { ...data,applicationId},user_id: user_id ?? ""}
        dispatch(setOtpData(dataSet))
    }

    useEffect(()=>{
        let ackDisc = localStorage.getItem("acknowledge_disclosure")
        if(ackDisc==null || ackDisc=="false"){
            setShowDisclosure(false)
        }else{
            setShowDisclosure(true)
        }

        return ()=>{
            localStorage.setItem("acknowledge_disclosure","false")
        }
    },[])
    
    useEffect(() => {
          // setFormState({user_id: '600S1871', password: 'ApiTesting15', second_auth: '9741578665'})
          let ack = sessionStorage.getItem("acknowledge_disclosure")
          if(isLoggedIn){
              if(searchParams.get('applicationId')) return checkTokenValid();
              if(ack){
                  if(ack!="true"){
                      navigate("/tvTerminal",{ replace: true});
                  }else{
                      handleLogout()
                  }
              }else{
                  navigate("/tvTerminal",{ replace: true})
              }
              return
          }
  
          if(user_id !== null){
              setFormState({ ...formState,user_id: user_id})
          }
        
        generateQrCode()

    },[theamDataBG])

    useEffect(() => {
        const interval = setInterval(() => {
          generateQrCode();
        }, 60000);
        return () => clearInterval(interval);
      }, []);

    function getIconAndHeader() {
        if(themeData.themeNeed){
            return(
                <div className="">
                    <div className=""><img width={'60'} height={'60'} src={'https://static.rupeezy.in/branding/blue-for-light-background.svg'} alt='Flowlogo'/></div>
                    {/* <div className="heading1 pt-2">Flow</div> */}
                    {/* <div className="heading3 colorGrey py-2">Login to {themeData.display_name} with Flow</div> */}
                </div>
            )
        }else {
            return(
                <div className="pt-5">
                    <div className=""><img src={logo} alt='logo'/></div>
                    <h3 className="heading1 py-2">Trade directly from charts</h3>
                </div>
            )
        }
    }

    if(status === STATUSES.LOADING){
        return (
            <div className="center_loading">
                    <Spinner className="mr-2 align-self-center" as="span" animation="border"  role="status" aria-hidden="true"/>
            </div>
        )
    }
    function Scope() {
        var scopeString = themeData.scope
        var scopeArray = scopeString.split(",");
        return (
            <div className='permission_box'>
                <div style={{ fontSize: '13px', alignItems: 'flex-start', fontWeight: 'bold' }} className="colorGrey pb-3">
                    You are about to grant the following permissions to {themeData.name}:
                    {scopeArray.map((scope: any) => {
                        var parsedScope = scopeParse(scope, scopeString)
                        if (parsedScope && parsedScope != "") {
                            return (<li className='permission-list-item' style={{fontWeight: 'normal'}}>
                                {parsedScope}
                            </li>)
                        }
                    })}

                </div>
                <label style={{ fontSize: '13px', alignItems: 'flex-start' }} className="colorGrey pb-3">
                <input
                        type="checkbox"
                        checked={isPermissionGranted} // Use 'checked' for controlling the state
                        onChange={setPermission}
                        style={{ marginRight: '3px' }}
                    />
                    I agree to give my account access to {themeData.name}
                </label>

            </div>


        )
    }


    function getDisclosure(){
        localStorage.setItem("acknowledge_disclosure","true")
        sessionStorage.setItem("acknowledge_disclosure","true")
        if(isDesktop){
            return(
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='disclosure'>
                    {showNext && <KraStatus kraStatus={kra}/>}
                            {!showNext && <RiskDisclosure />}
                            <div className="dot-container">
                                <div id="first-dot" className="cr-dot"></div>
                                {showDot && <div id="second-dot" className="cr-dot"></div>}
                            </div>
                        {!showNext? <div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={acknowledge}>I understand</div>
                        </div>:<div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={moveToNext}>Next</div>
                        </div>}
                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <div className='disclosureLayer'></div>
                    <div className='disclosure' style={{"width":"300px"}}>
                    {showNext && <KraStatus kraStatus={kra}/>}
                            {!showNext && <RiskDisclosure />}
                            <div className="dot-container">
                                <div id="first-dot" className="cr-dot"></div>
                                {showDot && <div id="second-dot" className="cr-dot"></div>}
                            </div>
                        {!showNext? <div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={acknowledge}>I understand</div>
                        </div>:<div className='discFooter'>
                            <div className='ackButton c-pointer' onClick={moveToNext}>Next</div>
                        </div>}
                    </div>
                </React.Fragment>
            )
        }
    }

    function getResponsiveLoginForm(){
        return (
                 <div className="h-100 p-5 p-xl-5 d-flex flex-column justify-content-end">
    
                     {themeData && getIconAndHeader()}
                        <div className='qr_inst py-1'>
                        {/* Open FLOW mobile application <br/>
                        Go to setting & scan QR code to Login */}
                        Scan QR code from settings of the latest Rupeezy App to Login
                        </div>
                     <div className="align-self-start flex-fill w-100 d-flex flex-column-reverse">
                        <div className='qr_center'>
                            <div className='qr_code'>
                                <img src={`data:image/png;base64,${qrImageString}`} className='qr_image' />
                                <img width={'40'} height={'40'} src={'https://static.rupeezy.in/branding/blue-for-light-background.svg'} className='qr_logo' />
                            </div>
                            
                        </div>
                     </div>
    
                     {(themeData && themeData.permission_needed) ? Scope() : ("")}
    
                     <div className="">
                         {/* <Button disabled={isLoading} className="blue_btn my-3" onClick={handleOnClick} variant="primary" type="submit">
                             {isLoading && (
                                 <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                             )}
                             Login with OTP
                         </Button> */}
                         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <div className='qr_log_switch py-2' onClick={handleOnClick}>login using Id & Password</div>
                         </div>
                         
    
                         <div className="py-2">Don’t have an account ? <span onClick={SendOpenAccound} className="colorBlue c-pointer"> Open an account</span> </div>
    
                        <MadeInLoveIndia/>
    
                     </div>
    
                 </div>
        );
    }
    return(
        <React.Fragment>
            <Background>
                {getReturn()}
            </Background>
            {showDisclosure && getDisclosure()}
         </React.Fragment>
    )

    function getReturn(){
        if(!isMobile){
            return (
                <React.Fragment>
                    {getResponsiveLoginForm()}
                </React.Fragment>
           );
        }else{
            if(themeData && !themeData.themeNeed){
                return (
                    <SupportDesktopView />
           );
            }else{
                return (
                    <React.Fragment>
                        {getResponsiveLoginForm()}
                    </React.Fragment>
               );
            }
        }
    }


     function handleOnClick(event:any){
        event.preventDefault();
        navigate("/login?applicationId="+getApplicationId())
    }

    function SendOpenAccound() {
        let referral_id=sessionStorage.getItem("referral_id")
        if(referral_id && referral_id!=""){
            window.open('https://accounts.rupeezy.in/authentication/login?c='+referral_id,'_blank')
        }else{
            window.open('https://accounts.rupeezy.in/authentication/login','_blank')

        }
    }


    async function generateQrCode(){
        setIsLoading(true)
        let browser = getBrowserDetails()
        let deviceInfo = `${browser?.browserName}`;
        let appVersion = `${browser?.fullVersion}`;
        let os = `${browser?.OSName}`;

        let dataSend={
            deviceInfo: deviceInfo,
            os: os,
            appVersion: appVersion,
            deviceId:getDeviceID(DEVICE_ID_KEY),
            applicationId:getApplicationId(),
            authType: "v2",
            secret:"313425432rfcdsfdfdsfdsfa"
        }
        DEVICE_ID=dataSend.deviceId
        flowBackEndServices2.getQrImage(dataSend)
            .then( (value) => {
                if(value.status===200){
                    setQrImageString(value.data.image)
                     sendSocketMessage(JSON.stringify({udid: value.data.udid}))
                }
            }).catch(reason=>{
                console.log("error occurred in calling QR generation api - "+reason)
                setIsLoading(false)
            }).finally(()=> setIsLoading(false))
    }

    function getApplicationId(){
        var applicationId : string = formState.ApplicationId ?? ""
        if(themeData && themeData.applicationId){
            applicationId = themeData.applicationId
        }
        else if (searchParams.get('applicationId')) {
            applicationId = searchParams.get('applicationId') ?? ""
            localStorage.setItem(QUERY_PARAM,JSON.stringify(searchParams.toString()))
        }else {
            applicationId = TV_APPLICATION_ID ?? ""
        }
        return applicationId
    }



    function sendSocketMessage(message:any){
        var interval = setInterval(()=>{
            if( socket !== undefined &&  socket.readyState === 1) {
                clearInterval(interval)
                socket.send(message)
            }else if(socket === undefined || socket.readyState === 2 || socket.readyState === 3) {
                reconnectSocket()
            }
        },200)
    }

    function acknowledge(){
        localStorage.setItem("acknowledge_disclosure","false")
        sessionStorage.setItem("acknowledge_disclosure","false")
        if(aeroUrl){
            window.open(aeroUrl, '_self')
        }else{
            navigate("/tvTerminal",{ replace: true});
        }
    }

    function moveToNext(){
        setShowNext(false)
    }

    function reconnectSocket(){
        if( socket !== undefined){
            socket.close
            socket = undefined    
        }
        socket = new WebSocket(QR_SOCKET_URL??"")
        var stateData = data
        socket.onmessage = (message:any) => {

            const messageDetails:any = JSON.parse(message.data);
            if (messageDetails.data){
                var data:VerifyMfaResponse=messageDetails
            if( data.status === "success") {
                let dataSet = {stateData: { ...stateData,mfaType:'qr',deviceId:DEVICE_ID}}
                setUpQrSessionData(data,dispatch,navigate,dataSet)
            }
            }else{
                var dataFromServer:LoginInfo = messageDetails
                if(dataFromServer.redirectUrl !== undefined && dataFromServer.redirectUrl !== '' ){
                    // login event
                    dispatch(setEmailAndMobile({mobile: null,email:dataFromServer.email }))
                    dispatch(loginEvent())
                    toast.success(`Logged In Successfully`)
                    const params = localStorage.getItem('Q_PARAM_ALL')
                    const applicationId=params?.split("=")
                    if(applicationId && applicationId[1]=='at_g4FXzp00jB"'){
                        if(dataFromServer.kra_status=="HOLD"){
                            setShowNext(true)
                            setKra(1)
                            setShowDot(true)
            
                        }else if(dataFromServer.kra_status=="REJECTED"){
                            setShowNext(true)
                            setKra(0)
                            setShowDot(true)
                        }else{
                            setShowNext(false)
                        }
                        setShowDisclosure(true)
                        setAeroUrl(dataFromServer.redirectUrl + getExtraQueryparam())
                    }else{
                        let fullRedirectUrl = dataFromServer.redirectUrl + getExtraQueryparam()
                        window.open(fullRedirectUrl,'_self')
                    }
                }
            }

        };

          socket.onerror = function(error:any){
            logMessage(`Streaming Provider: Socket Error: ${JSON.stringify(error)}`)
        }
    }


    function setUpQrSessionData(value:VerifyMfaResponse,dispatch:any,navigate:any,data:any) {
        let userData = value
        if(userData.status === 'success'){
            let newData = {...data,refreshToken: userData.refreshToken,mfaType: data.stateData.mfaType}
            let dataSet = {data: newData,user_id: userData.data.user_id}
            dispatch(setQrData(dataSet))
            const refreshData:RefreshData = { refreshToken: userData.refreshToken,ssoToken: userData.ssoToken,mfaType:data.stateData.mfaType,deviceId: data.stateData.deviceId,tradingActive: userData.data.tradingActive}
            const userModify = { ...userData.data,...refreshData}
    
            // login event
            dispatch(setEmailAndMobile({mobile: null,email: userModify.email }))
            dispatch(loginEvent())
    
            authService.saveUserSessionFlow(userModify,refreshData,dispatch)
            toast.success(`Logged In Successfully`)
            if(userData.data.kra_status=="HOLD"){
                setShowNext(true)
                setKra(1)
                setShowDot(true)

            }else if(userData.data.kra_status=="REJECTED"){
                setShowNext(true)
                setKra(0)
                setShowDot(true)
            }else{
                setShowNext(false)
            }
            setShowDisclosure(true)
            // navigate("/tvTerminal",{ replace: true});
        }
    }
    
    function callApiAndRedirect() {
        let datasend:RedirectPayload = {ssoToken:String(user?.ssoToken),applicationId: searchParams.get('applicationId') }
        dispatch(setStatus(STATUSES.LOADING));
        flowBackEndServices2.getRedirecUrl(datasend,String(user?.access_token))
                    .then( (value) => {
                        if(value?.status === 200) {
                            if(value.data?.redirectUrl){
                                let fullRedirectUrl = value.data.redirectUrl + getExtraQueryparam()
                                window.open(fullRedirectUrl, '_self')
                                window.open(value.data.redirectUrl,'_self')
                            }else {
                                toast.error(`${value.data.message}`)
                            }
                        }
                    }).catch(reason => {
                        toast.error(`invalid Data`)
                        setIsLoading(false)
                        dispatch(setStatus(STATUSES.IDLE));
                    }).finally(() =>  dispatch(setStatus(STATUSES.IDLE)))
    }
    function handleLogout () {
        dispatch(authService.logout())
        navigate("/",{ replace: true});
    }

    function checkTokenValid(){
        var applicationId = getApplicationId()
        if (applicationId != "" && applicationId != null){
            // at_cTKcWxTMaL is vortex application id. we dont want it to directly use sso auth
            if (applicationId.startsWith("at_") && applicationId != "at_cTKcWxTMaL" ) {
                if(tokenExp.ssoToken){
                    handleLogout()
                }else {
                    callApiAndRedirect()
                }
            }
        }else{
            if(tokenExp.ssoToken){
                handleLogout()
            }else {
                callApiAndRedirect()
            }
        }
    }    

}

export default LoginWithQr;
