import { useState, useCallback } from "react";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export interface CreateAlertPayload {
  name: string;
  token: number;
  marketSegmentId: number;
  triggerValue: number;
  property: string;
  condition: string;
  note: string;
  basketId: number | null;
}

interface CreateAlertResponse {
  success: boolean;
  message: string;
  data?: any;
}

const ALERTS_API_URL = `${FLOW_BACKEND_BASE_URL}aux/alerts`;

const useCreateAlert = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CreateAlertResponse | null>(null);
  
  // Get the token outside of the mutate function to avoid hook rule violations
  const token = useSelector((state: RootState) => state.authUser.user?.access_token);

  const mutate = useCallback(
    async (
      payload: CreateAlertPayload,
      options?: {
        onSuccess?: (responseData: CreateAlertResponse) => void;
        onError?: (error: string) => void;
      }
    ) => {
      setIsLoading(true);
      setIsError(false);
      setError(null);

      try {
        const response = await fetch(ALERTS_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Something went wrong");
        }

        const responseData = (await response.json()) as CreateAlertResponse;
        setData(responseData);

        // Call onSuccess callback if provided
        options?.onSuccess?.(responseData);

        return responseData;
      } catch (err: any) {
        const errorMessage = err.message || "Unknown error occurred";
        setIsError(true);
        setError(errorMessage);

        // Call onError callback if provided
        options?.onError?.(errorMessage);

        throw err; // Re-throw to allow error handling at call site
      } finally {
        setIsLoading(false);
      }
    },
    [token] // Add token to dependency array
  );

  return { mutate, isLoading, isError, error, data };
};

export default useCreateAlert;