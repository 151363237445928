import { SearchArrayType } from "../components/Alerts/common/SearchResultsDropdown";

export const productCode = (SerachType: SearchArrayType): number => {
  switch (SerachType) {
    case "Stocks":
      return 9;
    case "Options":
      return 22;
    case "Future":
      return 22;
    case "Indices":
      return 9;
    case "All":
      return 23;
    default:
      return 23;
  }
};
