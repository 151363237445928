import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {BasketState,AllBaskets} from "../models/basketOrders";

// Define types for the state
export interface CompleteBasket {
  model_open_basket: boolean;
  current_basket: BasketState | null;
  basketDetails: AllBaskets | null;
  currentModification: any | null;
  listDataMain: Array<AllBaskets>;
  sharedBasketId: string | null;
  orderListenerData: any | null;
  public_basket_data: any | null;
  basket_old_id_data: any | null;
  open_public_basket: boolean;
  execute_public_basket: boolean;
  public_basket_margin: any | null;
  basket_state: Array<BasketState>;
}

// Define the initial state
const initialState: CompleteBasket = {
  model_open_basket: false,
  current_basket: null,
  basketDetails: null,
  currentModification: null,
  listDataMain: [],
  sharedBasketId: null,
  orderListenerData: null,
  public_basket_data: null,
  basket_old_id_data: null,
  open_public_basket: false,
  execute_public_basket: false,
  public_basket_margin: null,
  basket_state: [
    { id: 1, param: null, url: "BucketList/EmptyState" },
    { id: 2, param: null, url: "CreateBasket" },
    { id: 3, param: null, url: "basketDetails" },
    { id: 4, param: null, url: "sharedBasketDetails" },
    { id: 5, param: null, url: "basketAnalyse" },
  ],
};

const basketOrderSlice = createSlice({
  name: "basketOrder",
  initialState,
  reducers: {
    BasketModelToggle: (state, action: PayloadAction<boolean>) => {
      state.model_open_basket = action.payload;
    },
    OpenPublicBasket: (state, action: PayloadAction<boolean>) => {
      state.open_public_basket = action.payload;
    },
    ExecutePublicBasket: (state, action: PayloadAction<boolean>) => {
      state.execute_public_basket = action.payload;
    },
    setSharedBasketId: (state, action: PayloadAction<string | null>) => {
      state.sharedBasketId = action.payload;
    },
    setPublicBasketData: (state, action: PayloadAction<any | null>) => {
      state.public_basket_data = action.payload;
    },
    setPublicBasketMargin: (state, action: PayloadAction<any | null>) => {
      state.public_basket_margin = action.payload;
    },
    setBasketOldIdData: (state, action: PayloadAction<any | null>) => {
      state.basket_old_id_data = action.payload;
    },
    setCurrentBasketState: (state, action: PayloadAction<BasketState | null>) => {
      state.current_basket = action.payload;
    },
    setBasketDetails: (state, action: PayloadAction<any | null>) => {
      state.basketDetails = action.payload;
    },
    setCurrentModification: (state, action: PayloadAction<any | null>) => {
      state.currentModification = action.payload;
    },
    setListDataMain: (state, action: PayloadAction<AllBaskets[]>) => {
      state.listDataMain = action.payload;
      if (state.basketDetails) {
        const data = state.listDataMain.find(d => d.id === state.basketDetails?.id);
        state.basketDetails = data || null;
      }
    },
    setOrderListenerData: (state, action: PayloadAction<any | null>) => {
      state.orderListenerData = action.payload;
    },
    basketOrderAnalyse: () => {
      window.GtmPusher({
        event: "tv-basket-order-analyse",
      });
    },
    sharedBasketAnalyse: () => {
      window.GtmPusher({
        event: "tv-basket-shared-analyse",
      });
    },
    sharedBasketExecute: () => {
      window.GtmPusher({
        event: "tv-basket-shared-execute",
      });
    },
    sharedBasketSave: () => {
      window.GtmPusher({
        event: "tv-basket-shared-save",
      });
    },
    basketShareEvent: () => {
      window.GtmPusher({
        event: "tv-basket-share-link",
      });
    },
    sharedBasketOpenEvent: () => {
      window.GtmPusher({
        event: "tv-basket-shared-open",
      });
    },
  },
});

export const {BasketModelToggle,setCurrentBasketState,setBasketDetails,setListDataMain,setCurrentModification,setOrderListenerData,OpenPublicBasket,setPublicBasketData,ExecutePublicBasket,setSharedBasketId,setBasketOldIdData,setPublicBasketMargin,basketOrderAnalyse,sharedBasketAnalyse,sharedBasketExecute,sharedBasketSave,basketShareEvent,sharedBasketOpenEvent,
} = basketOrderSlice.actions;

export default basketOrderSlice.reducer;
