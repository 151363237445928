import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthService from "../services/authService";
import { decodeBase64, STATUSES } from "../comman/status";
import { MOMENT_KEY, STORAGE_KEY, STORAGE_KEY_REFRESH } from "../utils/config";
import moment from "moment";
import { LoginInfo,RefreshData } from "../models/login"
import { datadogRum } from '@datadog/browser-rum';

const user:LoginInfo = JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null');
const sessionTime:number = JSON.parse(localStorage.getItem(MOMENT_KEY) || '0');
const refreshData:RefreshData = JSON.parse(localStorage.getItem(STORAGE_KEY_REFRESH) || 'null');

interface TokenExp {
  ssoToken: boolean;
  checked?: boolean;
}

interface AuthState {
  user: LoginInfo | null;
  isLoggedIn: boolean;
  status: string;
  error_message: string | null;
  email: string | null;
  mobile: string | null;
  data: any;
  user_id: string | null;
  themeData: any;
  tokenExp: TokenExp;
  sessionTime: number;
}

const initialState: AuthState = {
  user,
  isLoggedIn: !!user,
  status: STATUSES.IDLE,
  error_message: null,
  email: null,
  mobile: null,
  data: refreshData,
  user_id: null,
  themeData: null,
  tokenExp: { ssoToken: false },
  sessionTime: Number(sessionTime),
};

function checkTokenValid(state: AuthState) {
  const sso_token = state.user?.ssoToken;
  if (sso_token) {
    let decodeValue = JSON.parse(decodeBase64(sso_token.split('.')[1]));
    state.data = { ...state.data, applicationId: decodeValue.applicationId };
    let d = new Date(0);
    let momentInTime = moment(d.setUTCSeconds(decodeValue.exp));
    let now = moment();
    let diff = now.diff(momentInTime, 'minutes');
    if (diff > 0) {
      state.tokenExp = { ...state.tokenExp, ssoToken: true };
    } else {
      state.tokenExp = { ...state.tokenExp, ssoToken: false, checked: true };
    }
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<LoginInfo>) {
      datadogRum.setUser({
        id: action.payload.user_id,
        name: action.payload.user_name,
        email: action.payload.email,
      });
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.error_message = action.payload;
    },
    clearMessage(state) {
      state.error_message = null;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    setMpinEnable(state) {
      if (state.user) {
        state.user.mpin_enabled = true;
      }
    },
    setEmailAndMobile(state, action: PayloadAction<{ email: string | null, mobile: string | null }>) {
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
    },
    setQrData(state, action: PayloadAction<{ data: any }>) {
      state.data = action.payload.data;
    },
    setOtpData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
      state.user_id = action.payload.user_id;
    },
    setSessionTime(state, action: PayloadAction<number>) {
      state.sessionTime = action.payload;
    },
    setThemeData(state, action: PayloadAction<any>) {
      state.themeData = action.payload;
    },
    initialCheckData(state) {
      checkTokenValid(state);
    },
    loginEvent(state,action: PayloadAction<{ email: string | null, mobile: string | null }>) {
      if (action.payload.email && action.payload.mobile) {
        window.GtmPusher({
          event: 'tv-login',
          customEventProperties:{"email":action.payload.email,"mobile_number":action.payload.mobile}
        });
      }
    },
    switchUserEvent(state) {
      window.GtmPusher({
        event: 'tv-switch_user',
      });
    },
    TotpDoLaterEvent(state) {
      window.GtmPusher({
        event: 'tv-totp_do_later',
      });
    },
  },
});

export const currentUser = (state: { authUser: AuthState }) => state.authUser.user;
export const {
  setUser,
  setStatus,
  setMessage,
  clearMessage,
  setThemeData,
  setEmailAndMobile,
  setOtpData,
  setSessionTime,
  logout,
  loginEvent,
  setMpinEnable,
  initialCheckData,
  switchUserEvent,
  TotpDoLaterEvent,
  setQrData,
} = authSlice.actions;

export default authSlice.reducer;
