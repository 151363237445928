import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import '../../../../scss/_text.scss'

export interface GenericInputProps {
  label?: string;
  subLable?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;

  // Additional configuration options
  id?: string;
  placeholder?: string;
  maxLength?: number;
  type?: "text" | "number" | "email" | "password";
  
  // Number input properties
  min?: number;
  max?: number;
  step?: number | "any";

  // Styling and customization
  labelClassName?: string;
  inputClassName?: string;
  containerClassName?: string;

  // Optional addon elements
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;

  // Additional form control props
  required?: boolean;
  pattern?: string;
  autoComplete?: string;

  // Custom validation and error handling
  error?: string;
  helperText?: string;
}

const GenericInputBase: React.FC<GenericInputProps> = ({
  label,
  subLable,
  value,
  onChange,
  disabled = false,
  id = "inputField",
  placeholder = "Enter value",
  maxLength,
  type = "text",
  min,
  max,
  step,
  labelClassName = "text-start w-100 pt-4 text-color",
  inputClassName = "create-or-edit-dropdown pe-4",
  containerClassName = "",
  leftAddon,
  rightAddon,
  required = false,
  pattern,
  autoComplete = "off",
  error,
  helperText,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (type === "number") {
      // Allow empty value
      if (newValue === '') {
        onChange(e);
        return;
      }

      // For non-empty values, validate decimal number format
      if (/^\d*\.?\d*$/.test(newValue)) {
        // Handle single decimal point
        if (newValue === '.') {
          onChange(e);
          return;
        }

        // Handle regular decimal numbers
        const parts = newValue.split('.');
        if (
          // Allow single decimal point
          parts.length <= 2 && 
          // Limit decimal places to 2
          (parts.length === 1 || parts[1].length <= 2)
        ) {
          onChange(e);
        }
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div className={containerClassName}>
      {label && (
        <Form.Label className={labelClassName} htmlFor={id}>
          <div className="d-flex flex-wrap justify-content-between">
            <strong style={{ fontSize: 14 }} className="text-color">
              {label}
              {required && <span className="text-danger"> *</span>}
            </strong>
            <div className="text-color" style={{ opacity: 0.6, fontSize: 12 }}>
              {subLable}
            </div>
          </div>
        </Form.Label>
      )}

      <InputGroup>
        {leftAddon && <InputGroup.Text>{leftAddon}</InputGroup.Text>}

        <Form.Control
          id={id}
          className={`
            ${inputClassName} 
            ${disabled ? "cursor-not-allowed" : ""} 
            ${error ? "is-invalid" : ""}
          `}
          type={type === "number" ? "text" : type}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          required={required}
          pattern={pattern}
          autoComplete={autoComplete}
          inputMode={type === "number" ? "decimal" : "text"}
        />

        {rightAddon && <InputGroup.Text>{rightAddon}</InputGroup.Text>}
      </InputGroup>

      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
      {helperText && !error && (
        <Form.Text className="text-muted">{helperText}</Form.Text>
      )}
      {error && <small className="text-candel-red">{error}</small>}
    </div>
  );
};

const GenericInput = React.forwardRef<HTMLInputElement, GenericInputProps>(
  (props, ref) => {
    return (
      <GenericInputBase
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />
    );
  }
);

GenericInput.displayName = "GenericInput";

export default GenericInput;