/***
 * [This Component is Common For all the GTT Orders]
 *  */

import React, { useEffect, useState } from "react";
import ToggleButton from "../common/GTTTrigger/ToggleGroup";
import {
  AssetClasses,
  FormValidation,
  GTTFormState,
  OptionTimeframe,
  OrderType,
  SecurityType,
  TradeDirection,
} from "../gttTypes";

import "./gttbody.scss";
import "../../../../scss/_buttons.scss";

import GenericInput from "../common/GTTGenericInput";
import {
  calculateInitialGttState,
  ErrorMessageGTT,
  formatDecimalNumber,
  GTTFormValidationState,
  GttOrderCreateTerms,
  transformGTTObjectToFormState,
  // GttInitlaState,
  validateField,
  validateTriggerPrice,
} from "./gttHelper";
import { GTTBodyProps } from ".";
import { GTTOrder } from "../../../../models/gttOrders";
import useUpdateGttOrder from "../../../../hooks/api/put/updateGttAlert";
import { BrokerApi } from "../../../../chart-brokerapi/broker";
import { constructGTTUpdatePayload } from "../gtt.helper";

const limitAlertText: string =
  "Consider using market order instead of limit order to avoid non execution of order.";

export type GTTProbs = {
  setConditionalWidth: () => void;
  CloseGttModal: () => void;
  assetClass: AssetClasses;
  securityType: SecurityType;
  tradeDirection: TradeDirection;
  ltp: string;
  currentGTTTab: OptionTimeframe;
  tick: number;
  lotSize: number;
  isCondtionalWidthNeeded: boolean;
  targatedOrder: GTTOrder;
} & Pick<GTTBodyProps, "token" | "exchnage">;

const EditGTTComp: React.FC<GTTProbs> = ({
  tradeDirection,
  ltp,
  tick,
  lotSize,
  setConditionalWidth,
  targatedOrder,
  CloseGttModal,
}) => {
  const initialLtp = React.useMemo(() => ltp, []);
  const tickBy100 = React.useMemo(() => tick/100, []);
  // Initial form state

  const [formState, setFormState] = useState<GTTFormState>(() => {
    if (targatedOrder && targatedOrder.orders) {
      return transformGTTObjectToFormState(targatedOrder, tradeDirection,tickBy100);
    }

    // Default state if no targeted order
    return {
      triggerType: "Single",
      triggerPrice: "",
      quantity: "",
      price: "",
      orderType: "Limit",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  });

  const [showValidation, setShowValidation] = useState(false);
  
  const getErrorObject = () => {
    if (!showValidation) return {};

    return {
      stoplossErrorTriggerprice: validateTriggerPrice(
        formState.triggerPrice, 
        ltp, 
        tradeDirection, 
        tickBy100, 
        ErrorMessageGTT.stopLossMessage.greaterMessage, 
        ErrorMessageGTT.stopLossMessage.lessMessage, 
        ErrorMessageGTT.stopLossMessage.multipleMessage(tickBy100),
      ),
      stoplossErrorPrice: validateTriggerPrice(
        formState.price, 
        ltp, 
        tradeDirection, 
        tickBy100, 
        ErrorMessageGTT.stopLossMessage.greaterMessage, 
        ErrorMessageGTT.stopLossMessage.lessMessage, 
        ErrorMessageGTT.stopLossMessage.multipleMessage(tickBy100),
        formState.orderType === 'Market'
      ),
      triggerErrorTriggerPrice: validateTriggerPrice(
        formState?.targetTriggerPrice || '', 
        ltp, 
        tradeDirection === 'Buy' ? 'Sell' : 'Buy',
        tickBy100, 
        ErrorMessageGTT.targetMessage.greaterMessage, 
        ErrorMessageGTT.targetMessage.lessMessage, 
        ErrorMessageGTT.targetMessage.multipleMessage(tickBy100)
      ),
      triggerErrorPrice: validateTriggerPrice(
        formState?.targetPrice || '', 
        ltp, 
        tradeDirection === 'Buy' ? 'Sell' : 'Buy',
        tickBy100, 
        ErrorMessageGTT.targetMessage.greaterMessage, 
        ErrorMessageGTT.targetMessage.lessMessage, 
        ErrorMessageGTT.targetMessage.multipleMessage(tickBy100),
        formState.targetOrderType === 'Market'
      )
    };
  };

  const ErrorObject = getErrorObject();

  // Effect to update form when targatedOrder changes
  useEffect(() => {
    if (targatedOrder && targatedOrder.orders) {
      const transformedState = transformGTTObjectToFormState(
        targatedOrder,
        tradeDirection,
        tickBy100
      );
      setFormState(transformedState);
    }
  }, [targatedOrder]);

  const { mutate: updateGttOrder } = useUpdateGttOrder();

  // Effect to update form state when trade direction changes (only if no targeted order)
  useEffect(() => {
    setShowValidation(false)
    if (!targatedOrder?.orders) {
      setFormState((prevState) => ({
        ...calculateInitialGttState(initialLtp, tradeDirection,tickBy100),
        // Preserve existing values
        quantity: prevState.quantity,
        targetQuantity: prevState.targetQuantity,
        orderType: prevState.orderType,
        targetOrderType: prevState.targetOrderType,
        triggerType: prevState.triggerType,
      }));
    }
  }, [tradeDirection, initialLtp, targatedOrder]);

  // Effect for conditional width
  useEffect(() => {
    setConditionalWidth();
  }, [formState.triggerType]);

  // Validation state
  const [validationErrors, setValidationErrors] = useState<FormValidation>(
    GTTFormValidationState
  );

  // Generic input change handler
  const handleInputChange = (field: keyof GTTFormState, value: string) => {
    setShowValidation(true)
    setFormState((prev) => {
      let formattedValue = value;
      
      
      // Apply decimal formatting for numeric fields
      if (
        field === 'triggerPrice' ||
        field === 'price' ||
        field === 'targetTriggerPrice' ||
        field === 'targetPrice'
      ) {
        if (value !== 'Market') {
          formattedValue = formatDecimalNumber(value);
        }
      }

      const newState = { ...prev, [field]: formattedValue };

      if (field === "orderType") {
        if (value === "Market") {
          newState.price = "Market";
        } else if (value === "Limit" && prev.price === "Market") {
          // newState.price = "";
          newState.price = formState.triggerPrice;
        }
      }

      if (field === "targetOrderType" && prev.triggerType === "OCO") {
        if (value === "Market") {
          newState.targetPrice = "Market";
        } else if (value === "Limit" && prev.targetPrice === "Market") {
          // newState.targetPrice = "";
          newState.targetPrice = formState.targetTriggerPrice;
        }
      }

      return newState;
    });
  };

  // Validate entire form
  const validateForm = (): boolean => {
    const errors: FormValidation = {
      triggerPrice: !validateField("triggerPrice", formState.triggerPrice),
      quantity: !validateField("quantity", formState.quantity),
      price: !validateField("price", formState.price),
      // Conditionally validate target fields for ODC
      ...(formState.triggerType === "OCO" && {
        targetTriggerPrice: !validateField(
          "targetTriggerPrice",
          formState.targetTriggerPrice || ""
        ),
        targetQuantity: !validateField(
          "targetQuantity",
          formState.targetQuantity || ""
        ),
        targetPrice: !validateField("targetPrice", formState.targetPrice || ""),
      }),
    };

    setValidationErrors(errors);

    // Check if any errors exist
    return !Object.values(errors).some((error) => error === true);
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {

      try {
        const updatePayload = constructGTTUpdatePayload({
          formState,
          targatedOrder,
          tradeDirection
        });
        
        const result = await updateGttOrder(updatePayload, {
          onSuccess: (data) => {
            // console.log("Order updated successfully:", data);
          },
          onError: (errorMessage) => {
            // console.error("Error updating order:", errorMessage);
          },
        });

        if (result?.status === "success") {
          CloseGttModal();
          BrokerApi.instance?._host.showNotification(
            "GTT Order Updated successfully!",
            result.data?.data?.order_id,
            1
          );
        } else {
          BrokerApi.instance?._host.showNotification(
            "Failed to Update GTT Order",
            result?.message || "Unknown error occurred",
            0
          );
        }
      } catch (error) {
        BrokerApi.instance?._host.showNotification(
          "Error creating GTT Order",
          error instanceof Error ? error.message : "Unknown error occurred",
          0
        );
      }
    } else {
      // console.log("Form has validation errors");
    }
  };

  const isDiabled = !!(
    ErrorObject.stoplossErrorPrice || 
    ErrorObject.stoplossErrorTriggerprice || 
    ErrorObject.triggerErrorTriggerPrice || 
    ErrorObject.triggerErrorPrice
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-4 my-5 justify-content-between">
        {/* <div className="d-flex justify-content-between align-items-center">
          <div style={{ fontSize: 20 }} className="text-color fw-bolder">
            Trigger type
          </div>
          <ToggleButton<TriggerMode>
            options={["Single", "OCO"]}
            selectedOption={formState.triggerType}
            onToggle={(value) => handleInputChange("triggerType", value)}
          />
        </div> */}
        <div
          className={`${
            formState.triggerType === "OCO" ? "col-6" : "col-12"
          } text-start pe-4`}
        >
          {formState.triggerType === "OCO" && (
            <div className="text-color fw-bolder">Stop Loss</div>
          )}

          <GenericInput
            label="Trigger price"
            value={formState.triggerPrice || ""}
            onChange={(e) => handleInputChange("triggerPrice", e.target.value)}
            error={
              validationErrors.targetTriggerPrice
                ? "Invalid target trigger price"
                : ErrorObject.stoplossErrorTriggerprice
            }
            disabled={false}
            type="number"
          />

          <div className="row">
            <GenericInput
              containerClassName="col-6"
              label="Quantity"
              subLable={`1 lot = ${lotSize}`}
              required
              value={formState.quantity}
              onChange={(e) => handleInputChange("quantity", e.target.value)}
              error={validationErrors.quantity ? "Invalid quantity" : undefined}
              disabled={false}
              type="number"
            />

            <div className="col-6">
              <GenericInput
                label="Price"
                subLable={`Tick: ${tick / 100}`}
                value={
                  formState.orderType === "Market" ? "Market" : formState.price
                }
                onChange={(e) => handleInputChange("price", e.target.value)}
                error={
                  validationErrors.targetPrice
                    ? "Invalid target price"
                    : undefined
                }
                disabled={formState.orderType === "Market"}
                type={formState.orderType === "Market" ? "text" : "number"}
              />
              <div className="text-end mt-4">
                <ToggleButton<OrderType>
                  options={["Limit", "Market"]}
                  selectedOption={formState.orderType}
                  onToggle={() =>
                    handleInputChange(
                      "orderType",
                      formState.orderType === "Limit" ? "Market" : "Limit"
                    )
                  }
                />
              </div>
            </div>
          </div>
          {formState.orderType === "Limit" && (
            <div className="pt-1" style={{ color: "#FF992A", fontSize: 10 }}>
              {limitAlertText}
            </div>
          )}

        </div>

        <div
          className={`${
            formState.triggerType === "OCO" ? "col-6" : "col-12"
          } text-start border-start gtt-border-left ps-4`}
        >
          {formState.triggerType === "OCO" && (
            <>
              <div className="text-color fw-bolder">Target</div>
              <GenericInput
                label="Trigger price"
                value={formState.targetTriggerPrice || ""}
                onChange={(e) =>
                  handleInputChange("targetTriggerPrice", e.target.value)
                }
                error={
                  validationErrors.triggerPrice
                    ? "Invalid trigger price"
                    : ErrorObject.triggerErrorTriggerPrice
                }
                type="number"
                disabled={false}
              />

              <div className="row">
                <GenericInput
                  required
                  containerClassName="col-6"
                  label="Quantity"
                  subLable={`1 lot = ${lotSize}`}
                  value={formState.targetQuantity || ""}
                  onChange={(e) =>
                    handleInputChange("targetQuantity", e.target.value)
                  }
                  error={
                    validationErrors.targetQuantity
                      ? "Invalid target quantity"
                      : undefined
                  }
                  disabled={false}
                  type="number"
                />

                <div className="col-6">
                  <GenericInput
                    label="Price"
                    subLable={`Tick: ${tick / 100}`}
                    value={
                      formState.targetPrice === "Market"
                        ? "Market"
                        : formState.targetPrice || ""
                    }
                    onChange={(e) =>
                      handleInputChange("targetPrice", e.target.value)
                    }
                    error={validationErrors.price ? "Invalid price" : undefined}
                    type={
                      formState.targetPrice === "Market" ? "text" : "number"
                    }
                    disabled={formState.targetOrderType === "Market"}
                  />
                  <div className="text-end mt-4">
                    <ToggleButton<OrderType>
                      options={["Limit", "Market"]}
                      selectedOption={formState.targetOrderType || "Limit"}
                      onToggle={() =>
                        handleInputChange(
                          "targetOrderType",
                          formState.targetOrderType === "Limit"
                            ? "Market"
                            : "Limit"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {formState.targetOrderType === "Limit" && (
                <div className="pt-1" style={{ color: "#FF992A", fontSize: 10 }}>
                  {limitAlertText}
                </div>
              )}
            </>
          )}
        </div>

        <div
          className="text-color mt-4"
          style={{ fontSize: 12, lineHeight: "16px",textAlign: 'left'  }}
        >
          {GttOrderCreateTerms}
          <a
            style={{ color: "#064AD3" }}
            target="_blank"
            href="https://support.rupeezy.in/support/solutions/articles/21000002688-gtt-orders"
          >
            Learn more about GTT
          </a>
        </div>
      </div>
      <div
        style={{ height: 60 }}
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
      >
        <button
          type="submit"
          className={`btn-alert border-0 ${
            tradeDirection === "Buy" ? "btn tv-btn-success" : "btn tv-btn-danger"
          }`}
          style={{
            cursor: isDiabled ? 'not-allowed' : 'pointer'
          }}
          // onClick={() => handleAlertsTab("all-alerts")}
        >
          Modify GTT
        </button>
      </div>
    </form>
  );
};

export default EditGTTComp;
