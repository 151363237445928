import React, {
  useState,
  FormEvent,
  ChangeEvent,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ReactComponent as Clear } from "../../../assets/images/close-square2.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search-icon.svg";

import AlertContainer from "../AlertContainer";
import { Alerts } from "../AlertTools";
import useScripLookUp, {
  ScripLookUpHitSource,
} from "../../../hooks/api/get/getScripLookUp";
import { DataFeedInstance } from "../../../chart-datafeed/udf-compatible-datafeed";
// import { Quotes } from "../../../models/symbolData";
import nextId from "react-id-generator";
import { Quotes } from "../../../models/symbolData";
import useUpdateAlert, {
  UpdateAlertPayload,
} from "../../../hooks/api/put/useUpdateAlert";
import MarketSegmentDetails from "../common/MarketSegmentDetails";
import AlertNameInput from "../common/AlertNameInput";
import PropertyDropdown from "../common/PropertyDropdown";
import NoteInput from "../common/NoteInput";
import ValueInput from "../common/ValueInput";
import LastTradedPriceInput from "../common/LastTradedPriceInput";
import { AlertData } from "../../../hooks/api/get/getAlerts";
import SearchResultsDropdown, {
  SearchArrayType,
} from "../common/SearchResultsDropdown";
import { productCode } from "../../../utils/alertsProductCode";
import {
  Conditions,
  conditionToString,
  propertyToStringForEdit,
} from "../../../helper/alert/condition";
import ConditionDropdown from "../common/ConditionDropdown";
import BrokerApi from "../../../chart-brokerapi/broker";
import ErrorText from "../common/ErrorText";
import { useDispatch } from "react-redux";

import { toggleAlertsListModel } from "../../../slices/alertSlices";

// Define interface for form data
interface AlertFormData {
  searchQuery: string;
  alertName: string;
  property: string;
  condition: string;
  valueInr: string;
  lastTradedPrice: string;
  // expiry: string;
  note: string;
}

interface CreateOrEditFormProbs {
  handleAlertsTab: (alerts: Alerts) => void;
  probs: Omit<AlertData, "status">;
}

const EditAlertForm: React.FC<CreateOrEditFormProbs> = ({
  handleAlertsTab,
  probs,
}) => {
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedToken, setSelectedToken] = useState<number | null>(null);
  const [selectedMarketSegmentId, setSelectedMarketSegmentId] = useState<
    number | null
  >(null);
  const [isnIsIndex, setIsnIsIndex] = useState<boolean>(false);
  const [selectedMarketSegment, setSelectedMarketSegment] = useState<
    Quotes["v"] | null
  >(null);
  const dispatch = useDispatch();

  const [serchType, setSearchType] = useState<SearchArrayType>("All");

  const [priceValue, setPriceValue] = useState<number | null>(null); // we will be having data form the socket and we will save the value of certain instance when the condition input is changed
  const [volume, setVolume] = useState<number | null>(null);

  const hasBeenMountedRef = useRef(false);

  const [formData, setFormData] = useState<AlertFormData>({
    searchQuery: "",
    alertName: "",
    property: "",
    condition: "",
    valueInr: "", // Converting to String : )
    lastTradedPrice: "",
    // expiry: "",
    note: "",
  });

  useEffect(() => {
    if (probs) {
      setFormData({
        searchQuery: probs.stock_data?.symbol || "",
        alertName: probs.name || "",
        property: probs.property || "",
        condition: probs.condition || "",
        valueInr: probs.triggerValue?.toString() || "",
        lastTradedPrice: "",
        note: probs.note || "",
      });

      // Explicitly set these to ensure the MarketSegmentDetails is shown
      setSelectedToken(probs.token);
      setSelectedMarketSegmentId(probs.marketSegmentId);
    }
  }, [probs]);

  const { data, loading, error } = useScripLookUp({
    scripName: formData.searchQuery,
    productCode: productCode(serchType),
    serchType: serchType,
  });

  const dataForMaretingSegment = data?.find(
    (item) => item.nToken === probs.token
  );

  const { mutate, error: createError, isError, isLoading } = useUpdateAlert();

  // Handle input changes for text and textarea inputs
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    },
    []
  );

  const handleINRChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      const changeValue = parseFloat(value) || 0;
      const initialValue =
        parseFloat(
          formData.property === "VOLUME"
            ? volume?.toString() ?? "0"
            : priceValue?.toString() ?? "0"
        ) || 0;

      // Calculate percentage change differently for VOLUME
      const alertPercentValueChange =
        formData.property === "VOLUME"
          ? ((changeValue - initialValue) / initialValue) * 100
          : ((changeValue - initialValue) / initialValue) * 100;

      const formattedPercentChange = alertPercentValueChange.toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
        lastTradedPrice: formattedPercentChange,
      }));
    },
    [volume, priceValue, formData.property]
  );

  const onLastTradedPriceChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      const changeValue = parseFloat(value) || 0;
      const initialValue =
        parseFloat(
          formData.property === "VOLUME"
            ? volume?.toString() ?? "0"
            : priceValue?.toString() ?? "0"
        ) || 0;

      // Calculate new value based on percentage change
      const alertPercentValueChange =
        formData.property === "VOLUME"
          ? (initialValue * (1 + changeValue / 100)).toFixed(2)
          : (initialValue * (1 + changeValue / 100)).toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
        valueInr: alertPercentValueChange,
      }));
    },
    [volume, priceValue, formData.property]
  );

  const handleNoteClear = useCallback(() => {
    setFormData((prevState) => ({
      ...prevState,
      note: "",
    }));
  }, [formData.note]);

  // Handle dropdown changes
  const handleDropdownChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  // Handle search result selection
  const handleSearchResultSelect = useCallback((item: ScripLookUpHitSource) => {
    setFormData((prev) => ({
      ...prev,
      searchQuery: item.sSymbol,
    }));

    setSelectedToken(item.nToken);
    setSelectedMarketSegmentId(item.MktSegId === 8 ? 3 : item.MktSegId);
    setIsnIsIndex(!!item.nIsIndex);
    setShowSearchResults(false);
  }, []);

  // Handle form submission
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
      e.preventDefault();

      // Validate required fields before submission
      if (!selectedMarketSegmentId || !selectedToken) {
        return;
      }

      // Prepare payload with type safety
      const payload: UpdateAlertPayload = {
        id: probs.id, // Ensure this is correctly passed
        basketId: null,
        condition: formData.condition.trim(),
        marketSegmentId: selectedMarketSegmentId,
        name: formData.alertName.trim(),
        note: formData.note.trim(),
        property: formData.property.trim(),
        triggerValue: parseFloat(formData.valueInr),
        token: selectedToken,
      };

      // Ensure mutate is called with proper error handling
      mutate(payload, {
        onSuccess: (responseData) => {
          BrokerApi.instance?._host.showNotification(
            "Alert Updated Successfully",
            `${formData.alertName} has been updated successfully`,
            1
          );
          handleAlertsTab("all-alerts");
          dispatch(toggleAlertsListModel(false));
        },
        onError: (error: any) => {
          BrokerApi.instance?._host.showNotification(
            error?.message || "Something Went Wrong",
            `${formData.alertName} has not been updated successfully`,
            1
          );
        },
      });
    },
    [
      formData,
      selectedMarketSegmentId,
      selectedToken,
      mutate,
      probs.id,
      handleAlertsTab,
    ]
  );

  // Reset form data
  const resetForm = () => {
    setFormData({
      searchQuery: "",
      alertName: "",
      property: "",
      condition: "",
      valueInr: "",
      lastTradedPrice: "",
      // expiry: "",
      note: "",
    });
  };

  const reSetInput = useCallback(() => {
    setSelectedMarketSegmentId(null);
    setSelectedToken(null);
    setFormData((prev) => ({
      ...prev,
      searchQuery: "",
    }));
    // resetForm();
  }, []);

  useEffect(() => {
    if (
      formData.condition &&
      !formData.valueInr &&
      (priceValue !== null || volume !== null)
    ) {
      setFormData((prevState) => ({
        ...prevState,
        lastTradedPrice: "0",
        valueInr:
          formData.property === "VOLUME"
            ? volume?.toString() ?? "0"
            : priceValue?.toString() ?? "0",
      }));
    } else if (formData.property && (priceValue !== null || volume !== null)) {
      setFormData((prevState) => ({
        ...prevState,
        lastTradedPrice: "0",
        valueInr:
          formData.property === "VOLUME"
            ? volume?.toString() ?? "0"
            : priceValue?.toString() ?? "0",
      }));
    }
  }, [formData.condition, formData.property, priceValue, volume]);

  useEffect(() => {
    let listenerGuid: string | undefined;

    // Unsubscribe previous subscription if exists
    const cleanup = () => {
      if (listenerGuid) {
        DataFeedInstance?.unsubscribeQuotes(listenerGuid);
      }
    };

    // Subscribe to new quotes if token and market segment are available
    if (selectedToken && selectedMarketSegmentId) {
      listenerGuid = nextId();

      DataFeedInstance?.subcscribeQuotesFromTokens(
        [{ market_segment_id: probs.marketSegmentId, token: probs.token }],
        (quotes: Quotes[]) => {
          const selectedSegment = quotes[0].v;
          setSelectedMarketSegment(selectedSegment);

          // Use the ref to ensure this block runs only once
          if (!hasBeenMountedRef.current) {
            const valueToSet = probs.triggerValue;
            const isVolume = probs.property === "VOLUME";

            setPriceValue(!isVolume ? valueToSet : selectedSegment.lp);
            setVolume(isVolume ? valueToSet : selectedSegment.volume);

            if (isVolume) hasBeenMountedRef.current = false;
            else hasBeenMountedRef.current = true;
          }
        },
        listenerGuid
      );
    }

    // Cleanup subscription on component unmount or dependency change
    return cleanup;
  }, [selectedToken, selectedMarketSegmentId]);

  // Memoized property options
  const propertyOptions = useMemo(() => {
    if (isnIsIndex) {
      return [
        { value: "", label: propertyToStringForEdit("") },
        { value: "LTP", label: propertyToStringForEdit("LTP") },
      ];
    }
    return [
      { value: "", label: propertyToStringForEdit("") },
      { value: "LTP", label: propertyToStringForEdit("LTP") },
      { value: "AVGPRICE", label: propertyToStringForEdit("AVGPRICE") },
      { value: "VOLUME", label: propertyToStringForEdit("VOLUME") },
    ];
  }, [isnIsIndex]);

  // Memoized condition options
  const conditionOptions = useMemo(() => {
    const baseOptions = [
      { value: "", label: conditionToString("") },
      { value: "GE", label: conditionToString("GE") },
    ];

    if (formData.property !== "VOLUME") {
      baseOptions.push(
        { value: "EQ", label: conditionToString("EQ") },
        { value: "LE", label: conditionToString("LE") }
      );
    }

    return baseOptions;
  }, [formData.property]);
  // Render search results dropdown

  const isValid = !!formData.valueInr && ((formData.property !== "VOLUME" && +formData.valueInr >= selectedMarketSegment?.lp!) || (formData.property === "VOLUME" && +formData.valueInr >= selectedMarketSegment?.volume!));

  const hasNoEmptyFields = Object.entries(formData).some(
    ([key, field]) => {
      // Check if the field is an empty string and the key is NOT one of the excluded fields
      return field === "" && !["note"].includes(key);
    }
  )


  const isAnyFieldEmptyFromFormData = hasNoEmptyFields || !isValid;

  

  return (
    <>
      <AlertContainer>
        <div className="px-5 py-4">
          <Form onSubmit={handleSubmit}>
            {/* Search Input Group */}
            {/* Search Results Section */}
            {/* Search and Market Segment Section */}
            {!selectedToken || !selectedMarketSegmentId ? (
              <div className="position-relative w-100">
                <InputGroup>
                  <Form.Control
                    id="searchQuery"
                    type="text"
                    className="alert-main-input"
                    placeholder={`BankNifty 36500 optional contracts, "NIFTY FUT" for NIFTY future contracts, "USDINR 76" for currency`}
                    aria-label="Search stock alerts"
                    aria-autocomplete="list"
                    autoComplete="off"
                    value={formData.searchQuery}
                    onChange={handleInputChange}
                    onClick={() => setShowSearchResults(true)}
                    style={{ borderRight: 0 }}
                  />
                  <InputGroup.Text
                    className="bg-transparent search-bar"
                    aria-label="Search icon"
                  >
                    {formData.searchQuery.length ? (
                      <Clear onClick={reSetInput} />
                    ) : (
                      <SearchIcon />
                    )}
                  </InputGroup.Text>
                </InputGroup>

                <SearchResultsDropdown
                  isError={!!error}
                  searchType={serchType}
                  setSearchType={setSearchType}
                  showSearchResults={showSearchResults}
                  searchQuery={formData.searchQuery}
                  loading={loading && !!formData.searchQuery}
                  data={data}
                  handleSearchResultSelect={handleSearchResultSelect}
                />
              </div>
            ) : (
              <MarketSegmentDetails
                data={data!}
                isEditable={false}
                formData={formData}
                selectedMarketSegment={selectedMarketSegment ?? undefined}
                reSetInput={reSetInput}
              />
            )}

            {/* Loading and Error States */}
            {/* {loading && formData.searchQuery && (
              <div
                className="text-center text-muted mt-2"
                role="alert"
                aria-live="polite"
              >
                Searching for "{formData.searchQuery}"...
              </div>
            )}

            {error && (
              <div
                className="text-danger mt-2"
                role="alert"
                aria-live="assertive"
              >
                Error: {error}
              </div>
            )} */}

            {/* Alert Name Input */}
            <AlertNameInput
              label="Alert Name"
              value={formData.alertName}
              onChange={handleInputChange}
              disabled={false}
              error={!formData.alertName ? "Alert name is required" : undefined}
            />
            {!formData.alertName && (
              <ErrorText message={"Alert name is required"} />
            )}

            {/* Property Dropdown */}
            <PropertyDropdown
              label="Property"
              value={formData.property}
              propertyOptions={propertyOptions}
              onChange={handleDropdownChange}
              disabled={!formData.alertName || loading}
            />

            {!formData.property && (
              <ErrorText message={"Please select a property"} />
            )}

            {/* Condition Dropdown */}
            <ConditionDropdown
              formData={formData}
              loading={loading}
              conditionOptions={conditionOptions}
              handleDropdownChange={handleDropdownChange}
            />
            {!formData.condition && (
              <ErrorText
                message={
                  "Please select a condition eg. Greate Than or Equal or Less Than"
                }
              />
            )}

            <div className="d-flex gap-2 mt-4 align-items-center">
              <div className="flex-grow-1">
                <ValueInput
                  property={formData.property as Conditions}
                  value={formData.valueInr}
                  onChange={handleINRChange}
                  disabled={!formData.condition || loading}
                  error={
                    !formData.valueInr
                      ? "Please enter a Value" : undefined
                  }
                />
                {!formData.valueInr ? (
                  <ErrorText message="Please enter a Value." />
                ) : formData.property !== "VOLUME" ? (
                  +formData.valueInr < selectedMarketSegment?.lp! ? (
                    <ErrorText
                      message={`Price should be > ${selectedMarketSegment?.lp}`}
                    />
                  ) : undefined
                ) : +formData.valueInr < selectedMarketSegment?.volume! ? (
                  <ErrorText
                    message={`Volume should be > ${selectedMarketSegment?.volume}`}
                  />
                ) : undefined}
              </div>
              <strong
                className={`${!isAnyFieldEmptyFromFormData ? "pt-5" : ""} text-color`}
              >
                Or
              </strong>
              <div className="flex-grow-1">
                <LastTradedPriceInput
                  property={formData.property as Conditions}
                  value={formData.lastTradedPrice}
                  onChange={onLastTradedPriceChange}
                  disabled={!formData.condition || loading}
                  error={
                    !formData.lastTradedPrice
                      ? "Please enter a Value"
                      : undefined
                  }
                />
                {!formData.valueInr ? (
                  <ErrorText message="Please enter a Value." />
                ) : formData.property !== "VOLUME" ? (
                  +formData.valueInr < selectedMarketSegment?.lp! ? (
                    <ErrorText
                      message={`Price should be > ${selectedMarketSegment?.lp}`}
                    />
                  ) : undefined
                ) : +formData.valueInr < selectedMarketSegment?.volume! ? (
                  <ErrorText
                    message={`Volume should be > ${selectedMarketSegment?.volume}`}
                  />
                ) : undefined}
              </div>
            </div>

            {/* Add a note (Optional) */}
            <NoteInput
              onClear={handleNoteClear}
              value={formData.note}
              onChange={handleInputChange}
              disabled={!formData.condition || loading}
            />
          </Form>
        </div>
      </AlertContainer>
      <div
        style={{ height: 60 }}
        className="px-5 alerts-create-edit-background d-flex justify-content-end gap-3 py-2"
      >
        <button
          type="button"
          className="btn-alert secondary"
          onClick={() => handleAlertsTab("all-alerts")}
        >
          Cancel
        </button>
        <button
          disabled={isAnyFieldEmptyFromFormData || isLoading}
          style={{
            cursor: isAnyFieldEmptyFromFormData ? "not-allowed" : "pointer",
            opacity: isAnyFieldEmptyFromFormData ? 0.7 : 1,
          }}
          type="button"
          className="btn-alert primary"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </>
  );
};

export default EditAlertForm;
