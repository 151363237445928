import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { decodeBase64, STATUSES } from "../comman/status";
import { MOMENT_KEY, STORAGE_KEY, STORAGE_KEY_REFRESH } from "../utils/config";
import AuthService from "../services/authService";
import { datadogRum } from '@datadog/browser-rum';
import { LoginInfo } from "../models/login";

// Define the shape of your state
interface AuthState {
    user: LoginInfo | null; // Define a more specific type if possible
    isLoggedIn: boolean;
    status: string;
    error_message: string | null;
    email: string | null;
    mobile: string | null;
    data: any | null; // Define a more specific type if possible
    user_id: string | null;
    themeData: any; // Define a more specific type if possible
    theamDataBG:any;
    tokenExp: {
        ssoToken: boolean;
        checked?: boolean;
    };
    sessionTime: number;
}

// Initial state
const user = JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null');
const sessionTime = JSON.parse(localStorage.getItem(MOMENT_KEY) || '0');
const refreshData = JSON.parse(localStorage.getItem(STORAGE_KEY_REFRESH) || 'null');

const initialState: AuthState = {
    user,
    isLoggedIn: !!user,
    status: STATUSES.IDLE,
    error_message: null,
    email: null,
    mobile: null,
    data: refreshData,
    user_id: null,
    themeData: null,
    theamDataBG:null,
    tokenExp: { ssoToken: false },
    sessionTime: Number(sessionTime)
};

function checkTokenValid(state: AuthState) {
    const sso_token = state.user?.ssoToken;
    if (sso_token) {
        let decodeValue = JSON.parse(decodeBase64(sso_token.split('.')[1]));
        state.data = { ...state.data, applicationId: decodeValue.applicationId };
        let d = new Date(0);
        let momentInTime = moment(d.setUTCSeconds(decodeValue.exp));
        let now = moment();
        let val = now.isAfter(momentInTime);
        let diff = now.diff(momentInTime, 'minutes');
        if (diff > 0) {
            state.tokenExp = { ...state.tokenExp, ssoToken: true };
        } else {
            state.tokenExp = { ...state.tokenExp, ssoToken: false, checked: true };
        }
    }
}

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<LoginInfo>) {
            datadogRum.setUser({
                id: action.payload.user_id,
                name: action.payload.user_name,
                email: action.payload.email,
            });
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        setStatus(state, action: PayloadAction<string>) {
            state.status = action.payload;
        },
        setMessage(state, action: PayloadAction<string>) {
            state.error_message = action.payload;
        },
        clearMessage(state) {
            state.error_message = null;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.user = null;
        },
        setMpinEnable(state) {
            if (state.user) {
                state.user.mpin_enabled = true;
            }
        },
        setEmailAndMobile(state, action: PayloadAction<{ email: string | null; mobile: string | null}>) {
            state.email = action.payload.email;
            state.mobile = action.payload.mobile;
        },
        setQrData(state, action: PayloadAction<{ data: any }>) {
            state.data = action.payload.data;
        },
        setOtpData(state, action: PayloadAction<{ data: any; user_id: string | null }>) {
            state.data = action.payload.data;
            state.user_id = action.payload.user_id;
        },
        setSessionTime(state, action: PayloadAction<number>) {
            state.sessionTime = action.payload;
        },
        setThemeData(state, action: PayloadAction<any>) {
            state.themeData = action.payload;
        },
        initialCheckData(state) {
            checkTokenValid(state);
        },
        loginEvent(state) {
            (window as any).GtmPusher({
                event: 'tv-login',
                email: state.email,
                mobile_number: state.mobile
            });
        },
        switchUserEvent(state) {
            (window as any).GtmPusher({
                event: 'tv-switch_user',
            });
        },
        TotpDoLaterEvent(state) {
            (window as any).GtmPusher({
                event: 'tv-totp_do_later',
            });
        },
    },
});

export const currentUser = (state: { auth: AuthState }) => state.auth.user;
export const { setUser, setStatus, setMessage, clearMessage, setThemeData,
    setEmailAndMobile, setOtpData, setSessionTime, logout, loginEvent, setMpinEnable, initialCheckData,
    switchUserEvent, TotpDoLaterEvent, setQrData } = authReducer.actions;
export default authReducer.reducer;
