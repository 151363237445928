import React, {useEffect, useState} from 'react';
import BucketList from "./bucketList";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {currentUser} from "../../slices/authSlice";
import flowBackEndService from "../../services/flowBackendApi";
import Spinner from "react-bootstrap/Spinner";
import EmptyState from "./emptyState";
import CreateBasket from "./Create/createBasket";
import BasketDetails from "./basketDetails/basketDetails";
import SharedBasketDetails from "./SharedBasketDetails"
import BasketAnalyst from './BasketAnalyst/BasketAnalyst';
import {basketSubServices} from "../../sub";
import {setListDataMain} from "../../slices/basketOrderSlice";
import { Subscription } from 'rxjs';
import {AllBaskets, FetchBasketResponse,BasketState} from "../../models/basketOrders"


const BasketFetch = () => {

    const  {current_basket}  = useSelector((state:any) => state.basketOrder);
    const user = useSelector(currentUser);
    const dispatch = useDispatch();
    const [ listData, setListData ] = useState<AllBaskets[]>([])
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        dispatch(setListDataMain(listData))
    }, [listData])

    useEffect(() => {
         fetchBasketList()
         handleBaaketAdd()
        return () => {
            subscriptionBasket?.unsubscribe();
        }
    }, [])


    let subscriptionBasket:Subscription | undefined = undefined;
    function handleBaaketAdd() {

        subscriptionBasket = basketSubServices.getSub().subscribe(value => {
           if(value) fetchBasketList()
        });

    }


    function chekCurrentState() {
        if(current_basket.id === 1){
            if(current_basket.param?.fetch_new){
                fetchBasketList();
            }
        }
    }

    useEffect(() => {
       if (current_basket) chekCurrentState()
    }, [current_basket])


    function fetchBasketList() {
        setIsLoading(true)
        flowBackEndService.getBuckets(String(user?.access_token))
            .then(value => {
                let response:FetchBasketResponse=value.data
                if(response.status === "success"){
                    setListData(response.response)
                    if(response.response[0].ordersCount===0){
                        response.response[0].basketOrders=[]
                    }
                }
            })
            .catch(error => {
                // BrokerApiInstance._host.showNotification("Error fetching baskets",error.response.data.message ,0)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    if (isLoading) {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center">
                <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
            </div>
        )
    }

    if (current_basket === null) {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center">
                <Spinner style={{ marginTop:'100px'}} className="mr-2 pri" as="span" animation="border" variant="primary" size="sm" role="status" aria-hidden="true"/>
            </div>
        )
    }else if(current_basket.id === 1){

        if (listData.length === 0) {
            return <EmptyState/>;
        }else {
            return <BucketList listData={listData}/>;
        }

    }else if(current_basket.id === 2){
        return <CreateBasket/>;
    }else if(current_basket.id === 3){
        return <BasketDetails data={current_basket.param}/>;
    }else if(current_basket.id === 4){
        return <SharedBasketDetails />
    }else if(current_basket.id === 5){
        return <BasketAnalyst />
    }


    return (
        <div></div>
    );
};

export default BasketFetch;

