import React, {useEffect} from 'react';
import frameIcon from "../../assets/icons/Frame.svg";
import BasketketHeader from "./Header/bacsketHeader";
import cartIcon from "../../assets/icons/shopping-cart.svg";
import {useDispatch} from "react-redux";
import {setCurrentBasketState} from "../../slices/basketOrderSlice";
import {BasketState} from "../../models/basketOrders";

const EmptyState=()=> {

    const dispatch = useDispatch();


    function createNewBasket() {
        let state:BasketState =  {id: 2,param: null,url:'CreateBasket'};
        dispatch(setCurrentBasketState(state))
    }


    return (
        <div>
            <BasketketHeader>
                <span className="heading2"> <img width={30} className="" src={cartIcon} alt={''}/>&nbsp;Basket Orders</span>
            </BasketketHeader>

            <div className="d-flex flex-column justify-content-center align-items-center">
                <div style={{ height:'40px'}} className="">&nbsp;</div>
                <div className=""><img src={frameIcon} alt={''}/></div>
                <div className="heading2 mt-5">No Basket Orders</div>
                <div className="text1 colorGrey">Add stocks to create a basket order.</div>
                <button onClick={createNewBasket} className="btn w-40 blue_btn text-nowrap mt-5">Create New Basket</button>
            </div>
        </div>
    );
}

export default EmptyState;
