import { useState, useEffect, useCallback } from "react";
import axios, { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";
import { RootState } from "../../../store";

// Type Definitions for the Response
export type ExchangeMap = {
  exchange: string;
  token: number;
};

export type StockDetailsResponse = {
  symbol: string;
  instrumentName: string;
  securityDesc: string;
  expiryDate: string;
  strikePrice: string;
  optionType: string;
  marketSegmentID: string;
  token: number;
  isinCode: string;
  expiryKey: string;
  expiryYMD: string;
  expiryMonth: string;
  eligibility: number;
  series: string;
  lotSize: number;
  tick: number;
  hasOptionChain: boolean;
  session: string;
  industry: string;
  sectorName: string;
  asmGsmStage: string;
  has_icon: boolean;
  is_mtf: boolean;
  marginMultiplier: number;
  exchangeMaps: ExchangeMap[];
};

export type ApiResponse = {
  message: string;
  response: StockDetailsResponse;
  status: "success" | "failure";
};

export type UseStockDetailsOptions = {
  enabled?: boolean;
  onSuccess?: (data: StockDetailsResponse) => void;
  onError?: (error: string) => void;
};

const useStockDetails = (
  scripToken: number | null, 
  instrumentType: string, 
  options: UseStockDetailsOptions = {}
) => {
  // Destructure options with default values
  const { 
    enabled = true, 
    onSuccess, 
    onError 
  } = options;

  // Get access token from Redux store
  const token = useSelector((state: RootState) => state.authUser.user?.access_token);

  // State management
  const [stockDetails, setStockDetails] = useState<StockDetailsResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch stock details function
  const fetchStockDetails = useCallback(async () => {
    // Early return conditions
    if (!enabled || !scripToken || !token) {
      setLoading(false);
      return;
    }

    // Reset states before fetch
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post<ApiResponse>(
        `${FLOW_BACKEND_BASE_URL}aux/scripdetails`,
        {
          scripToken,
          instrumentType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle successful response
      if (response.data.status === "success") {
        const details = response.data.response;
        setStockDetails(details);
        
        // Optional success callback
        onSuccess?.(details);
      } else {
        const errorMessage = response.data.message || "Failed to fetch stock details";
        setError(errorMessage);
        onError?.(errorMessage);
      }
    } catch (err: unknown) {
      // Comprehensive error handling
      let errorMessage = "An unexpected error occurred";

      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError<{ message?: string }>;
        if (axiosError.response) {
          // Server responded with an error
          errorMessage = axiosError.response.data?.message || 
                         `Error: ${axiosError.response.status}`;
        } else if (axiosError.request) {
          // Request made but no response received
          errorMessage = "No response received from the server";
        } else {
          // Something happened in setting up the request
          errorMessage = axiosError.message;
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }

      setError(errorMessage);
      onError?.(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [scripToken, instrumentType, token, enabled, onSuccess, onError]);

  // Trigger fetch on mount or when dependencies change
  useEffect(() => {
    fetchStockDetails();
  }, [fetchStockDetails]);

  // Return hook values and methods
  return {
    stockDetails,
    loading,
    error,
    refetch: fetchStockDetails,
  };
};

export default useStockDetails;