import React, {useEffect, useState} from 'react';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {NumberColor} from "../../common/number_color";
import {Quotes} from "../../../models/symbolData"
import Subscribed from "../../../models/symbolData"

interface TopHeaderProps {
    close: () => void;
    data: {
      _source: {
        nToken: string;
        MktSegId: string;
      };
    };
    subDatafun: (data: any | null) => void;
    handleBuySell: (isBuy: boolean) => void;
    buySell: boolean;
  }
const TopHeader:React.FC<TopHeaderProps> = ({close,data,subDatafun,handleBuySell,buySell}) => {

    const [ subData, setSubData ] = useState<Subscribed | null>(null);


    useEffect(() => {
        subDatafun(subData)
    }, [subData])

    useEffect(() => {
        // console.log('mount TopHeader')
    }, [])


    useEffect(() => {
        headerData()
        return () => {
            removeSub()
            // console.log('UnMount TopHeader')
        }
    }, [data])

    let listenerGuid:string | undefined = undefined
    function headerData() {
        listenerGuid = `top-${data._source.nToken}-${data._source.MktSegId}`
        // console.log("listenerGuid",listenerGuid)
        DataFeedInstance?.subcscribeQuotesFromTokens([
            {market_segment_id: Number(data._source.MktSegId) , token: Number(data._source.nToken) }],(quotes:Quotes[])=>{
             // console.log("getQuotes data TopHeader",quotes);
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(listenerGuid)
    }

    if(subData == null){
        return (
            <div className="d-flex header-basket flex-row w-100 justify-content-start align-items-start">
                <div className="p-3 mr-3">
                    <span className="heading2"> <i onClick={close} className="fa fa-arrow-left c-pointer" aria-hidden="true"/></span>
                </div>
            </div>
        );
    }


    return (
        <div className="d-flex header-basket flex-row w-100 justify-content-start align-items-start">
            <div className="p-3 mr-3">
                <span className="heading2"> <i onClick={close} className="fa fa-arrow-left c-pointer" aria-hidden="true"/></span>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start align-self-start">
                <div className=" d-flex flex-row">
                    <div className="heading_stock_name currentText2">{subData.short_name}</div>
                    <div id="badge_exch" className="mt-1">{subData.exchange}</div>
                </div>
                <div className="heading_light currentText">{subData.description}</div>
                <div className="m_text d-flex currentText flex-row justify-content-start align-items-center">
                    <div className="mr-2">{subData.lp}</div>
                    <NumberColor number={subData.ch}  />
                    <div className="pl-1"/>
                    <NumberColor number={subData.chp} numFix={true} percentageAdd={true} isFiniteCheck={true} returnBracket={true}/>
                </div>
            </div>
            <div className="d-flex flex-grow-1 flex-row-reverse p-2">
                <div className="buySellBx">
                    <div onClick={() => handleBuySell(true)} className={`c-pointer ${buySell ? 'buyBox':'ml-3'}`}>Buy</div>
                    <div onClick={() => handleBuySell(false)} className={`c-pointer ${buySell ? 'mr-3':'sellBox'}`}>Sell</div>
                </div>
            </div>
        </div>
    );
};

export default TopHeader;
