import { Status } from "../Alerts/AlertTile";
import "./badge.scss";

interface BadgeProps {
  Status: Status;
}

const Badge: React.FC<BadgeProps> = ({ Status }) => {
  const css: Record<Status, string> = {
    active: 'green-badge',
    inactive: 'red-badge',
  };

  return (
    <span className={`py-1 px-2 br-6 font-10 ${css[Status]}`}>
      {Status === "active" ? "Active" : "Triggred"}
    </span>
  );
};

export default Badge;
