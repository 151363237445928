import { OptionTimeframe } from "../gttTypes";
import "./gttTabs.scss";

type GttTabsProps = {
  optionTimeframe: OptionTimeframe[];
  selected: OptionTimeframe;
  toggleOptionTimeframe: (timeframe: OptionTimeframe) => void;
  disabled?: boolean;  // Optional disabled parameter
};

const GTTTabs = ({ optionTimeframe, selected, toggleOptionTimeframe, disabled = false }: GttTabsProps) => {
  return (
    <div className="d-flex px-5 text-color fw-bolder border-bottom gtt-tabs-border-white">
      {optionTimeframe.map((timeframe) => {
        const isSelected = selected === timeframe;
        const isDisabled = disabled; // Make all tabs disabled if 'disabled' is true
        const opcaityFade = !isSelected && !!isDisabled ? .7 : 1;

        return (
          <div
            key={timeframe}
            onClick={() => !isDisabled && toggleOptionTimeframe(timeframe)} // Only toggle if not disabled
            className={`py-2 px-4 gtt-gray-hover ${isSelected ? "gtt-selected-border-bottom gtt-hover-gray font-700" : "font-400"} ${isDisabled ? "dull-opacity" : ""}`}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' ,opacity : opcaityFade, fontSize:16}} // Disable cursor when disabled
          >
            {timeframe}
          </div>
        );
      })}
    </div>
  );
};

export default GTTTabs;
