import React, { useState } from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, Tooltip, TooltipProps } from 'recharts';
import { Payoff } from "../../../models/strategy";

interface Props {
  payOffData: Payoff[];
  expanded: boolean;
}

const AnalyseGraph: React.FC<Props> = ({ payOffData, expanded }) => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);

  const handleTooltipEnter = () => {
    setTooltipActive(true);
  };

  const handleTooltipLeave = () => {
    setTooltipActive(false);
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'short',
    day: '2-digit',
    month: 'short'
  });

  const gradientOffset = () => {
    const dataMax = Math.max(...payOffData.map(i => i.expiry_pay_off));
    const dataMin = Math.min(...payOffData.map(i => i.expiry_pay_off));
    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };

  const offset = gradientOffset();

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className='label'>Expected P&L at &nbsp;
            <span style={{ fontSize: "15px", fontWeight: 500 }}>
              {payload[0]?.payload["at"]}
            </span>
          </div>
          {payload.map((data, index) => (
            <div
              key={index}
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className='label'>
                {data.name === 'intraday_pay_off' ? currentDate : "At expiry"}
              </div>
              <div
                className='payOffValue'
                style={{ color: `${data.value! > 0 ? '#28C269' : '#FE4141'}` }}
              >
                {data.value?.toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderAnalyseChart = () => (
    <ComposedChart width={expanded ? 870 : 400} height={210} data={payOffData}>
      <XAxis 
        dataKey="at"
        axisLine={{ stroke: '#555', strokeWidth: 2 }}
        tick={{ className: "custom-axis-label", dy: 12 }}
      />
      <YAxis 
        axisLine={{ stroke: '#555', strokeWidth: 2 }}
        tick={{ className: "custom-axis-label", dx: 0, dy: 2 }}
      />
      <Tooltip
        content={<CustomTooltip />}
        // onMouseEnter={handleTooltipEnter}
        // onMouseLeave={handleTooltipLeave}
        isAnimationActive={false}
        active={tooltipActive}
        wrapperStyle={{ outline: "none" }}
      />
      <defs>
        <linearGradient id="splitColor" x1="1" y1="0" x2="1" y2="1">
          <stop offset={offset} stopColor="#28C269" stopOpacity={0.5} />
          <stop offset={offset} stopColor="#FE4141" stopOpacity={0.5} />
        </linearGradient>
        <linearGradient id="lineColor" x1="1" y1="0" x2="1" y2="1">
          <stop offset={offset} stopColor="#28C269" stopOpacity={1} />
          <stop offset={offset} stopColor="#FE4141" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Line
        type="monotone"
        dataKey="intraday_pay_off"
        stroke="#064AD3"
        dot={false}
        strokeWidth={2}
      />
      <Area
        type="monotone"
        dataKey="expiry_pay_off"
        fill="url(#splitColor)"
        yAxisId={0}
        fillOpacity={0.5}
        strokeWidth={2}
        stroke="url(#lineColor)"
        dot={false}
        isAnimationActive={false}
      />
    </ComposedChart>
  );

  return <>{renderAnalyseChart()}</>;
};

export default AnalyseGraph;
