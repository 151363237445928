import React, {useEffect, useState, useRef} from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {numDifferentiation} from "../../comman/status";
import nextId from "react-id-generator";
import {NumberColor,ExchangeSegmentMap} from "../common/number_color";
import BuySellButton from "./BuySellButton"
import {SpotService, ceService, BuySellServices, layoutSubServices,ceIoService} from "../../sub";
import VisibilitySensor from 'react-visibility-sensor'
import {OptionProps} from '../../models/optionChain'
import {Quotes} from '../../models/symbolData'
import { Subscription } from "rxjs";
import { debounce } from "lodash";


const CallData:React.FC<OptionProps> = ({data,sp,showZeroOi,vega,theta,gamma,delta})=>{
    const [ yellowShade, setYellowShade ] = useState(false)
    const [ subData, setSubData ] = useState<any>(null)
    const [ barData, setBarData ] = useState<number>(0);
    const [ barIoData, setBarIoData ] = useState<number>(0);
    const [ max, setMax ] = useState<number>(0);
    const [ maxIo, setMaxIo ] = useState<number>(0);
    const [ buySellSymbol, setBuySellSymbol ] = useState<string | null>(null);
    const [changeOi,setChangeOi] = useState<number | null>(null);
    const [ltpChangePercent,setLtpChangePercent] = useState<string>("0");
    const [ltpChangeClass,setLtpChangeClass]=useState<string | null>(null);
    const [oiChangePercent,setOiChangePercent] = useState<string>("0");
    const [oiChangeClass,setOiChangeClass]=useState<string | null>(null);
    const [guide,setGuide] =useState<string | undefined | null>(null)

    const guideRef = useRef<string | null>(null);

    function calPercentage(minvolume:number, maxVolume:number) {
        let width=0
        if(maxVolume>0){
             width =  (minvolume/maxVolume) * 100
        }       setBarData((width/100)*80)
    }
    function calIoPercentage(minimumIo:number, maximumIo:number) {
        let width=0
        if(maximumIo>0){
             width =  (minimumIo/maximumIo) * 100
        }        setBarIoData((width/100)*80)
    }
    useEffect(() => {
        if (subData) {
            ceService.sendMax(Number(subData.volume))
            // setVolumeCE(Number(subData.oi))
            // calPercentage(Number(subData.oi),maxVolumeCE)
        }
    }, [subData?.volume,max])
    useEffect(() => {
        if (subData) {
            ceIoService.sendMaxIo(Number(subData.oi))
            // setVolumeCE(Number(subData.oi))
            // calPercentage(Number(subData.oi),maxVolumeCE)
        }
    }, [subData?.oi,maxIo])

    useEffect(() => {
        guideRef.current = guide as string;
      }, [guide]);

    useEffect(()=>{
        if(subData) changeOiData()
    })
    useEffect(()=>{
        if(subData) checkLtpChangePercentage()
    })
    useEffect(()=>{
        if(subData) checkOiChangePercentage()
    })

    useEffect(() => {
        if(subData) calPercentage(Number(subData.volume),max)
    })

    useEffect(() => {
        if(subData) calIoPercentage(Number(subData.oi),maxIo)
    })


    useEffect(() => {
        ceService.resetVolumeCE();
        ceIoService.resetIoCE()
    }, [data])


    function callSub() {
        const newGuid = `pe-${data.option?.token}-${ExchangeSegmentMap[data.exchange as string]}`;
        
        if (guideRef.current) {
            DataFeedInstance?.unsubscribeQuotes(guideRef.current);
        }
        setGuide(newGuid)
        DataFeedInstance?.subcscribeQuotesFromTokens([{market_segment_id: ExchangeSegmentMap[data.exchange as string] , token: Number(data.option?.token) }],(quotes:Quotes[])=>{
            // console.log(quotes[0].n)
            setBuySellSymbol(quotes[0].n)
            setSubData(quotes[0].v)
        },newGuid)
    }

    let subscription:Subscription | undefined = undefined;
    let subscriptionCE:Subscription | undefined = undefined;
    let subscriptionIoCE:Subscription | undefined = undefined;

    useEffect(() => {

        subscriptionCE = ceService.getSub().subscribe(value => {
            setMax(value)
        });
        subscriptionIoCE = ceIoService.getSubIo().subscribe(value => {
            setMaxIo(value)
        });

        return () => {
            subscription?.unsubscribe();
            subscriptionCE?.unsubscribe();
            subscriptionIoCE?.unsubscribe();
        }
    }, [])

    function changeOiData(){
        let data = parseFloat(subData.oi_ch)
        if(data>=0){
            setChangeOi(subData.oi-(subData.oi/(100+data))*100)
        }else{ 
            setChangeOi((subData.oi/(100+data))*100-subData.oi)
        }
    }

    function checkLtpChangePercentage(){
        let sign = Math.sign(subData.chp)
        if(sign===1){
            setLtpChangePercent("(+"+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-1){
            setLtpChangePercent("("+subData.chp.toFixed(2)+"%)")
            setLtpChangeClass("redText1")
        }else if(sign===0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }else if(sign===-0){
            setLtpChangePercent("(0%)")
            setLtpChangeClass("redText1")
        }else{
            setLtpChangePercent("(0%)")
            setLtpChangeClass("greenText1")
        }
    }

    function checkOiChangePercentage(){
        let sign = Math.sign(parseFloat(subData.oi_ch))
        if(sign===1){
            setOiChangePercent("(+"+parseFloat(subData.oi_ch).toFixed(2)+"%)")
            setOiChangeClass("greenText1")
        }else if(sign===-1){
            setOiChangePercent("("+parseFloat(subData.oi_ch).toFixed(2)+"%)")
            setOiChangeClass("redText1")
        }else if(sign===0){
            setOiChangePercent("(0%)")
            setOiChangeClass("greenText1")
        }else if(sign===-0){
            setOiChangePercent("(0%)")
            setOiChangeClass("redText1")
        }else{
            setOiChangePercent("(0%)")
            setOiChangeClass("greenText1")
        }
    }

    function onChange (isVisible:boolean) {
        if(isVisible){
            callSub()
        }else{
            if(guideRef.current) removeSub() 
        }
      }
    const OpenBuyWindow = () => {
           let data = { ...subData ,buySellSymbol,"type":"B","option_type":"CE"};
           BuySellServices.setBuySell(data)
       }
    
        const OpenSellWindow = () => {
            let data = { ...subData ,buySellSymbol,"type":"S","option_type":"CE"};
            BuySellServices.setBuySell(data)
        }
    
        const OpenChartWindow = () => {
            let data = { ...subData ,buySellSymbol,"type":"","option_type":""};
            layoutSubServices.setLayOut(data)
        }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(String(guideRef.current))
        setSubData(null)
    }

    return(
        <VisibilitySensor onChange={onChange} partialVisibility={true}>
    <td id="head_border_bsc">
        <div id="buy_sell_call">
                <div className="bsc_buttons">
                    <BuySellButton buy={OpenBuyWindow} sell={OpenSellWindow} chart={OpenChartWindow}/>
                </div>
        </div>
        <Row className='flex-nowrap p-0'>
            <Col className={`${vega} chainValue optionChainHeader p-0`} name="vega" id="vega">
                <div className="callChainValue">{data.greeks?.vega}</div>
            </Col>
            <Col className={`${theta}  chainValue optionChainHeader p-0`} name="theta" id="theta">
                <div className="callChainValue">{data.greeks?.theta}</div>        
            </Col>
            <Col className={`${gamma}  chainValue optionChainHeader p-0`} name="gamma" id="gamma">
                <div className="callChainValue">{data.greeks?.gamma}</div>
            </Col>
            <Col className={`${delta}  chainValue optionChainHeader p-0`} name="delta" id="delta">
                <div className="callChainValue">{data.option?.delta}</div>
            </Col>
            <Col  className="optionChainHeader p-0">
                <div style={{"position":"relative"}}>
                     <div className="percentageBar callBar" style={{"width":barIoData}}></div>
                     <div className={`chainValue callChainValue`}>{numDifferentiation(subData?subData.oi:'0')}</div>
                </div>    
            </Col>
            <Col  className="optionChainHeader p-0" style={{"width":"140px"}}>
                <div className={`chainValue callChainValue`}>{changeOi?numDifferentiation(changeOi.toFixed(2)):'0'} <span className={`${oiChangeClass}`} style={{"fontSize":"10px"}}>{oiChangePercent}</span></div>
            </Col>
            <Col  className="optionChainHeader p-0">
                <div style={{"position":"relative"}}>
                    <div className="percentageBar callBar" style={{"width":barData}}></div>
                    <div className={`chainValue callChainValue`}>{numDifferentiation(subData?subData.volume:'0') }</div>
                </div>
            </Col>
            <Col  className="optionChainHeader " style={{"width":"140px"}}>
                <div className={`chainValue callChainValue`} style={{"paddingLeft":"0px"}}>{subData?subData.lp:'0'} <span className={`${ltpChangeClass}`} style={{"fontSize":"10px"}}>{ltpChangePercent}</span></div>
            </Col>
        </Row>
    </td>
    </VisibilitySensor>
    )
}

export default CallData