import './alertcontainer.scss'

type AlertContainerProps = {
  children: React.ReactNode;
};  

const AlertContainer: React.FC<AlertContainerProps> = ({ children }) => {
return (
  <div className="overflow-auto" style={{ height: '388px' }}>
    {children}
  </div>
);
};

export default AlertContainer;