import React, { useEffect, useState } from "react";
import { ReactComponent as CloserIcon } from "../../assets/images/close-square2.svg";
import {OptionChainPreferences} from '../../models/optionChain'

interface AddFilterProps {
  preferences: OptionChainPreferences | null;
  filterFunction: (aboveAtmCount: number, belowAtmCount: number, filterHit: string) => void;
  AboveATMCount: number | null;
  BelowATMCount: number | null;
  updatePref: (showVega: boolean, showDelta: boolean, showTheta: boolean, showGamma: boolean) => void;
  reset: () => void;
  vegaFunction: (value: boolean) => void;
  thetaFunction: (value: boolean) => void;
  gammaFunction: (value: boolean) => void;
  deltaFunction: (value: boolean) => void;
}

const AddFilter: React.FC<AddFilterProps> = ({preferences,filterFunction,AboveATMCount,BelowATMCount,updatePref,reset,vegaFunction,thetaFunction,gammaFunction,deltaFunction,
}) => {
  const [showVega, setShowVega] = useState(false);
  const [showTheta, setShowTheta] = useState(false);
  const [showGamma, setShowGamma] = useState(false);
  const [showDelta, setShowDelta] = useState(false);
  const [vegaClass, setVegaClass] = useState<string | null>(null);
  const [thetaClass, setThetaClass] = useState<string | null>(null);
  const [gammaClass, setGammaClass] = useState<string | null>(null);
  const [deltaClass, setDeltaClass] = useState<string | null>(null);
  const [darkClass, setDarkClass] = useState('light');

  useEffect(() => {
    if (preferences) ApplyFilters();
  }, [preferences]);

  const ApplyFilters = () => {
    if (preferences?.greeks.vega) {
      setVegaClass('vega_active_button');
      setShowVega(true);
    }
    if (preferences?.greeks.theta) {
      setThetaClass('theta_active_button');
      setShowTheta(true);
    }
    if (preferences?.greeks.gamma) {
      setGammaClass('gamma_active_button');
      setShowGamma(true);
    }
    if (preferences?.greeks.delta) {
      setDeltaClass('delta_active_button');
      setShowDelta(true);
    }
  };

  function scrollIntoView() {
    let elementId = document.getElementById("MAINTABLE");
    elementId?.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'start' });
    setTimeout(() => {
      let targetRow = document.getElementById("spot_price_live");
      if (targetRow) {
        targetRow.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'center' });
      }
    }, 1000);
  }

  function showHideVega() {
    if (vegaClass !== 'vega_active_button') {
      setVegaClass('vega_active_button');
      vegaFunction(true);
      scrollIntoView();
    } else {
      setVegaClass('');
      vegaFunction(false);
    }
    setShowVega(!showVega);
  }

  function showHideTheta() {
    if (thetaClass !== 'theta_active_button') {
      setThetaClass('theta_active_button');
      thetaFunction(true);
      scrollIntoView();
    } else {
      setThetaClass('');
      thetaFunction(false);
    }
    setShowTheta(!showTheta);
  }

  function showHideGamma() {
    if (gammaClass !== 'gamma_active_button') {
      setGammaClass('gamma_active_button');
      gammaFunction(true);
      scrollIntoView();
    } else {
      setGammaClass('');
      gammaFunction(false);
    }
    setShowGamma(!showGamma);
  }

  function showHideDelta() {
    if (deltaClass !== 'delta_active_button') {
      setDeltaClass('delta_active_button');
      deltaFunction(true);
      scrollIntoView();
    } else {
      setDeltaClass('');
      deltaFunction(false);
    }
    setShowDelta(!showDelta);
  }

  function resetFilters() {
    if (preferences?.greeks.vega) {
      setVegaClass('vega_active_button');
      setShowVega(true);
      vegaFunction(true);
    } else {
      setVegaClass('');
      setShowVega(false);
      vegaFunction(false);
    }
    if (preferences?.greeks.theta) {
      setThetaClass('theta_active_button');
      setShowTheta(true);
      thetaFunction(true);
    } else {
      setThetaClass('');
      setShowTheta(false);
      thetaFunction(false);
    }
    if (preferences?.greeks.gamma) {
      setGammaClass('gamma_active_button');
      setShowGamma(true);
      gammaFunction(true);
    } else {
      setGammaClass('');
      setShowGamma(false);
      gammaFunction(false);
    }
    if (preferences?.greeks.delta) {
      setDeltaClass('delta_active_button');
      setShowDelta(true);
      deltaFunction(true);
    } else {
      setDeltaClass('');
      setShowDelta(false);
      deltaFunction(false);
    }
    reset();
  }

  return (
    <div>
      <div className={`filter_header pt-5`}>
        <div className="container">
          <div className="row">
            <div className={`col-6 filter_name`}>
              Filters
            </div>
            <div className={`col-3 reset pt-1`}>
              <a onClick={resetFilters}>Reset</a>
            </div>
            <div className="col-3">
              <span onClick={() => updatePref(showVega, showDelta, showTheta, showGamma)} className="c-pointer">
                <CloserIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="filter_body">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className={`greeks_name mt-2`}>
                Greeks
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-6 pr-1">
              <div className={`greeks_button ${darkClass} ${deltaClass}`} id="delta_button" onClick={showHideDelta}>Delta</div>
            </div>
            <div className="col-6 pl-1">
              <div className={`greeks_button ${darkClass} ${gammaClass}`} id="gamma_button" onClick={showHideGamma}>Gamma</div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6 pr-1">
              <div className={`greeks_button ${darkClass} ${thetaClass}`} id="theta_button" onClick={showHideTheta}>Theta</div>
            </div>
            <div className="col-6 pl-1">
              <div className={`greeks_button ${darkClass} ${vegaClass}`} id="vega_button" onClick={showHideVega}>Vega</div>
            </div>
          </div>
          <div className="row mt-6">
            <div className="col-12">
              <div className={`strikesAtm`}>Strikes Above ATM</div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4 pr-1">
              <div className="Atm_Values" id="A10" onClick={() => filterFunction(10, Math.abs(BelowATMCount as number), "filter hit")}>10</div>
            </div>
            <div className="col-4 pr-1 pl-1">
              <div className="Atm_Values" id="A20" onClick={() => filterFunction(20, Math.abs(BelowATMCount as number), "filter hit")}>20</div>
            </div>
            <div className="col-4 pl-1">
              <div className="Atm_Values" id="A30" onClick={() => filterFunction(30, Math.abs(BelowATMCount as number), "filter hit")}>30</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className={`strikesAtm`}>Strikes Below ATM</div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4 pr-1">
              <div className="Atm_Values" id="B10" onClick={() => filterFunction(Math.abs(AboveATMCount as number), 10, "filter hit")}>10</div>
            </div>
            <div className="col-4 pr-1 pl-1">
              <div className="Atm_Values" id="B20" onClick={() => filterFunction(Math.abs(AboveATMCount as number), 20, "filter hit")}>20</div>
            </div>
            <div className="col-4 pl-1">
              <div className="Atm_Values" id="B30" onClick={() => filterFunction(Math.abs(AboveATMCount as number), 30, "filter hit")}>30</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFilter;
