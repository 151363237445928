import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import Buy from "../../../assets/icons/Buy.svg"
import Sell from "../../../assets/icons/Sell.svg"
import moment from 'moment';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {SpotService} from "../../../sub";
import {NumberColor} from "../../common/number_color";
import {formatdateDDMM} from "../../../comman/status"
import {Quotes} from "../../../models/symbolData"
import Subscribed from "../../../models/symbolData"

interface AnalyseProps{
    options:any;
    currentindex:any;
}

const BasketAnalyseOptions:React.FC<AnalyseProps> = ({options,currentindex}) =>{

    const [subData, setSubData ] = useState<Subscribed | null>(null);

    useEffect(() => {
        if(subData) sendMessage();
    })



    function sendMessage() {
        SpotService.sendSpot(subData as Subscribed);
    }
    

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [options])

    let listenerGuid:string | undefined = undefined
    function headerData() {
        listenerGuid = `a-${currentindex}-${options.token}-2`
        DataFeedInstance?.subcscribeQuotesFromTokens([
            {market_segment_id: 2 , token: options.token }],(quotes:Quotes[])=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(listenerGuid)
    }



    if(subData){
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}} className="mb-2">
                <div style={{"position":"relative"}} className="px-1"><img height={"17px"} width={"17px"} src={options.action=="BUY"?Buy:Sell}/></div>
                <div style={{"alignSelf": "center","textAlign":"center","height":"100%","width":"60px"}} className=" sPrice">{(options.strike_price)}</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">{options.option_type}</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">{formatdateDDMM(options.expiry_date)}</div>
                <div style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className="ltp_change">{subData?subData.lp.toFixed(2):0}</div>
                <div style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"right"}} className="ltp_change"><NumberColor number={subData?subData.chp:0} returnSign={true}/>&nbsp;<NumberColor number={subData.chp} numFix={true} percentageAdd={true} isFiniteCheck={true} returnBracket={true}/></div>
            </div>
        )
    }else{
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}} className="mb-2">
                <div style={{"position":"relative","top":"2px"}} className="px-1"><img height={"17px"} width={"17px"} src={Buy}/></div>
                <div style={{"alignSelf": "center","textAlign":"center","height":"100%","width":"60px"}} className=" sPrice">80000</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">CE</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">25 Mar</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"center"}} className="ltp_change">24.5</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change">45.6</div>
            </div>
        )
    }
}

export default BasketAnalyseOptions;