import axios,{ AxiosRequestConfig } from 'axios';
import {API_KEY, API_URL, X_API_KEY} from "../utils/config";
import { remoteConfigGetter } from '../utils/remoteConfig';

var config:AxiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': String(process.env.REACT_APP_VORTEX_KEY),
    "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
    "X-Stage": String(process.env.REACT_APP_ENV)
  },
}
export default axios.create(config);
