import React from "react";
import { AssetClasses, OptionTimeframe, SecurityType, TradeDirection, TriggerMode } from "../gttTypes";
import CreateGTT from "./CreateGTT";

// Type definitions for the props
export type GTTBodyProps = {
  setConditionalWidth: ()=>void;
  CloseGttModal: () => void;
  token: string;
  exchnage: string;
  assetClass: AssetClasses;
  securityType: SecurityType;
  tradeDirection: TradeDirection;
  ltp: string; // Actullay a number
  currentGTTTab: OptionTimeframe;
  tick:number,
  lotSize: number
  isCondtionalWidthNeeded:boolean
  securityDesc:string
};

const GttBody: React.FC<GTTBodyProps> = ({ assetClass, securityType,tradeDirection:buyOrSell,ltp ,token,exchnage,currentGTTTab,lotSize,tick,setConditionalWidth,isCondtionalWidthNeeded,CloseGttModal,securityDesc}) => {
  // Get the correct component from the mapper based on assetClass and securityType
  // const Component = gttComponentMapper[assetClass]?.[securityType];
  const Component = CreateGTT;
  // console.log("assetClass>>>",assetClass,securityType)

  return (
    <div>
      {Component ? (
        <Component securityDesc={securityDesc} CloseGttModal={CloseGttModal} isCondtionalWidthNeeded={isCondtionalWidthNeeded} setConditionalWidth={setConditionalWidth} lotSize={lotSize} tick={tick} currentGTTTab={currentGTTTab} exchnage={exchnage} token={token} assetClass={assetClass} securityType={securityType} tradeDirection={buyOrSell} ltp={ltp} />
      ) : (
        <div>Invalid asset class or security type</div>
      )}
    </div>
  );
};

export default GttBody;
