import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './eachstock.css'
import MenuIcon from "../../../assets/icons/menu.svg";
import {ReactComponent as MenuIconRect} from "../../../assets/icons/menu.svg";
import executeIcon from "../../../assets/icons/send-2.svg";
import ModifyIcon from "../../../assets/icons/setting-3.svg";
import {ReactComponent as ModifyIconRect} from "../../../assets/icons/setting-3.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import {ReactComponent as DeleteIconRect} from "../../../assets/icons/trash.svg";
import AdditemIcon from "../../../assets/icons/additem.svg";
import {ReactComponent as AdditemIconRect} from "../../../assets/icons/additem.svg";

import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {useDispatch, useSelector} from "react-redux";
import {setBasketDetails, setCurrentModification} from "../../../slices/basketOrderSlice";
import flowBackEndService from "../../../services/flowBackendApi";
import {toast} from "react-toastify";
import {basketSubServices} from "../../../sub";
import {currentUser} from "../../../slices/authSlice";
import {BrokerApi} from "../../../chart-brokerapi/broker";
import Spinner from "react-bootstrap/Spinner";
import {OrderOMSFeedSub} from "../../../sub";
import {logMessage} from "../../../utils/helpers";
import { Subscription } from 'rxjs';
import { Quotes,TokenSymbol,Source } from "../../../models/symbolData"
import {AllBaskets, BasketOrder} from "../../../models/basketOrders"
import { AllOrders } from "../../../models/orders"
import Subscribed from "../../../models/symbolData"

export const productTypeModel:Record <string, string> = {'1': 'Intraday','2':'PayLater','3':'Delivery'};
export const OrderTypeModel:Record <string, string> = {'1': 'RL','2':'RL-MKT','3':'SL','4':'SL-MKT'};
export const ValidtyTypeModel:Record <string, string> = {'1': 'DAY','2': 'IOC','3': 'GTT'}

interface EachStockProps {
    data: BasketOrder; // Replace 'any' with the specific type for `data`
    index: number;
    masterOrder: AllOrders[]; // Replace 'any' with the specific type for `masterOrder`
    isExecuted: boolean;
    setSingleExcData: () => void; // Replace 'any' with the specific data type for `setSingleExcData`
  }

  interface CurrentData {
    symbol: string;
    type: string | undefined;
    _source: Source
    currentData: BasketOrder;
}
const EachStock = forwardRef(({data,index,masterOrder,isExecuted,setSingleExcData}:EachStockProps, ref) => {


    const dispatch = useDispatch();

    const [ subData, setSubData ] = useState<Subscribed | null>(null);

    const {basketDetails} = useSelector((state:any) => state.basketOrder);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(currentUser);


    const [currentData, setCurrentData] = useState<CurrentData | null>(null);

    function closeLodding2(){
        setIsLoading(false)
    }

    useImperativeHandle(ref, () => ({

        closeLodding() {
            closeLodding2()
        }

    }));


    const OrderStatus:Record <string, string> = {'1': 'Canceled', '2': 'Filled', '3': 'Inactive', '4': 'Placing', '5': 'Rejected', '6': 'Working'}


    useEffect(() => {
        listenSingleOrdereExcecution()
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    let subscription:Subscription 
    function listenSingleOrdereExcecution(){
        subscription = OrderOMSFeedSub.OrderListener().subscribe((values:any) => {
            if(values.response && values.response.error){
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        // console.log('subData',index,subData)
    }, [subData])

    useEffect(() => {
         // console.log('masterOrder',masterOrder)
    }, [masterOrder])


    useEffect(() => {
      if(currentData) dispatch(setCurrentModification(currentData))
    }, [currentData])


    useEffect(() => {
         headerData()
        return () => {
            removeSub()
            // console.log('UnMount EachStock')
        }
    }, [data])

    function removeFromArray<T>(array: T[], from: number, to?: number): T[] {
        const rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        array.push(...rest);
        return array;
      }

    const deleteOrder = () => {
      let BasketOrders = [...basketDetails.basketOrders]
      BasketOrders=removeFromArray(BasketOrders,index)
        //   BasketOrders.remove(index)
        let newData = { ...basketDetails,basketOrders: BasketOrders}
         dispatch(setBasketDetails(newData))
        setIsLoading(true)
        const datas = {basketName: newData.basketName,orders:newData.basketOrders}
        flowBackEndService.UpdateBasket(datas,String(user?.access_token),newData.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    // toast.success(`Basket order delete ${value.data.message}`);
                    basketSubServices.setNewBasket(newData)
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function getJsonOrder(){
        let final:BasketOrder= {
            id:0,
            orderPosition:0,
            token: data.token,
            marketSegmentId: data.marketSegmentId,
            buySell: data.buySell,
            productType: data.productType,
            orderType: data.orderType,
            quantity: data.quantity,
            price:  data.price,
            disclosedQuantity: data.disclosedQuantity,
            triggerPrice: data.triggerPrice,
            validity: data.validity,
            isAmo: data.isAmo,
            validityDays:0,
            orderIdentifier:"",
            orderId:"",
            createdAt:"",
            updatedAt:"",
            tag_ids:[]
        };
        return final;
    }

    const duplicateOrder = () => {
        let BasketOrders:BasketOrder[] = [...basketDetails.basketOrders,getJsonOrder()]
        let newData = { ...basketDetails,basketOrders: BasketOrders}
        setIsLoading(true)
        const datas = {basketName: newData.basketName,orders:newData.basketOrders}
        flowBackEndService.UpdateBasket(datas,String(user?.access_token),newData.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    // toast.success(`Basket order Duplicate ${value.data.message}`);
                    basketSubServices.setNewBasket(newData)
                    dispatch(setBasketDetails(newData))
                }else{
                    toast.error(`Error update ${value?.data?.message}`);
                }
            })
            .catch(error => {
                toast.error(`${error.response.data.message}`)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    let listenerGuid:string | undefined = undefined
    function headerData() {
        listenerGuid = `top-${data.token}-${data.marketSegmentId}-${index}`
        DataFeedInstance?.subcscribeQuotesFromTokens([
            {market_segment_id: data.marketSegmentId , token: data.token }],(quotes:Quotes[])=>{
              setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(listenerGuid)
    }




   function setupStockData(values:TokenSymbol){
        let datanew:CurrentData = { symbol: values.symbol,type: data?.stockData?.series,
            _source: {nToken: Number(subData?.token),MktSegId: Number(subData?.market_segment_id)},
            currentData: data
            }
       setCurrentData(datanew)
    }

    function modifyOrder() {
        DataFeedInstance?.tokenInfo({market_segment_id: data.marketSegmentId , token: data.token }).then((values:TokenSymbol) => {
            setupStockData(values)
        })
    }

    function executeOrderSingle() {
        setIsLoading(true)
        DataFeedInstance?.tokenInfo({market_segment_id: data.marketSegmentId , token: data.token }).then((values:TokenSymbol) => {
            var listenerId =  data.id;
            setSingleExcData()
            placeOrederSingle(values,listenerId)
        })
    }

   function placeOrederSingle(values:TokenSymbol,listenerId:number){
    BrokerApi.instance?.placeOrder({
           symbol: values.symbol,
           side: data.buySell,
           margin: Number(data.productType),
           qty: data.quantity,
           limitPrice: data.price,
           triggerPrice: data.triggerPrice,
           order_type: data.orderType,
           validity: Number(data.validity),
           is_amo: data.isAmo,
           origin:'single_order_basket'
       },String(listenerId)).then(()=>{
           window.GtmPusher({
               event: 'tv-order-placed-basket'
           })
       })
   }


    function getOrderStatus() {
        if(data.orderId === '' && isExecuted){
            return  (
                <div  className="d-flex flex-row justify-content-start align-items-center">
                  {/*<div className="mr-1"><img src={executeIcon} alt={''}/></div>*/}
                    {isLoading ? (
                        <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    ) : (<div onClick={executeOrderSingle} className="excecute_btn c-pointer">Execute &gt;</div>)}

                </div>
            )
        }

        if(data.orderId.length >  2){
         let orderSingle:AllOrders | undefined =  masterOrder.find((d:AllOrders) => d.order_id === data.orderId)
            if(orderSingle){
               let name = OrderStatus[orderSingle?.status?.toString()];
                return (
                    <div className={`back_side ${name}`}>{name}</div>
                )
            }
        }

    }

    return (

            <tr className="each_box card_bg">
                <td><div className="mr-2 grab">
                    <MenuIconRect/>
                    {/*<img alt={''} className="grab" src={MenuIcon}/> */}
                </div></td>
                <td className="menuBox position-relative">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                        <div className="d-flex flex-row">
                            <div className="type_badge mr-2">{productTypeModel[data.productType.toString()]}</div>
                            <div className="type_badge mr-2">{OrderTypeModel[data.orderType.toString()]}</div>
                            { data.isAmo && (
                                <div className="type_badge">AMO</div>
                            )}
                        </div>
                        <div className="d-flex flex-row mt-1">
                            {data.buySell === 1 ? <div className="buysell_badeg color_buy mr-1">B</div> : <div className="buysell_badeg color_sell mr-3">S</div> }
                            <div className="text_head1">{subData?.short_name}</div>
                        </div>
                    </div>
                    <div className="drover_box">
                       <div className="d-flex h-100 flex-row justify-content-start align-items-center">
                           <div className="w-25">
                               <div onClick={deleteOrder} className="tooltipMenu">
                                   {/*<img src={DeleteIcon} alt={''}/>*/}
                                   <DeleteIconRect/>
                                   <div className="top">
                                       <div>Delete</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>
                           <div className="w-25">
                               <div onClick={modifyOrder} className="tooltipMenu">
                                   {/*<img src={ModifyIcon} alt={''}/>*/}
                                   <ModifyIconRect/>
                                   <div className="top">
                                       <div>Modify</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>
                           <div className="w-25">
                               <div onClick={duplicateOrder} className="tooltipMenu">
                                   {/*<img src={AdditemIcon} alt={''}/>*/}
                                   <AdditemIconRect/>
                                   <div className="top">
                                       <div>Duplicate</div>
                                       <i></i>
                                   </div>
                               </div>
                           </div>

                       </div>
                    </div>
                </td>
                <td className="priceTag">{subData?.lp}</td>
                <td className="priceTag">{data.price} </td>
                <td className="priceTag">{data.quantity}</td>
                <td>{getOrderStatus()}</td>
            </tr>

    );
});

export default EachStock;
