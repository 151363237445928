import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
// import { RootState } from "../get/getAlerts";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";
import { RootState } from "../../../store";

export interface UpdateOrderPayload {
  id: number;
  price: number;
  trigger_price: number;
  quantity: number;
  variety: string;
}

interface UpdateOrderResponse {
  status: "success" | "failure";
  message: string;
  data?: any;
}

const BASE_API_URL = `${FLOW_BACKEND_BASE_URL}trading/orders/gtt`;

const useUpdateGttOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<UpdateOrderResponse | null>(null);

  const token = useSelector(
    (state: RootState) => state.authUser.user?.access_token
  );

  const selectedGttId = useSelector(
    (state: RootState) => state.gttAlerts.isOpenModal
  );

  const mutate = useCallback(
    async (
      payload: UpdateOrderPayload[],
      options?: {
        onSuccess?: (responseData: UpdateOrderResponse) => void;
        onError?: (error: string) => void;
      }
    ) => {
      setIsLoading(true);
      setIsError(false);
      setError(null);

      try {
        // Assuming the first payload item determines the ID for the URL
        const url = `${BASE_API_URL}/${selectedGttId}`;

        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload), // Send the payload array
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Something went wrong");
        }

        const responseData = (await response.json()) as UpdateOrderResponse;
        setData(responseData);

        options?.onSuccess?.(responseData);

        return responseData;
      } catch (err: any) {
        const errorMessage = err.message || "Unknown error occurred";
        setIsError(true);
        setError(errorMessage);

        options?.onError?.(errorMessage);

        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [token]
  );

  return { mutate, isLoading, isError, error, data };
};

export default useUpdateGttOrder;
