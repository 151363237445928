import React,{ReactNode} from 'react';
import cartIcon from "../../../assets/icons/shopping-cart.svg";
import {ReactComponent as CloserIcon} from "../../../assets/images/close-square2.svg";

import {useDispatch} from "react-redux";
import {BasketModelToggle,OpenPublicBasket} from "../../../slices/basketOrderSlice";
interface BasketketHeaderProps {
    children: ReactNode;
  }
const BasketketHeader:React.FC<BasketketHeaderProps>=(props)=> {

    const dispatch = useDispatch();


    const onModelClose = () => {
        sessionStorage.removeItem("public_basket_id")
        dispatch(BasketModelToggle(false))
        dispatch(OpenPublicBasket(false))
    }

    return (
        <div className="d-flex flex-row w-100 handle_b">
            <div className="custom-order-dialog__header_basket currentBg2" id="custom-order-dialog__header-id" data-dragg-area="true">
                <div className="custom-order-dialog__stock-info" id="custom-order-dialog__quote-stock-info-id">
                    {props.children}
                </div>
                <div className="custom-order-dialog__actions">
                    <div className="field custom-order-dialog__side-switch-row">
                    </div>
                    <div className="custom-order-dialog__dialog-actions" id="custom-order-dialog__quote-dialog-actions-id">
                        <div onClick={onModelClose} className="c-pointer" id=""><CloserIcon/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasketketHeader;
