import React from "react";
import { ReactComponent as Edit } from "../../assets/icons/edit-2.svg";
import { ReactComponent as Delete } from "../../assets/icons/trash.svg";
import { ReactComponent as Recreate } from "../../assets/icons/arrow.svg";

export enum IconType {
  Edit = "edit",
  Delete = "delete",
  ReCreate = "recreate",
}

const iconMapping: { [key in IconType]: React.ElementType } = {
  [IconType.Edit]: Edit,
  [IconType.Delete]: Delete,
  [IconType.ReCreate]: Recreate,
};

interface CustomAlertButtonProps {
  iconType: IconType; // Prop to select the icon type
  onClick: () => void; // Click handler
  isDisabled?: boolean; // Whether the button is disabled
}

const CustomAlertButton: React.FC<CustomAlertButtonProps> = ({
  iconType,
  onClick,
  isDisabled = false, // Default to not disabled
}) => {
  const IconComponent = iconMapping[iconType];

  return (
    <button
      onClick={!isDisabled ? onClick : undefined} // Disable click when `isDisabled` is true
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor when disabled
        opacity: isDisabled ? 0.7 : 1, // Adjust opacity when disabled
      }}
      aria-label={iconType} // Accessibility label
      disabled={isDisabled} // Native disabled attribute
    >
      <IconComponent style={{ height: 16, width: 16 }} />
    </button>
  );
};

export default CustomAlertButton;
