import React, { useState, useEffect } from 'react';

interface TimerProps {
  initialMinute: number;
  initialSeconds: number;
  showResend: () => void;
}

const Timer: React.FC<TimerProps> = ({ initialMinute, initialSeconds, showResend }) => {
  const [minutes, setMinutes] = useState<number>(initialMinute);
  const [seconds, setSeconds] = useState<number>(initialSeconds);

  let myInterval: number | undefined;

  useEffect(() => {
    // This effect could be used for setting up anything else if needed, but it's not used here.
  }, []);

  useEffect(() => {
    timeset();
  }, [seconds]);

  function timeset() {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 0) {
      if (minutes === 0) {
        showResend();
        clearInterval(myInterval);  // This line can be safely called only if `myInterval` is a valid interval.
      } else {
        setMinutes((minutes - 1));
        setSeconds(59);
      }
    }
  }

  return (
    <div>
      {minutes === 0 && seconds === 0
        ? null
        : <h6 className="ml-1">
            <i style={{ color: "#ff9933" }} className="fa-solid fa-stopwatch" />  
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </h6>
      }
    </div>
  );
}

export default Timer;
