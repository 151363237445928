import type { AlertData } from "../../hooks/api/get/getAlerts";
import type { AlertsType } from "../../components/Alerts/AlertTools";

export const arraySelector = (status: AlertsType, activeAlerts: AlertData[], inactiveAlerts: AlertData[]) => {
  switch (status) {
    case 'all':
      return [...activeAlerts, ...inactiveAlerts];
    case 'active':
      return activeAlerts;
    case 'inactive':
      return inactiveAlerts;
    default:
      return [];
  }
};