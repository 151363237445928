import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ReactComponent as DropDownArrow } from "../../../assets/icons/dropdownArrow.svg";
import './properydropdwon.scss'

interface PropertyDropdownProps {
  label: string;
  value: string;
  propertyOptions: { value: string; label: string }[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean; // Made optional
}

const PropertyDropdown: React.FC<PropertyDropdownProps> = ({
  label,
  value,
  propertyOptions,
  onChange,
  disabled = false, // Default value
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className="property-dropdown position-relative">
      <Form.Label
        htmlFor="property"
        className="text-start w-100 text-color font-16 mb-1 mt-4"
      >
        <strong className="text-color">{label}</strong>
      </Form.Label>

      <InputGroup>
        <div className="position-relative w-100">
          <Form.Select
            id="property"
            name="property"
            disabled={disabled}
            aria-label="Select an option"
            aria-expanded={isOpen} // Accessibility improvement
            className={`create-or-edit-dropdown pe-4 ${
              disabled ? "cursor-not-allowed alerts-disabled" : ""
            }`}
            value={value}
            onChange={onChange}
            onClick={toggleDropdown}
          >
            {propertyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>

          <DropDownArrow
            className="position-absolute top-50 end-0 translate-middle-y me-2 pointer-events-none text-color"
            style={{
              transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
              transition: "transform 0.3s ease",
            }}
          />
        </div>
      </InputGroup>
    </div>
  );
};

export default PropertyDropdown;
