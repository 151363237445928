import React, { useEffect, useState } from "react";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import { setListDataMain } from "../../slices/basketOrderSlice";
import LoadingSpinner from "./LoadingSpinner";
import {MarketDepth, Bids} from "../../models/future"

interface MarketDepthProps{
    symbol: string;
}

const MarketDepthData:React.FC<MarketDepthProps> = ({symbol})=> {

    const [askSubData,setAskSubData]=useState<Bids[] | null>(null)
    const [bidSubData,setBidSubData]=useState<Bids[] | null>(null)
    const [depth,setDepth]=useState<MarketDepth | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    useEffect(()=>{
        subMarketDepth()

        return()=>{
            removeMarketDepth()
        }
    },[symbol])

    let subscriberId:string | undefined = undefined

    function subMarketDepth(){
        setIsLoading(true)
        subscriberId = DataFeedInstance?.subscribeDepth(symbol,(depth:MarketDepth)=>{
            setDepth(depth)
            setAskSubData(depth.asks as Bids[])
            setBidSubData(depth.bids?.reverse() as Bids[])
        })
        setIsLoading(false)
    }

    function removeMarketDepth(){
        DataFeedInstance?.unsubscribeDepth(subscriberId)
    }
    function getBidsData(){

        return bidSubData?.map((key:Bids,value1:number)=>{
            
            return(
                <div className="row" key={value1}>
                    <div className="col-4 alignMktDepth greenText1">{key?key.orders:'0'}</div>
                    <div className="col-4 alignMktDepth greenText1">{key?key.volume:'0'}</div>
                    <div className="col-4 alignMktDepth greenText1">{key?key.price.toFixed(2):'0'}</div>
                </div>
            )
        })
    }

    function getAsksData(){
        return askSubData?.map((key:Bids,value2:number)=>{

            return(
                <div className="row" key={value2}>
                    <div className="col-4 alignMktDepth redText1">{key?key.price.toFixed(2):'0'}</div>
                    <div className="col-4 alignMktDepth redText1">{key?key.volume:'0'}</div>
                    <div className="col-4 alignMktDepth redText1">{key?key.orders:'0'}</div>
                </div>
            )
        })
    }


    return(
        <React.Fragment>
        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginRight":"1%"}}>
            {isLoading?<LoadingSpinner/>:bidSubData && getBidsData()}
            <div className="row totalgreen">
                <div className="col-4 alignMktDepth">Total</div>
                <div className="col-4 alignMktDepth">{depth?.totalBuyQty}</div>
            </div>
        </div>
        <div className="headerBackground" style={{"width":"48%","display":"inline-block","marginLeft":"1%"}}>
        {isLoading?<LoadingSpinner/>:askSubData && getAsksData()}
        <div className="row totalred">
                <div className="col-4 alignMktDepth">Total</div>
                <div className="col-4 alignMktDepth">{depth?.totalSellQty}</div>
            </div>
        </div>
        <div className="row my-4">
            <div className="col-4 alignOHLC">
                <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>P.Close:</div>
                    <div style={{"display":"flex"}}>{depth?.closePrice?.toFixed(2)}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>Open:</div>
                    <div style={{"display":"flex"}}>{depth?.openPrice?.toFixed(2)}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>High:</div>
                    <div style={{"display":"flex"}}>{depth?.highPrice?.toFixed(2)}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>Low:</div>
                    <div style={{"display":"flex"}}>{depth?.lowPrice?.toFixed(2)}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>Volume:</div>
                    <div style={{"display":"flex"}}>{depth?.volume}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>LTP:</div>
                    <div style={{"display":"flex"}}>{depth?.lp?.toFixed(2)}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>LTQ:</div>
                    <div style={{"display":"flex"}}>{depth?.LTQ}</div>
                </div>
            </div>
            <div className="col-4 alignOHLC">
            <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"display":"flex","width":"42%"}}>Change(%):</div>
                    <div style={{"display":"flex"}}>{depth?.ch+"("+depth?.chp?.toFixed(2)+"%)"}</div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default MarketDepthData
