import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GTTOrder } from "../../src/models/gttOrders";
import { RootState } from "../store";

// Define the state type that holds both isOpenModal and GTTOrder[]
interface AlertState {
    isOpenModal: string; // If It Exists, It Will Be Opened || '0' ==> closed modal and any other value mean we need to open modal
    gttOrders: GTTOrder[];
    recreateNewGTTOrderWithId: string; // UUID
}

// Initial state
const initialState: AlertState = {
    isOpenModal: '0',      // modal visibility state
    gttOrders: [],           // array to store GTT orders
    recreateNewGTTOrderWithId: ''
}

// Create the slice
const AlertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        // Toggle the visibility of the modal
        setModal(state, action: PayloadAction<string>) {
            state.isOpenModal = action.payload;
        },
        // Set the UUID for recreating new GTT order
        setRecreateNewGTTOrderWithId(state, action: PayloadAction<string>) {
            state.recreateNewGTTOrderWithId = action.payload;
        },

        // Set the GTT orders (replace the whole array)
        setGttOrders(state, action: PayloadAction<GTTOrder[]>) {
            state.gttOrders = action.payload;
        },

        // Add a new GTT order to the existing array
        addGttOrder(state, action: PayloadAction<GTTOrder>) {
            state.gttOrders.push(action.payload);
        },

        // Update a specific GTT order in the array based on id
        updateGttOrder(state, action: PayloadAction<GTTOrder>) {
            const index = state.gttOrders.findIndex(order => order.id === action.payload.id);
            if (index !== -1) {
                state.gttOrders[index] = action.payload;  // Replace the order at the found index
            }
        },

        // Remove a GTT order based on id
        removeGttOrder(state, action: PayloadAction<string>) {
            state.gttOrders = state.gttOrders.filter(order => order.id !== action.payload);
        },

        // Get All GTT Orders
        getAllGttOrders(state, action: PayloadAction<GTTOrder[]>) {
            state.gttOrders = action.payload;
        },

        
        addIfNotExist(state, action: PayloadAction<GTTOrder>) {
            // Find if the main GTT order exists in our state
            const index = state.gttOrders.findIndex(order => order.id === action.payload.id);

            if (index === -1 && action.payload.orders[0].status !== 'deleted') {
                // Case 1: Main GTT order doesn't exist
                // Add the complete new GTT order to the state
                state.gttOrders.push(action.payload);
                return;
            }

            // Case 2: Main GTT order exists
            // Find if the incoming sub-order exists in the main order
            const existingSubOrderIndex = state.gttOrders[index].orders.findIndex(order => 
                order.id === action.payload.orders[0].id
            );

            if (existingSubOrderIndex === -1) {
                // Case 2.1: Sub-order doesn't exist
            
                // If the new order type is "Sell", it should be at index 1, and the previous order should be moved to index 0, and vice versa.
                const newOrder = action.payload.orders[0];
                const existingOrders = state.gttOrders[index].orders;
            
                if (newOrder.transaction_type === "SELL" && existingOrders.length > 0) {
                    // Place the new "Sell" order at index 1 and rearrange
                    state.gttOrders[index].orders = [existingOrders[0], newOrder];
                } else if (newOrder.transaction_type === "BUY" && existingOrders.length > 0) {
                    // Place the new "Buy" order at index 0 and rearrange
                    state.gttOrders[index].orders = [newOrder, existingOrders[0]];
                } else {
                    // Default case: Push the new order
                    state.gttOrders[index].orders.push(newOrder);
                }
            
                return;
            }
            

            // Case 2.2: Sub-order exists - handle based on status
            const incomingOrder = action.payload.orders[0];

            if (incomingOrder.status === 'deleted') {
                // If order is marked for deletion
                // if (state.gttOrders[index].orders.length <= 2) {
                //     // Remove entire GTT order if it would have less than 2 orders
                //     state.gttOrders = state.gttOrders.filter((_, i) => i !== index);
                // } else {
                //     // Remove only the specific sub-order
                //     state.gttOrders[index].orders = state.gttOrders[index].orders.filter(
                //         order => order.id !== incomingOrder.id
                //     );
                // }
                state.gttOrders[index].orders = state.gttOrders[index].orders.map(order => {
                    if (order.status !== 'deleted') {
                        return { ...order, status: 'deleted' }; // Update the status to 'deleted'
                    }
                    return order; // Keep the order as is if it's already 'deleted'
                });
            } else {
                // Update the existing sub-order with new data
                state.gttOrders[index].orders[existingSubOrderIndex] = {
                    ...state.gttOrders[index].orders[existingSubOrderIndex],
                    ...incomingOrder,
                };

                // If status changed to triggered, update triggered_at
                if (incomingOrder.status === 'triggered') {
                    state.gttOrders[index].orders[existingSubOrderIndex].trigerred_at = 
                        new Date().toISOString();
                }
            }
        },
    }
});

// Export actions for use in components
export const {
    setModal,
    setRecreateNewGTTOrderWithId,
    setGttOrders,
    addGttOrder,
    updateGttOrder,
    removeGttOrder,
    getAllGttOrders,
    addIfNotExist
} = AlertSlice.actions;

// Export the reducer to use in the store
export default AlertSlice.reducer;


export const getGttOrderById = (state: RootState, id: string): GTTOrder | undefined => {
    return state.gttAlerts.gttOrders.find((order) => order.id === id);
};