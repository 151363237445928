

import { FLOW_BACKEND_BASE_URL } from '../utils/config';
import store from '../store';

import type { TokensToSymbolsPayload,TokensToSymbolsMappedResult,TokensToSymbolsApiResponse } from '../models/gttOrders';

export function getDatafeedSubscriptionId(id:string) {
    return `SampleBroker-${id}`;
}


export function _getRealizedPl(position:any){
    var realizedPl = 0.00 ; 
    if(position.sell_quantity == position.buy_quantity){
        realizedPl = (parseFloat(position.sell_price)-parseFloat(position.buy_price) )*position.sell_quantity
    }
    if (position.exchange == 'NSE_CUR'){
        return realizedPl  * parseInt(position.lot_size)
    }else{
        return realizedPl  
    }

}

export function _getRealizedOverallPl(position:any){
    var realizedPl:number = 0.00 ; 
    if(position.sell_quantity == position.buy_quantity){
        realizedPl = (parseFloat(position.sell_price)-parseFloat(position.buy_price) )*position.sell_quantity
    }
    if (position.exchange == 'NSE_CUR'){
        return realizedPl  * parseInt(position.lot_size)
    }else{
        return realizedPl  
    }

}

export function _getUnrealizedPl(position:any,ltp = 0.00,close_price=0.00,open_price=0.00){
    var unrealizedPl = 0.00 ; 

    if (parseFloat(position.value)==0){
        if(position.buy_quantity > position.sell_quantity ){
            unrealizedPl=(ltp-close_price)*position.buy_quantity
        }else if(position.sell_quantity > position.buy_quantity){

            unrealizedPl=(close_price-ltp)*position.sell_quantity
        }else if(position.sell_quantity == position.buy_quantity){
            unrealizedPl = 0
        }
        if (position.exchange == 'NSE_CUR'){
            return unrealizedPl * parseInt(position.lot_size)
        }else{
            return unrealizedPl 
        }
    }else{
        if (position.exchange=="BSE_FO"||position.exchange=="NSE_FO"){
            unrealizedPl=parseFloat(position.value) + ((position.buy_quantity-position.sell_quantity) * ltp)
        }else if (position.exchange=="MCX_FO"){
            unrealizedPl=parseFloat(position.value) + (((position.buy_quantity-position.sell_quantity)*parseInt(position.multiplier)) * ltp)
        }else{
            if (position.product=="MTF"){
                if(position.overnight_average_price!=0){
                    if (position.buy_quantity>position.sell_quantity){
                        unrealizedPl=(ltp - position.buy_price)*((position.buy_quantity-position.sell_quantity)*parseInt(position.lot_size))
                    }else{
                        unrealizedPl=(ltp - position.buy_price)*((position.sell_quantity-position.buy_quantity)*parseInt(position.lot_size))
                    }
                }else{
                    if (close_price!=0){
                        unrealizedPl=(ltp - close_price)*((position.buy_quantity-position.sell_quantity)*parseInt(position.lot_size))
                    }else{
                        unrealizedPl=(ltp - open_price)*((position.buy_quantity-position.sell_quantity)*parseInt(position.lot_size))
                    }
                }
            }else{
                unrealizedPl=parseFloat(position.value) + (((position.buy_quantity-position.sell_quantity)*parseInt(position.lot_size)) * ltp)
            }

        }
        return unrealizedPl
    }
        
}

export function _getUnrealizedOverallPl(position:any,ltp = 0.00){
    var gain = 0.00;
    var percentage = 0.00;
    let isSell = position.sell_quantity > position.buy_quantity;
    let isCF = position.cf_net_price == '0.00' ? false : true;
    if (position.buy_quantity > position.sell_quantity) {
      if (isCF && (position.exchange != 'NSE_EQ' && position.exchange != 'BSE_EQ')) {
        gain = (ltp) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.quantity : position.quantity *position.lot_size) +
            parseFloat(position.overnight_sell_value) -
            parseFloat(position.overnight_buy_value) ;
      } else {
        gain = ((ltp ) - parseFloat(position.buy_price)) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.quantity : position.quantity *position.lot_size) +
            (parseFloat(position.sell_price) - parseFloat(position.buy_price)) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.sell_quantity : position.sell_quantity *position.lot_size);
      }

      percentage = gain /
          (((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.buy_quantity : position.buy_quantity *position.lot_size) *
              parseFloat(position.buy_price));
    } else if (position.sell_quantity > position.buy_quantity) {
      if (isCF && position.exchange != 'NSE_EQ' && position.exchange != 'BSE_EQ') {
        gain = ( - ltp) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? Math.abs(position.quantity) : Math.abs(position.quantity) *position.lot_size) +
            parseFloat(position.overnight_sell_value) -
            parseFloat(position.overnight_buy_value);
      } else {
        gain = (parseFloat(position.sell_price) - (ltp )) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? Math.abs(position.quantity) : Math.abs(position.quantity) *position.lot_size) +
            (parseFloat(position.sell_price) - parseFloat(position.buy_price)) *
                ((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.buy_quantity : position.buy_quantity *position.lot_size);
      }
      percentage = gain /
          (((position.exchange == 'NSE_FO' || position.exchange == 'BSE_FO') ? position.sell_quantity : position.sell_quantity *position.lot_size) *
              parseFloat(position.sell_price));
    } else if (position.quantity == 0) {
        // TODO: what to do here ? 
    //   if (position.exchange == 'NSE_FO') {
    //     gain = parseFloat(position.overnight_sell_value) - parseFloat(position.overnight_buy_value);
    //   } else {
    //     gain = parseFloat(position.net_value);
    //   }
    //   percentage = gain / (parseFloat(isSell ? position.overnight_sell_value : position.overnight_buy_value));
    }
    return gain
}


export function _getOrderStatus(status:string){
    const dict:Record<string,number> = {
        "CLIENTXMITTED" : 6,
        "GATEWAYXMITTED" : 6,
        "OMSXMITTED" : 6,
        "EXCHANGEXMITTED" : 6,
        "PENDING" : 6,
        "CANCELLED" : 1,
        "EXECUTED" : 2,
        "ADMINPENDING" : 6,
        "GATEWAYREJECT" : 5,
        "OMSREJECT" : 5,
        "ORDERERROR" : 5,
        "FROZEN" : 6,
        "MINIADMINPENDING" : 6,
        "ADMINACCEPT" : 6,
        "ADMINREJECT" : 5,
        "ADMINMODIFY" : 6,
        "ADMINCANCEL" : 1,
        "AMOSUBMITTED" : 6,
        "AMOCANCELLED" : 1,
        "COMPLETED" : 2,
        "STOPPED" : 1,
        "CONVERTED" : 6,
        "AMOACCEPTED": 6
    }
    return dict[status]
}

export function _getGttOrderStatus(status:string){
    const dict:Record<string,number> = {
        "active": 6 , 
        "WITHDRAWN":1,
        "COMPLETED": 2,
        "": 5
    }
    return dict[status]
}

export function _exchangeMap(exchange:string){ 
    const dict:Record<string,string> = {
        "NSE_FO": "2", 
        "BSE_FO": "4", 
        "BSE_EQ": "3", 
        "NSE_CUR": "13", 
        "NSE_EQ": "1", 
        "MCX_FO": "5"
    }
    return dict[exchange]
}

export function _marketSegmentId(segment:string){
    const dict:Record<string,string> = {
        "2" : "NSE_FO",
        "13": "NSE_CUR",
        "1": "NSE_EQ",
        "3": "BSE_EQ",
        "4": "BSE_FO",
        "5": "MCX_FO",
    }
    return dict[segment.toString()]
}

export function sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  export function formatNumber(value:number) {
    // Check if the value is a number
    if (typeof value === 'number' && !isNaN(value)) {
        return value.toFixed(2); // Format the number to 2 decimal places
    }
    
    // Check if the value is a string that represents a valid number
    if (typeof value === 'string' && !isNaN(parseFloat(value)) && isFinite(value)) {
        return parseFloat(value).toFixed(2); // Convert and format the string number
    }
    
    // If the value is not a valid number, return "0.00"
    return "0.00";
}

export function chopIntegerIntoArray(originalInt:number, maxValue:number) {
    const result = [];

    while (originalInt > 0) {
        const value = Math.min(originalInt, maxValue);
        result.push(value);
        originalInt -= value;
    }

    return result;
}


interface DeleteOrderResponse {
    message?: string; // Optional, depending on your API response
  }
  
  export const deleteGTTOrder = async (url: string, id: string): Promise<DeleteOrderResponse> => {
    const token = store.getState().authUser?.user?.access_token;
  
    try {
      const response = await fetch(`${url}/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Optional, depending on the API
        },
      });
  
      if (!response.ok) {
        const errorMessage = await response.json(); // Get error details
        throw new Error(`Failed to delete the order: ${errorMessage}`);
      }
  
      // Check if the response body exists before parsing
      if (response.status === 204) {
        // No content to return, simply resolve
        return { message: 'Order deleted successfully' }; // Resolving with a message
      }
  
      // If there's a response body, parse it
      return await response.json() as DeleteOrderResponse;
    } catch (error) {
      throw new Error(`Error occurred while deleting the order: ${(error as Error).message}`);
    }
  };

  

  export async function fetchAndMapTokensWithAuth(
    payload: TokensToSymbolsPayload,
  ): Promise<TokensToSymbolsMappedResult | void> {
    const url = `${FLOW_BACKEND_BASE_URL}aux/tokensToSymbols`;
    const authToken = store.getState().authUser?.user?.access_token;
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the Authorization header
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      const result: TokensToSymbolsApiResponse = await response.json();
  
      // Map tokens from payload to symbols from the response
      const mappedResult: TokensToSymbolsMappedResult = payload.token_seg_ids.map((item, index) => ({
        token: item.token,
        symbol: result.data[index]?.symbol || "N/A", // Handle potential missing symbol
      }));
  
      console.log("Mapped Result:", mappedResult);
      return mappedResult;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }