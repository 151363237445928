import React,{useState,useEffect} from 'react';
import { ReactComponent as Tick } from '../../assets/icons/tick-circle.svg';

const RiskDisclosure:React.FC=()=>{
    const [link,setLink] = useState("https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html")
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      console.log("isDesktop",isDesktop)

    if(isDesktop){
        return(
            <div className='discContent'>
            <div className='discHeader'>
                Risk Disclosures on Derivatives
            </div>
            <div className='discPointers mt-5'>
                <div className='pointers-flex'>
                    <Tick />
                    <div className='pl-3'>9 out of 10 individual traders in equity Future and Options Segment, incurred net losses. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3'>Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading<br/> losses as transactions costs. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3'>On an average, loss makers registered net trading loss close to ₹ 50,000.</div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3'>Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.</div>
                </div>
            </div>
    
            <div className='discInfo'>
                <div className='infoHead'>For more Information:</div>
                <div className='hyperlink'><a href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html" target="_blank">{link}</a></div>
            </div>
        </div>
        )
    }else{
        return(
            <div className='discContent'>
            <div className='discHeader' style={{"fontSize":"18px"}}>
                Risk Disclosures on Derivatives
            </div>
            <div className='discPointers mt-5'>
                <div className='pointers-flex'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>9 out of 10 individual traders in equity Future and Options Segment, incurred net losses. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading<br/> losses as transactions costs. </div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>On an average, loss makers registered net trading loss close to ₹ 50,000.</div>
                </div>
                <div className='pointers-flex pt-4'>
                    <Tick />
                    <div className='pl-3' style={{"fontSize":"12px"}}>Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.</div>
                </div>
            </div>
            <div className='discInfo' style={{"marginTop":"5px"}}>
                <div className='infoHead'>For more Information:</div>
                <div className='hyperlink' style={{"fontSize":"8px"}}><a href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html" target="_blank">{link}</a></div>
            </div>
        </div>
        )
    }

}

export default RiskDisclosure