import React from "react";
import { Form } from "react-bootstrap";

interface NoteInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
  onClear: () => void;
}

const NoteInput: React.FC<NoteInputProps> = ({ value, onChange, disabled ,onClear}) => {
  return (
    <Form.Group
      className="text-start w-100 text-color font-16 mb-1 mt-4"
      controlId="note"
    >
      <Form.Label htmlFor="note">
        <strong className="text-color">Add a note (Optional)</strong>
      </Form.Label>
      <Form.Control
        id="note"
        className={`bg-transparent search-bar ${disabled && 'cursor-not-allowed alerts-disabled'}`}
        placeholder="Example: prediction based on highest price"
        as="textarea"
        disabled={disabled}
        rows={3}
        maxLength={140}
        value={value}
        onChange={onChange}
      />
      {/* Add text Saying value.length/140 characters */}
      <div className="d-flex justify-content-between ">
        {value.length > 0  && (
          <div className="text-muted text-end">{value.length}/140 Characters</div>
        )}
        {value.length > 0 && <div style={{cursor: 'pointer'}} onClick={onClear} className="text-danger">Clear</div>}
      </div>
    </Form.Group>
  );
};

export default NoteInput;
