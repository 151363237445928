export const formatDate = (expDate: string) => {
  if (!expDate || expDate.length !== 8) {
    return null; // Return null if the date is not in the expected format
  }

  const year = expDate.substring(0, 4);
  const month = expDate.substring(4, 6);
  const day = expDate.substring(6, 8);

  // Create a new Date object using the extracted components
  const date = new Date(`${year}-${month}-${day}`);

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit", // Display two digits for the day
    month: "short", // Abbreviated month (e.g., "Dec")
    year: "numeric", // Display full year (e.g., "2024")
  };

  // Format the date using `toLocaleDateString`
  return date.toLocaleDateString("en-GB", options); // Using 'en-GB' for the "DD MMM YYYY" format
};

export function formatExpiryDate(dateStr: string): string {
  const date = new Date(dateStr);

  // Extract day, month, and year directly from the Date object
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase(); // Convert month to uppercase
  const year = date.getFullYear();

  // Return in "DD MMM YYYY" format
  return `${day} ${month} ${year}`;
}

export const formatTimestamp = (timestamp: number): string => {
  // Create a new Date object from the Unix timestamp
  const date = new Date(timestamp * 1000); // multiply by 1000 to convert seconds to milliseconds

  // Format the date into "dd mmm yyyy"
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatDateNormal = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export function parseDate(input: string) {
  let day = "";
  let month = "";
  let year = "";
  let i = 0;

  // Extract day
  while (i < input.length && !isNaN(Number(input[i]))) {
    day += input[i];
    i++;
  }

  // Extract month
  while (i < input.length && isNaN(Number(input[i]))) {
    month += input[i];
    i++;
  }

  // Extract year
  while (i < input.length && !isNaN(Number(input[i]))) {
    year += input[i];
    i++;
  }
  return `${day} ${month} ${year}`;
}

// export function formatExpiryDate(timestamp: number): string {
//   const date = new Date(timestamp * 1000);  // Convert Unix timestamp to milliseconds
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: 'short', // Use 'short' for abbreviated month name
//     year: '2-digit', // Use '2-digit' for the year (e.g., '26' for 2026)
//   };

//   // Using toLocaleDateString to format the date in 'DD MMM YY' format
//   return date.toLocaleDateString('en-GB', options).toUpperCase();  // Example: '24 DEC 26'
// }
