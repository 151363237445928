import Decimal from 'decimal.js';

import { GTTOrder } from "../../../../models/gttOrders";
import { FormValidation, GTTFormState, TradeDirection } from "../gttTypes";

// Validate individual field
export const validateField = (
  field: keyof GTTFormState,
  value: string
): boolean => {
  switch (field) {
    case "price":
    case "targetPrice":
      // Allow Market or positive number with up to 2 decimal places
      return (
        value === "Market" ||
        (/^\d+(\.\d{1,2})?$/.test(value) && parseFloat(value) > 0)
      );

    case "quantity":
    case "targetQuantity":
      // Ensure quantity is a positive integer, cannot be "Market"
      return /^\d+$/.test(value) && parseInt(value) > 0;

    case "triggerPrice":
    case "targetTriggerPrice":
      // Allow positive number with up to 2 decimal places
      return /^\d+(\.\d{1,2})?$/.test(value) && parseFloat(value) > 0;

    default:
      return true;
  }
};

// Create GTT inital State

export const calculateInitialGttState = (
  ltp: string,
  tradeDirection: TradeDirection,
  tick: number
): GTTFormState => {
  const ltpValue = parseFloat(ltp);

  if (isNaN(ltpValue)) {
    return {
      triggerType: "OCO",
      triggerPrice: "",
      quantity: "",
      price: "",
      orderType: "Limit",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  }

  // Calculate base prices based on trade direction
  const rawTriggerPrice =
    tradeDirection === "Buy" ? ltpValue * 1.05 : ltpValue * 0.95;

  const rawTargetTriggerPrice =
    tradeDirection === "Buy" ? ltpValue * 0.95 : ltpValue * 1.05;

  // Round prices to nearest tick value
  const triggerPrice = roundToNearestTick(rawTriggerPrice, tick );
  const price = triggerPrice; // Using same rounded value for price
  const targetTriggerPrice = roundToNearestTick(rawTargetTriggerPrice, tick);
  const targetPrice = targetTriggerPrice; // Using same rounded value for target price

  return {
    triggerType: "OCO",
    triggerPrice,
    quantity: "1",
    price,
    orderType: "Limit",
    targetTriggerPrice,
    targetQuantity: "1",
    targetPrice,
    targetOrderType: "Limit",
  };
};

export const calculateEditInitialGttState = ({
  orderType,
  price,
  quantity,
  triggerPrice,
  triggerType,
  targetOrderType,
  targetPrice,
  targetQuantity,
  targetTriggerPrice,
}: GTTFormState): GTTFormState => {
  return {
    triggerType: triggerType,
    triggerPrice: triggerPrice,
    quantity: quantity,
    price: price,

    orderType: orderType,

    targetTriggerPrice: targetTriggerPrice,
    targetQuantity: targetQuantity,
    targetPrice: targetPrice,
    targetOrderType: targetOrderType,
  };
};

export const GTTFormValidationState: FormValidation = {
  triggerPrice: false,
  quantity: false,
  price: false,
  targetTriggerPrice: false,
  targetQuantity: false,
  targetPrice: false,
};

export const transformGTTObjectToFormState = (
  gttOrder: GTTOrder,
  tradeDirection: TradeDirection,
  tick: number
): GTTFormState => {
  // Helper function to safely convert and round price values
  const formatPrice = (value: number | string | undefined): string => {
    if (!value) return "";
    const numValue = typeof value === "string" ? parseFloat(value) : value;
    return isNaN(numValue) ? "" : roundToNearestTick(numValue, tick);
  };

  // Safety check for undefined/null gttOrder or orders
  if (!gttOrder?.orders || gttOrder.orders.length === 0) {
    return {
      triggerType: "Single",
      triggerPrice: "",
      quantity: "",
      price: "",
      orderType: "Limit",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  }

  // Destructure and create copies to avoid mutating original data
  let [firstOrder, secondOrder] = [...gttOrder.orders];

  // For single order case
  if (!secondOrder) {
    return {
      triggerType: "Single",
      triggerPrice: formatPrice(firstOrder?.trigger_price),
      quantity: firstOrder?.quantity?.toString() || "",
      price: formatPrice(firstOrder?.price),
      orderType: firstOrder?.variety === "RL" ? "Limit" : "Market",
      targetTriggerPrice: "",
      targetQuantity: "",
      targetPrice: "",
      targetOrderType: "Limit",
    };
  }

  // For OCO orders, determine which is stopLoss and which is target
  let stopLoss, target;

  if (tradeDirection === "Buy") {
    // For Buy: Higher trigger price is stopLoss
    if (Number(firstOrder.trigger_price) > Number(secondOrder.trigger_price)) {
      stopLoss = firstOrder;
      target = secondOrder;
    } else {
      stopLoss = secondOrder;
      target = firstOrder;
    }
  } else {
    // For Sell: Lower trigger price is stopLoss
    if (Number(firstOrder.trigger_price) < Number(secondOrder.trigger_price)) {
      stopLoss = firstOrder;
      target = secondOrder;
    } else {
      stopLoss = secondOrder;
      target = firstOrder;
    }
  }

  // Return the transformed state with rounded prices
  return {
    triggerType: "OCO",
    triggerPrice: formatPrice(stopLoss?.trigger_price),
    quantity: stopLoss?.quantity?.toString() || "",
    price: formatPrice(stopLoss?.price),
    orderType: stopLoss?.variety === "RL" ? "Limit" : "Market",
    targetTriggerPrice: formatPrice(target?.trigger_price),
    targetQuantity: target?.quantity?.toString() || "",
    targetPrice: formatPrice(target?.price),
    targetOrderType: target?.variety === "RL" ? "Limit" : "Market",
  };
};

/**
 * Rounds a number to the nearest tick value and returns it as a string
 * with appropriate decimal places based on the tick size
 *
 * @param value - The number to round
 * @param tick - The tick size (e.g., 0.05, 0.01)
 * @returns The rounded value as a string with appropriate decimal places
 *
 * @example
 * roundToNearestTick(105.234, 0.05) // returns "105.25"
 * roundToNearestTick(105.234, 0.01) // returns "105.23"
 */

// function roundToNearestTick(value: number, tick: number): string {
//   if (tick <= 0) {
//     throw new Error("Tick must be a positive number.");
//   }

//   let currentValue: number = value;

//   while (true) {
//     // Check if the current value is divisible by the tick
//     if ((currentValue % tick).toFixed(2) === "0.00") {
//       return currentValue.toFixed(2);
//     }
//     // Increment by the smallest step (0.01)
//     currentValue += 0.01;
//     // Ensure precision to 2 decimal places after subtraction
//     currentValue = parseFloat(currentValue.toFixed(2));
//   }
// }

// Using Concept Of Binary Serach ## https://leetcode.com/discuss/interview-experience/1979273/infinite-sorted-array
function roundToNearestTick(value: number, tick: number): string {
  if (tick <= 0) {
      throw new Error("Tick must be a positive number.");
  }

  // Convert inputs to Decimal
  const valueNum = new Decimal(value);
  const tickNum = new Decimal(tick);

  // Calculate the nearest lower and upper bounds divisible by the tick
  const lowerBound = valueNum.minus(valueNum.mod(tickNum));
  const upperBound = lowerBound.plus(tickNum);

  let low = lowerBound;
  let high = upperBound;

  let result: Decimal | null = null;

  // Binary search for the nearest divisible value
  while (low.lessThanOrEqualTo(high)) {
      const mid = low.plus(high).dividedBy(2).toDecimalPlaces(2); // Ensure 2 decimal places
      const modResult = mid.mod(tickNum);

      if (modResult.isZero()) {
          result = mid;
          break;
      } else if (mid.lessThan(valueNum)) {
          low = mid.plus(0.01);
      } else {
          high = mid.minus(0.01);
      }
  }

  // If no exact result is found, fallback to the closer bound
  if (!result) {
      const distanceToLower = valueNum.minus(lowerBound).abs();
      const distanceToUpper = upperBound.minus(valueNum).abs();
      result = distanceToLower.lessThan(distanceToUpper) ? lowerBound : upperBound;
  }

  return result.toFixed(2);
}

export function validateTriggerPrice(
  triggerPrice: string | number,
  ltp: string | number,
  tradeDirection: TradeDirection,
  tick: number,
  greaterMessage: string,
  lessMessage: string,
  multipleMessage: string,
  isMarket: boolean = false
): string {
  if (isMarket || triggerPrice === 'Market') {
    return '';
  }

  try {
    const triggerPriceNum = new Decimal(triggerPrice);
    const ltpNum = new Decimal(ltp);
    const tickDecimal = new Decimal(tick);

    // Basic number validation
    if (triggerPriceNum.isNaN() || ltpNum.isNaN()) {
      return "Invalid trigger price or Last Trade Price";
    }

    // Direction-based validation
    if (tradeDirection === "Buy") {
      if (triggerPriceNum.lessThan(ltpNum)) {
        return greaterMessage;
      }
    } else {
      if (triggerPriceNum.greaterThan(ltpNum)) {
        return lessMessage;
      }
    }

    // Tick multiple validation
    const remainder = triggerPriceNum.modulo(tickDecimal);
    if (!remainder.isZero()) {
      return multipleMessage;
    }

    return "";
  } catch (error) {
    return "Invalid price format";
  }
}



export const ErrorMessageGTT = {
  stopLossMessage: {
    greaterMessage:
      "Stop Loss Triggered Price should be greater than Last Trade Price",
    lessMessage:
      "Stop Loss Triggered Price should be less than Last Trade Price",
    multipleMessage: (tick: number) =>
      `Trigger Price should be a multiple of ${tick}`,
  },
  targetMessage: {
    greaterMessage:
      "Target Triggered Price should be greater than Last Trade Price",
    lessMessage:
      "Target Triggered Price should be less than Last Trade Price",
    multipleMessage: (tick: number) =>
      `Trigger Price should be a multiple of ${tick}`,
  },
};


// Utility function to validate and format decimal numbers
// Utility function to validate and format decimal numbers
export const formatDecimalNumber = (value: string): string => {
  // If empty, return '0'
  if (!value) return '0';

  // Check if the last character is a decimal point
  const endsWithDecimal = value.endsWith('.');

  // Remove any character that isn't a digit or decimal point
  let sanitizedValue = value.replace(/[^\d.]/g, '');

  // Handle multiple decimal points by keeping only the first one
  const parts = sanitizedValue.split('.');
  if (parts.length > 2) {
    sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
  }

  // Remove leading zeros from the whole number part
  sanitizedValue = sanitizedValue.replace(/^0+(?=\d)/, '');

  // If the sanitized value is empty after removing leading zeros, set it to '0'
  if (sanitizedValue === '' || sanitizedValue === '.') {
    sanitizedValue = '0';
  }

  // Limit decimal places to 2 while preserving typing
  const [whole, decimal] = sanitizedValue.split('.');
  if (decimal && decimal.length > 2) {
    sanitizedValue = whole + '.' + decimal.slice(0, 2);
  }

  // Preserve trailing decimal point during typing
  if (endsWithDecimal && !sanitizedValue.endsWith('.')) {
    sanitizedValue += '.';
  }

  return sanitizedValue;
};


export const GttOrderCreateTerms = 'I agree to the terms, understand and accept that order executed are not guaranteed.'