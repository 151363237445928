import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import Buy from "../../../assets/icons/Buy.svg"
import Sell from "../../../assets/icons/Sell.svg"
import moment from 'moment';
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {NumberColor,ExchangeSegmentMap} from "../../common/number_color";
import {LegDetails} from "../../../models/strategy"
import {Quotes} from "../../../models/symbolData"
import {formatdateDDMM} from "../../../../src/comman/status"
import Subscribed from "../../../models/symbolData"

interface CombinationsPros{
    options: LegDetails;
    currentindex:number;
    currentMultiplier:number;
    strategyName:string | undefined;
    bool:boolean;
}
const OptionsCombinations:React.FC<CombinationsPros> = ({options,currentindex,currentMultiplier,strategyName,bool}) =>{

    const [ subData, setSubData ] = useState<Subscribed | null>(null);
    

    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [options])

    let listenerGuid:string | undefined = undefined
    function headerData() {
        listenerGuid = `cepe-${currentindex}-${options.option.token}-${ExchangeSegmentMap[options.option.exchange]}`
        DataFeedInstance?.subcscribeQuotesFromTokens([
            {market_segment_id: ExchangeSegmentMap[options.option.exchange] , token: options.option.token }],(quotes:Quotes[])=>{
            setSubData(quotes[0].v)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(listenerGuid)
    }


    if(subData){
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                <div className='multvalue' style={{"fontSize":"18px"}}>{(options.quantity/options.option.lot_size)*(bool?currentMultiplier:1)}</div>
                <div className="cross" style={{"alignSelf": "center","height":"100%"}}><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className=""><img height={"17px"} width={"17px"} src={options.action=="BUY"?Buy:Sell}/></div>
                <div style={{"alignSelf": "center","height":"100%","width":"60px"}} className=" sPrice">{(options.option.strike_price).toFixed(2)}</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">{options.option.option_type}</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">{formatdateDDMM(options.option.expiry_date)}</div>
                <div id={(strategyName as string)+(currentindex+1)} style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className={`ltp_change ${(strategyName as string)+(currentindex+1)}`}>{subData?subData.lp.toFixed(2):0}</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change"><NumberColor number={subData.chp} numFix={true} percentageAdd={true} isFiniteCheck={true} returnBracket={true}/></div>
            </div>
        )
    }else{
        return(
            <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                <div className='multvalue' style={{"fontSize":"18px"}}>0</div>
                <div className="cross" style={{"alignSelf": "center","height":"100%"}}><i className="fa-solid fa-xmark"></i></div>
                <div style={{"position":"relative","top":"2px"}} className=""><img height={"17px"} width={"17px"} src={Buy}/></div>
                <div style={{"alignSelf": "center","height":"100%","width":"60px"}} className=" sPrice">0</div>
                <div style={{"alignSelf": "center","height":"100%"}} className="cepe">CE</div>
                <div style={{"alignSelf": "center","height":"100%","textAlign":"right","width":"50px"}} className="date">---</div>
                <div id={(strategyName as string)+(currentindex+1)} style={{"alignSelf": "center","height":"100%","width":"100px","textAlign":"center"}} className={`ltp_change ${(strategyName as string)+(currentindex+1)}`}>0</div>
                <div style={{"alignSelf": "center","height":"100%","color":"#171D27","width":"100px","textAlign":"right"}} className="ltp_change">0</div>
            </div>
        )
    }
}

export default OptionsCombinations;