import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import OptionStrategyHeader from "../OptionStrategyHeader"
import {setStrategyState,setCurrentMultilier} from "../../../slices/optionStrategySlice";
import flowBackEndServices2 from "../../../services/flowBackendServices";
import {currentUser} from "../../../slices/authSlice";
import AnalyseGraph from "./AnalyseGraph";
import AnalyseOptions from './AnalyseOptions';
import {ReactComponent as Expandicon} from "../../../assets/icons/ExpansionIcon.svg"
import {ReactComponent as Contracticon} from "../../../assets/icons/ContractIcon.svg"
import CustomSpinner from "../CustomSpinner";
import HeaderSubscribedData from "./HeaderSubscribedData"
import { RootState } from '../../../store';
import {PayOffData,Payoff,StrategyState} from "../../../models/strategy"

interface LegInfo{
        token: number,
        action: string,
        quantity: number;
    }
const StrategyAnalyst:React.FC = ({})=>{

    const dispatch = useDispatch()
    const {current_strategy_state,current_symbol_details,analyse_data,current_multiplier,available_funds,required_margin} = useSelector((state:RootState) => state.optionStrategy);
    const [value, setValue] = useState<number | null>(null); // Set initial value to 50
    const [analyseLegs,setAnalyseLegs]=useState<LegInfo[] | null>(null)
    const user = useSelector(currentUser);  
    const [payOffGraphData,setPayOffGraphData]=useState<Payoff[]>([])
    const [debouncedFunction, setDebouncedFunction] = useState<NodeJS.Timeout | null>(null);
    const [payOffData,setPayOffData]=useState<PayOffData | null>(null)
    const [currentMultiplier,setCurrentMultiplier]=useState(current_multiplier?current_multiplier:1)
    const [lotSize,setLotSize] = useState<number>(0)
    const [undividedPayOffData,setUndividedPayOffData]=useState<Payoff[] | null>(null)
    const [expandedGraph,setExpandedGraph]=useState(false)
    const [isLoading,setIsLoading]=useState(true)

    useEffect(()=>{
        formatPayOffInput()
    },[])

    useEffect(()=>{
        if(undividedPayOffData && lotSize) formatPayOffData(undividedPayOffData,lotSize)
    },[current_multiplier])

    useEffect(()=>{
        if(payOffGraphData) getAnalyseGraph()
    },[payOffGraphData])

    useEffect(()=>{
        if(analyseLegs) getPayOff(false,0)
    },[analyseLegs])

    
    const onBackClick =()=>{
        if(current_strategy_state.url=="analyse"){
            let state = {id:4,url:'strategyDetails'}
            dispatch(setStrategyState(state))
        }else{
            let state = {id:7,url:'builderStrategyDetails'}
            dispatch(setStrategyState(state))
        }
    }

    function formatPayOffInput(){
        let data:LegInfo[] = []
        analyse_data?.legs.map(d=>{
            let json ={
                token:d.option.token,
                action:d.action,
                quantity:d.quantity
            }

            data.push(json)
        })
        setAnalyseLegs(data)
    }

    function handleChange(event:any) {
        setValue(event.target.value);
        if (debouncedFunction) {
            clearTimeout(debouncedFunction);
        }
        const newDebouncedFunction = setTimeout(() => {
            // This function will be called after a certain delay (e.g., 300 milliseconds)
            getPayOff(true,Number(event.target.value))
            // Perform your desired action here, such as making an API call
          }, 1000); // Adjust the delay (in milliseconds) as needed
      
          // Store the reference to the new debounce timer
        setDebouncedFunction(newDebouncedFunction);
      }

    function getPayOff(hasInputExpiry:boolean,inpDaysToExpiry:number){
        var data 
        var exchange:string | undefined=""
        if (current_symbol_details?.sExchange == "NSE_EQ" || current_symbol_details?.sExchange == "NSE_FO"){
            exchange = "NSE_FO"
        }else if (current_symbol_details?.sExchange == "BSE_EQ" || current_symbol_details?.sExchange == "BSE_FO"){
            exchange = "BSE_FO"
        }else{
            exchange = current_symbol_details?.sExchange
        }
        if (hasInputExpiry){
             data = {exchange:exchange,symbol: current_symbol_details?.sSymbol,days_to_expiry:inpDaysToExpiry,legs:analyseLegs}
        }else{
             data = {exchange:exchange,symbol: current_symbol_details?.sSymbol,legs:analyseLegs}
        }
        
        flowBackEndServices2.getPayOff(data,String(user?.access_token)).then(value => {
            return value.data
        }).then((data)=>{
            const response:PayOffData=data.data
            if(response.payoffs!=null){
                setPayOffData(response)
                formatPayOffData(response.payoffs,response.leg_greeks[0]['lot_size'])
                setUndividedPayOffData(response.payoffs)
                setLotSize(response.leg_greeks[0]['lot_size'])
                setIsLoading(false)
                setValue(inpDaysToExpiry==0 && hasInputExpiry==false?data.data.min_days_to_expiry:inpDaysToExpiry)
            }
        }).catch(reason => {
            console.log("Error in strategies data",reason);
        })

    }

    function formatPayOffData(payOff:Payoff[],lotSize:number){
        const modifiedArray:Payoff[] =payOff.map(data=>{
            return{...data,at:data.at,intraday_pay_off:(data.intraday_pay_off)*currentMultiplier,expiry_pay_off:(data.expiry_pay_off)*currentMultiplier}
       })
       setPayOffGraphData(modifiedArray)
    }

    function formatBreakEvens(data:number[]){
        if(data){
            const modifiedArray = data.map((element) => {
                return (element).toFixed(2);
              });
            return modifiedArray.join(',')
        }else{
            return ''
        }
    }

    const addMultiplier=()=>{
        let value=currentMultiplier
        value+=1
        setCurrentMultiplier(value)
        dispatch(setCurrentMultilier(value))
    }

    const subtractMultiplier=()=>{
        if(currentMultiplier>1){
            let value=currentMultiplier
            value-=1
            setCurrentMultiplier(value)
            dispatch(setCurrentMultilier(value))
        }
    }

    function getPnLnGreeks(){
        return(
            <React.Fragment>
                <div style={{"display":"flex","flexDirection":"row","marginTop":"5px","justifyContent":"space-between"}}>
                <div style={{"textAlign":"left"}}>
                    <div className='detailshead'>Max profit</div>
                    <div className='profitValue'>{payOffData?.infinite_profit?'Unlimited':(payOffData?.max_profit as number)*parseInt(currentMultiplier.toFixed(2))}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Max loss</div>
                    <div className='lossValue'>{payOffData?.infinite_loss?'Unlimited':(-1*(payOffData?.max_loss as number))*parseInt(currentMultiplier.toFixed(2))}</div>
                </div>
            </div>
            <div style={{"display":"flex","flexDirection":"row","marginTop":"15px","justifyContent":"space-between"}}>
                <div style={{"textAlign":"left"}}>
                    <div className='detailshead'>Delta</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.delta as number)*currentMultiplier).toFixed(2)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Gamma</div>
                    <div className='breakevensValue'>{(((payOffData?.combined_greeks.gamma as number))*currentMultiplier).toFixed(4)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Theta</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.theta as number)*currentMultiplier).toFixed(2)}</div>
                </div>
                <div style={{"textAlign":"right"}}>
                    <div className='detailshead'>Vega</div>
                    <div className='breakevensValue'>{((payOffData?.combined_greeks.vega as number)*currentMultiplier).toFixed(2)}</div>
                </div>
            </div>
        </React.Fragment>
        )
    }

    const getOptionsSubcribedData = () =>{
        return payOffData?.leg_greeks.map((data,index)=>{
            return(
                <AnalyseOptions key={index} options={data} currentindex={index} minQuantity={lotSize} currentMultiplier={current_multiplier?current_multiplier:currentMultiplier}/>
            )
        })
    }

    const getMultiplier=()=>{
        return(
            <div className='multiplier2'>
                <div className='multText'>Multiplier</div>
                <div className='addSubtractBox ml-4 c-pointer' onClick={subtractMultiplier}><i className="fa-solid fa-minus"></i></div>
                <div className='addSubtractBox' style={{"background":"#FFFFFF","width":"32px"}}>{currentMultiplier}</div>
                <div className='addSubtractBox c-pointer' onClick={addMultiplier}><i className="fa-solid fa-plus"></i></div>
            </div>
        )
    }

    const openExecution = () =>{
        if(current_strategy_state.url=="analyse"){
            let data:StrategyState = {id:6,url:'execution'}
            dispatch(setStrategyState(data))
        }else{
            let data:StrategyState = {id:6,url:'builderExecution'}
            dispatch(setStrategyState(data))
        }
      }

    const getSlider = () =>{


            return(
                <React.Fragment>
                    <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                        <div className='dte'>Days To Expiry</div>
                        <div className='daystoexpire'>{value}</div>
                    </div>
                    <div >
                        <div style={{"display":"flex","flexDirection":"row","justifyContent":"space-between"}}>
                            <div className='dte'>{payOffData?.min_days_to_expiry}</div>
                            <div className='dte'>0</div>
                        </div>
                            <input style={{"width":"100%","direction": "rtl"}}
                            type="range"
                            min="0"
                            max={payOffData?.min_days_to_expiry}
                            value={value ?? 0}
                            title={String(value) ?? ""}
                            onChange={handleChange}
                            step={1}
                            />
                    </div>
                </React.Fragment>
            )
    }

    function getAnalyseGraph(){
            return(
                <AnalyseGraph payOffData={payOffGraphData} expanded={expandedGraph}/>
            )
    }

    const GraphExpansion =()=>{
        let value = !expandedGraph
        setExpandedGraph(value)
    }

    function getBottom(){
        return(
            <div className='bottom-area pt-4' style={{"alignItems":"center"}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-6' >
                        <div className='details'>
                            <div className='pl-3'>
                                <div className='detailshead'>Required</div>
                                <div className='ltp_change' style={{"textAlign":"left"}}>{required_margin?(required_margin*currentMultiplier).toFixed(2):0}</div>
                            </div>
                            <div style={{"paddingLeft":"20%"}}>
                                <div className='detailshead'>Available</div>
                                <div className='ltp_change' style={{"textAlign":"left"}}>{available_funds?available_funds.toFixed(2):0}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='continue2 c-pointer' onClick={openExecution}>
                            Continue
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    return(
        <React.Fragment>
            <OptionStrategyHeader>
                <div className='flex headerText'> <i onClick={onBackClick} className="fa fa-arrow-left c-pointer" aria-hidden="true"/>&nbsp;<span className=' px-2'>Analyse</span></div>
            </OptionStrategyHeader>
            {isLoading && (
                <CustomSpinner />
            )}
            {expandedGraph==false && !isLoading &&  (
                <React.Fragment>
                    <div style={{"display":"flex","flexDirection":"row"}}>
                    <div style={{"textAlign":"left","paddingLeft":"20px","paddingTop":"13px"}}>
                        <div className='underlying'>Underlying</div>
                        <div className='stratName'>{current_symbol_details?.sSymbol}</div>
                        <div className='pt-4' style={{"position":"relative","left":"-8px"}}>
                        <HeaderSubscribedData token={current_symbol_details?.nToken} segmentId={current_symbol_details?.nMarketSegmentId} symbol={current_symbol_details?.sSymbol} description={current_symbol_details?.sSecurityDesc!=""?current_symbol_details?.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details?.ExchangeSegment} location={"analysis"}/>
                            <div className='c-pointer' style={{"position":"absolute","top":"-3%","right":"0px"}} onClick={()=>GraphExpansion()}><Contracticon/></div>
                            {payOffGraphData && getAnalyseGraph()}
                        </div>
                        {payOffData && getSlider()}
                    </div>
                    <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"24px"}}>
                        <div className='AnalyseBreakEvens p-4'>
                            <div className='detailshead'>Breakeven points</div>
                            <div className='breakevensValue bdr' style={{"paddingBottom":"5px"}}>{payOffData && formatBreakEvens(payOffData.breakevens)}</div>
                            {payOffData && getPnLnGreeks()}
                        </div>
                        <div className='mt-4 optionsLeg px-4'>
                            {getMultiplier()}
                            <div style={{"height":"86px","overflowY":"scroll"}}>
                                <div className='mt-4'>
                                    {payOffData && getOptionsSubcribedData()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {getBottom()}
        </React.Fragment>
            )}
            {expandedGraph==true && !isLoading && (
                <React.Fragment>
                    <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"13px"}}>
                        <div className='underlying'>Underlying</div>
                        <div className='stratName'>{current_symbol_details?.sSymbol}</div>
                    </div>
                    <div style={{"height":"313px","overflowY":"scroll"}}>
                        <div style={{"textAlign":"left","paddingLeft":"24px","paddingTop":"13px"}}>
                            <div className='pt-4' style={{"position":"relative","left":"-23px"}}>
                                <HeaderSubscribedData token={current_symbol_details?.nToken} segmentId={current_symbol_details?.nMarketSegmentId} symbol={current_symbol_details?.sSymbol} description={current_symbol_details?.sSecurityDesc!=""?current_symbol_details?.sSecurityDesc:current_symbol_details.sSymbol+" Index"} exchange={current_symbol_details?.sExchange} location={"analysis"}/>
                            <div className='c-pointer' style={{"position":"absolute","top":"-3%","right":"0px"}} onClick={()=>GraphExpansion()}><Expandicon/></div>
                                {payOffGraphData && getAnalyseGraph()}
                            </div>
                        </div>
                        <div style={{"display":"flex","flexDirection":"row","paddingTop":"5px"}}>
                            <div style={{"paddingLeft":"24px"}}>
                                <div className='AnalyseBreakEvens p-4'>
                                    <div className='detailshead'>Breakeven points</div>
                                    <div className='breakevensValue bdr' style={{"paddingBottom":"5px"}}>{payOffData && formatBreakEvens(payOffData.breakevens)}</div>
                                    {payOffData && getPnLnGreeks()}
                                </div>
                            </div>
                            <div style={{"marginLeft":"30px"}}>
                                <div style={{"width":"99%"}}>
                                    {payOffData && getSlider()}
                                </div>
                                <div>
                                    <div className='mt-4 optionsLeg2 px-4'>
                                        {getMultiplier()}
                                        <div style={{"height":"74px","overflowY":"scroll"}}>
                                            <div className='mt-4'>
                                                {payOffData && getOptionsSubcribedData()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {getBottom()}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default StrategyAnalyst