import React, {useState} from 'react';
import './all_stayle.css'
import {numDifferentiation} from "../../comman/status";

interface NumberColorProps {
    number?: number;
    textChange?: boolean;
    numFix?: boolean;
    percentageAdd?: boolean;
    isFiniteCheck?: boolean;
    returnSign?: boolean;
    returnBracket?: boolean;
    percentageSymbol?: boolean;
  }

  interface ExchangeSegmentMap {
    [key: string]: number;
  }

  interface SegmentExchangeMap {
    [key: number]: string;
  }
  

export function NumberColor({number=0,textChange=false,numFix=false,percentageAdd=false,isFiniteCheck=false,returnSign=false,returnBracket=false}: NumberColorProps): JSX.Element | null {
    const num = Math.sign(number);
    let strNumber: string;
    number.toFixed(2)
    // Format the number based on the flags
    if (!isFiniteCheck || isFinite(number)) {
      strNumber = numFix ? number.toFixed(2) : number.toString();
    } else {
      strNumber = "0.00"; // Default for non-finite values
    }
  
    // Add percentage suffix if required
    if (percentageAdd) {
      strNumber += "%";
    }

    if(returnSign===true){
        if(num === 1) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }else if(num === -1) {
            return(<i className="fa-solid fa-caret-down red_icon"></i>);
        }else if(num === 0) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }else if(num === -0) {
            return(<i className="fa-solid fa-caret-down red_icon"></i>);
        }else if(isNaN(num)) {
            return(<i className="fa-solid fa-caret-up greenText1"></i>);
        }

    }else if(returnBracket==true){
        if(num === 1) {
            return(<div className="greenText1">(+{strNumber})</div>);
        }else if(num === -1) {
            return(<div className="redText1">({strNumber})</div>);
        }else if(num === 0) {
            return(<div className="greenText1">(+{strNumber})</div>);
        }else if(num === -0) {
            return(<div className="redText1">({strNumber})</div>);
        }else if(isNaN(num)) {
            return(<div className="greenText1">(0)</div>);
        }
    }
    else{
        if(num === 1) {
            return(<div className="greenText1">+{strNumber}</div>);
        }else if(num === -1) {
            return(<div className="redText1">{strNumber}</div>);
        }else if(num === 0) {
            return(<div className="greenText1">+{strNumber}</div>);
        }else if(num === -0) {
            return(<div className="redText1">{strNumber}</div>);
        }else if(isNaN(num)) {
            return(<div className="greenText1">0</div>);
        }
    }
    return null;
}

export const ExchangeSegmentMap:ExchangeSegmentMap = {
    "NSE_EQ": 1,
    "NSE_FO": 2,
    "MCX_FO": 5,
    "NSE_CUR": 13,
    "BSE_FO": 4,
    "BSE_EQ": 3,
    "NSE_CUR_SPOT": 14,
    "MCX_FO_SPOT": 6,
}

export const SegmentExchangeMap:SegmentExchangeMap = {
    1: "NSE_EQ",
    2: "NSE_FO",
    5: "MCX_FO",
    13: "NSE_CUR",
    14: "NSE_CUR_SPOT",
    6: "MCX_FO_SPOT",
    4: "BSE_FO",
    3: "BSE_EQ"
}

// export const ExchangeSegmentMap;