import React, { useEffect, useMemo } from "react";
import VirtualizedList from "../../../components/VirtualizedList";
import { ScripLookUpHitSource } from "../../../hooks/api/get/getScripLookUp";
import "./search.scss";
import NoStocksFound from "../../../components/NoStocksFound";

const searhArray = ["All", "Stocks", "Options", "Future", "Indices"] as const;
export type SearchArrayType = (typeof searhArray)[number];

interface SearchResultsDropdownProps {
  showSearchResults: boolean;
  searchQuery: string;
  loading: boolean;
  data: ScripLookUpHitSource[] | null;
  handleSearchResultSelect: (item: ScripLookUpHitSource) => void;
  isError: boolean;

  setSearchType: (searchType: SearchArrayType) => void;
  searchType: SearchArrayType;
}

const SearchResultsDropdown: React.FC<SearchResultsDropdownProps> = ({
  showSearchResults,
  searchQuery,
  loading,
  data,
  setSearchType,
  searchType,
  isError,
  handleSearchResultSelect,
}) => {
  useEffect(() => {
    // Log message when component is unmounted (destroyed)
    return () => {
      setSearchType("All");
    };
  }, []);
  const renderDropdown = useMemo(() => {
    if (!(showSearchResults && searchQuery && data && Array.isArray(data))) {
      return null;
    }

    return (
      <div
        className="position-absolute w-100 mt-2 overallSearch border-12"
        style={{
          top: "100%",
          left: 0,
          zIndex: 1050,
          maxHeight: 249,
          overflow: "hidden",
        }}
      >
        {/* <ButtonGroup> */}
        <div className="text-start d-flex flex-wrap gap-1 my-3 ms-1 overallSearch">
          {searhArray.map((category) => (
            <div
              className={`my_badge_topic ${
                searchType === category ? "active" : ""
              }`}
              key={category}
              // variant={selectedCategory === category ? 'primary' : 'secondary'}
              onClick={() => setSearchType(category)}
            >
              {category}
            </div>
          ))}
        </div>
        {/* </ButtonGroup> */}
        {isError ? (
          <NoStocksFound
            heading="No Stocks Found"
            subHeading="Please try again with a different search term"
          />
        ) : (
          <VirtualizedList
            searchType={searchType}
            loading={loading}
            items={data}
            itemHeight={64}
            visibleHeight={200}
            onItemSelect={handleSearchResultSelect}
          />
        )}
      </div>
    );
  }, [
    showSearchResults,
    searchQuery,
    loading,
    data,
    handleSearchResultSelect,
    searchType,
    isError,
  ]);

  return renderDropdown;
};

export default SearchResultsDropdown;
