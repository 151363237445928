import React, {useEffect, useRef, useState} from 'react';
import BasketketHeader from './Header/bacsketHeader';
import {useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import PublicEachStock from "./PublicEachStock";
import nextId from "react-id-generator";
import { ExecutePublicBasket,sharedBasketAnalyse,sharedBasketSave,setCurrentBasketState,setPublicBasketData,setBasketOldIdData,setPublicBasketMargin, OpenPublicBasket} from "../../slices/basketOrderSlice";
import {shallowEqual,useDispatch,useSelector} from "react-redux";
import { ReactComponent as Save } from "../../assets/icons/symbols_save.svg";
import Button from "react-bootstrap/Button";
import flowBackEndServices2 from "../../services/flowBackendServices";
import flowBackEndService from "../../services/flowBackendApi";
import {toast} from "react-toastify";
import CustomSpinner from '../OptionStrategy/CustomSpinner';
import {ReactComponent as InfoCircle} from "../../assets/icons/info-circle2.svg";
import moment from 'moment';
import {OrderMarginResponse,BasketState,BasketOrder, UpdateBasketParam} from "../../models/basketOrders"
import { MarginInput } from '../../models/strategy';
import {SegmentExchangeMap} from "../common/number_color"
import { currentUser } from "../../slices/authSlice"


const SharedBasketDetails = ()=>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apiDetail,setApiDetail]=useState<any>(null)
    const [ totalMargin, setTotalMargin ] = useState<OrderMarginResponse | null>(null)
    const [savedBasketId,setSavedBasketId]=useState(null)
    const [isLoading,setIsLoading]=useState(true)
    // const user = useSelector(((state:any) => state.authUser),shallowEqual);
    const user = useSelector(currentUser);
    useEffect(()=>{
        let basket_id:string | null=sessionStorage.getItem("public_basket_id")
        if( basket_id && basket_id!="") fetchData(basket_id)
    },[])

    useEffect(()=>{
      if(apiDetail) callTotalMarginApi(apiDetail.basketDetails.basketOrders)
    },[apiDetail])
        const productMap:Record<string,string> = {
            "1": "INTRADAY",
            "2": "MTF",
            "3": "DELIVERY",
        }
        const VarietyMap:Record<string,string> = {
            "1": "RL",
            "2": "RL-MKT",
            "3": "SL",
            "4": "SL-MKT",
        }
      function formateMarginDara(data:BasketOrder) {
        const finalInput:MarginInput = {
          "token": data.token,
          "exchange": SegmentExchangeMap[data.marketSegmentId],
          "transaction_type": (data.buySell == 1 ? "BUY" : "SELL"),
          "product": productMap[data.productType],
          "variety": VarietyMap[data?.orderType],
          "quantity": data.quantity * Number(data.stockData?.lotSize),
          "price": data.price
      }
      return finalInput
     }
 
     function callTotalMarginApi(orders:BasketOrder[] | undefined) {
         let data:MarginInput[] | undefined = orders?.map((d:BasketOrder) => formateMarginDara(d))
         flowBackEndServices2.getTotalMargin({orders: data},String(user?.access_token)).then(value => {
          let response:OrderMarginResponse=value.data
             if(value.status === 200 && response.status === 'success'){
                dispatch(setPublicBasketMargin(response))
                setTotalMargin(response)
             }
         })
     }

      const fetchData = async (basket_id:string) => {
        flowBackEndService.getSharedBasket(basket_id,String(user?.access_token))
        .then(value => {
            return value.data
        })
        .then(value=>{
            if(value.status==="success"){
            setApiDetail(value.response);
            dispatch(setBasketOldIdData(value.response.basketDetails))
          }else{
            toast.error("Shared basket expired or doesn't exist")
            sessionStorage.removeItem("public_basket_id")
            let state:BasketState =  {id: 1,param: {fetch_new: true},url:'BasketFetch'};
            dispatch(setCurrentBasketState(state))
            dispatch(OpenPublicBasket(false))
          }
          setIsLoading(false)
        })
        .catch(error => {
            toast.error("Shared basket expired or doesn't exist");
            setIsLoading(false)
        })
      };

      const tableBody = ()=>{
        return(
            apiDetail.basketDetails.basketOrders.map((item:BasketOrder,index:number) => {
                const id1 = nextId("BE-");
                return(
                    <PublicEachStock key ={index} data={item}/>
                )
            })
        )
      }

      
      const formatExpiry = (date:string) =>{
        const formattedDate = moment(date, 'YYYYMMDD').format('MMM DD, YYYY');
        return formattedDate
      }

      const saveBasket=(basketName:string,orders:BasketOrder[],execute:boolean=false)=>{
        dispatch(sharedBasketSave())
        callCreateBasket(basketName,orders,execute)
      }

      const callCreateBasket=(name:string,ordersList:BasketOrder[],execute:boolean)=>{
        const data:UpdateBasketParam={basketName: name,orders:ordersList}
        flowBackEndService.SaveBasket(data,String(user?.access_token))
            .then(value => {
                  if(value.data.status=="success"){
                    toast.success("basket saved successfully!!!")
                    if(execute){
                      dispatch(ExecutePublicBasket(true))
                    }
                    let state =  {id: 1,param: {fetch_new: true},url:'BasketFetch'};
                    dispatch(setCurrentBasketState(state))
                    setSavedBasketId(value.data.response.id)
                    const updatedObject = {
                        ...apiDetail.basketDetails,
                        id: value.data.response.id,
                      };
                    dispatch(setPublicBasketData(updatedObject))
                  }else{
                    toast.error(`something went wrong`)
                  }
            })
            .catch(error => {
                toast.error(`${error.response.data.message},delete some existing basket`)
                // setIsLoading(false);
            })
            .finally(() => {
                // setIsLoading(false);
            })
    }

    const executeBasket = (basketName:string,orders:BasketOrder[]) =>{
        saveBasket(basketName,orders,true)
    }

    const analyseGraph = () =>{
        let optionAvailable = false
        apiDetail.basketDetails.basketOrders.map((d:BasketOrder)=>{
            if(d.stockData?.marketSegmentId==2){
                optionAvailable = true
            }
        })
        if(optionAvailable){
            let state:BasketState =  {id: 5,param: apiDetail.basketDetails,url:'publicBasketAnalyse'};
            dispatch(setCurrentBasketState(state))
            dispatch(sharedBasketAnalyse())
        }else{
            toast.error("At least one order of any option should be in the basket")
        }
    }

    const basketDetails = ()=>{
      return(
        <div className="d-flex flex-column justify-content-start align-items-start m-4">
            {apiDetail && <div className='pr-2 validity'>Valid till {formatExpiry(apiDetail.basketExpiryDate)}</div>}
        <div className="d-flex scroll_box3 my_scroll_track flex-column align-items-stretch w-100 justify-content-between">
            <table className="pubBasketTable">
                <thead className="card_bg">
                <tr className="headTxt">
                    <th>Name</th>
                    <th>LTP</th>
                    <th>Price</th>
                    <th>Qty.</th>
                </tr>
                </thead>
                <tbody>
                  {apiDetail && tableBody()}
                </tbody>

            </table>
        </div>
    </div>
      )
    }
    return (
        <React.Fragment>
                <BasketketHeader>
                    {apiDetail && <span className="heading2">{apiDetail.basketDetails.basketName}&nbsp;({apiDetail.basketDetails.basketOrders.length}/10)</span>}
                    <span onClick={()=>{saveBasket(apiDetail.basketDetails.basketName,apiDetail.basketDetails.basketOrders)}} className="save_button"><Save />&nbsp;<span className="save">Save</span></span>
                    {apiDetail && <span className='pl-2'>
                    <span className='tooltip2' style={{"position":"relative"}}>
                        <span className='c-pointer'>{<InfoCircle />}</span>
                            <div className="tooltiptext px-4 py-3" style={{"textAlign":"left"}}>
                                <div>1. Basket will be valid for 30 days from the day its shared. In case any scrip in the basket expires within 30 days, the basket link will also expire.</div>
                                <div className='py-2'>2. The link can be shared multiple times to multiple people from your end and receiver's end also.</div>
                            </div>
                        </span>
                    </span>}
                </BasketketHeader>
                {isLoading && (
                <CustomSpinner />
            )}
                {basketDetails()}
                <div className="bottom_execute_bar currentBg2">
                    <div onClick={()=>executeBasket(apiDetail.basketDetails.basketName,apiDetail.basketDetails.basketOrders)} style={{"cursor":"pointer"}} className="execute_btn my-3 w-25 mr-4">
                        Save & Execute
                    </div>
                    <div onClick={analyseGraph} style={{"marginLeft":"170px" , "cursor":"pointer"}} className="analyse_btn my-3 w-25">
                        Analyse
                    </div>
                    <div className='d-flex flex-row'>
                      <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                          <div className="text_grey currentText">Initial Margin</div>
                          <div className="heading2">{totalMargin?totalMargin.initial_margin.toFixed(2):0}</div>
                      </div>
                      <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                        <div className="text_grey currentText">Final Margin</div>
                        <div className="heading2">{totalMargin?totalMargin.required_margin.toFixed(2):0}</div>
                      </div>
                    </div>

                    </div>
    
    
            </React.Fragment>
    )
}

export default SharedBasketDetails