import {QUERY_PARAM} from "../../utils/config";

export function getExtraQueryparam() {
    const val = localStorage.getItem(QUERY_PARAM);
    if (val === null) {
        return '';
    }
    const valStr=val.toString();
    const cleanedVal = '&' + valStr.replace(/^"(.*)"$/, '$1');
    return cleanedVal;
}

export function getDeviceID(DEVICE_ID_KEY:string) {
    let deviceID = localStorage.getItem(DEVICE_ID_KEY)
     if(deviceID === null){
         deviceID = Date.now().toString()
         localStorage.setItem(DEVICE_ID_KEY,deviceID)
     }
    return  deviceID
 }

 export function scopeParse(scope:string, scopeString:string) {
    var permission;

    switch (scope) {
        case "profile.read":
            permission = "Read profile details"
            break;
        case "orders.read":
            if (scopeString.indexOf("orders.write") !== -1) {
                break;
            }
            permission=""
            break
        case "orders.write":
            permission = "Place & manage orders"
            break;
        case "holdings.read":
            permission = "Fetch holdings"
            break;
        default:
            permission = ""
            break;
    }
    return permission
}