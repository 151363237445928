import axios,{ AxiosRequestHeaders } from 'axios';
import {remoteConfigGetter} from "../utils/remoteConfig";

const globalHeaders:AxiosRequestHeaders =  {
    'Content-Type': 'application/json',
    "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
    "X-Stage": String(process.env.REACT_APP_ENV),
    'x-api-key': String(process.env.REACT_APP_VORTEX_KEY)
}

const apiFlowBackend = axios.create({
    baseURL: remoteConfigGetter('inhouse_base_url_prod').asString(),
    headers: {
        'Content-Type': 'application/json',
    },
});



function authorizedHeaders(token:string){
    return {...globalHeaders,'Authorization': `Bearer ${token}`}
}
async function getBuckets (token:string)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.get('aux/baskets',{headers: headers});
}

async function AddBasket (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.post('aux/basket',data,{headers: headers});
}

async function UpdateBasket (data:any,token:string,id:number)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.put('aux/basket/'+ id,data,{headers: headers});
}

async function DeleteBasket (data:any,token:string,id:number)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.delete('aux/basket/'+ id,{headers: headers});
}

async function basketShareLink (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.post('aux/basket/sharing',data,{headers: headers});
}

async function getSharedBasket (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return apiFlowBackend.get('aux/basket/shared?basket_id='+data,{headers: headers});
}

async function SaveBasket (data:any,token:string)  {
    let headers =authorizedHeaders(token);    
    return apiFlowBackend.post('aux/basket/save',data,{headers: headers});
}

const flowBackEndService = {
    getBuckets,AddBasket,UpdateBasket,DeleteBasket,basketShareLink,getSharedBasket,SaveBasket
};

export default flowBackEndService;