import {BehaviorSubject, Subject} from 'rxjs';
import Subscribed from "./models/symbolData"

interface Buysell {
    model_open: boolean;
    data: Subscribed | null;
}
const subject = new BehaviorSubject<Subscribed>({
    ask: 0,
    ask_qty: 0,
    bid: 0,
    bid_qty: 0,
    buySellSymbol: "",
    ch: 0,
    chp: 0,
    description: "",
    dpr: "0-0",
    exchange: "",
    high_price: 0,
    low_price: 0,
    lp: 0,
    market_segment_id: "",
    oi: "0",
    oi_ch: "0",
    open_price: 0,
    option_type: "",
    prev_close_price: 0,
    short_name: "",
    token: "",
    type: "",
    volume: 0,
});

export const SpotService = {
    sendSpot: (data:Subscribed) => subject.next(data),
    getSpot: () => subject.asObservable()
};


// PEIO

let maxIoPE:number = 0
const subjectIoPE = new BehaviorSubject(0);
export const peIoService = {
    sendMaxIo: (data:number) => {
        if(data > maxIoPE){
            maxIoPE = data
            subjectIoPE.next(maxIoPE)
        }
    },
    getSubIo: () => subjectIoPE.asObservable(),
    resetIoPE: () => {
        maxIoPE = 0
        subjectIoPE.next(0)
    }
};

// CEIO

let maxIoCE = 0
const subjectIoCE = new BehaviorSubject(0);
export const ceIoService = {
    sendMaxIo: (data:number) => {
        if(data > maxIoCE){
            maxIoCE = data
            subjectIoCE.next(maxIoCE)
        }
    },
    getSubIo: () => subjectIoCE.asObservable(),
    resetIoCE: () => {
        maxIoCE = 0
        subjectIoCE.next(0)
    }
};

// PE

let maxVolumePE = 0
const subjectPE = new BehaviorSubject(0);
export const peService = {
    sendMax: (data:number) => {
        if(data > maxVolumePE){
            maxVolumePE = data
            subjectPE.next(maxVolumePE)
        }
    },
    getSub: () => subjectPE.asObservable(),
    resetVolumePE: () => {
        maxVolumePE = 0
        subjectPE.next(0)
    }
};

//   CE

let maxVolumeCE = 0
const subjectCE = new BehaviorSubject(0);
export const ceService = {
    sendMax: (data:number) => {
        if(data > maxVolumeCE){
            maxVolumeCE = data
            // console.log("mxCE",maxVolumeCE);
            subjectCE.next(maxVolumeCE)
        }
    },
    getSub: () => subjectCE.asObservable(),
    resetVolumeCE: () => {
        maxVolumeCE = 0
        subjectCE.next(0)
    }
};


//   BuySellService

let buySellData:Buysell = {model_open: false,data: null}
const subjectBuySell = new BehaviorSubject({...buySellData})
export const BuySellServices = {
    setBuySell: (data:Subscribed) => {
       const val:Buysell = {model_open: true,data: data}
        subjectBuySell.next({...val})
    },
    getSub: () => subjectBuySell.asObservable(),
    clearBuySell: () => {
        subjectBuySell.next({...buySellData})
    }
};


// layOut subServices
const layoutSub = new BehaviorSubject(null)
export const layoutSubServices = {
    setLayOut: (data:any) => {
        layoutSub.next(data)
    },
    getSub: () => layoutSub.asObservable(),
};


const OrederSub = new Subject()
export const OrderOMSFeedSub = {
    NotifyOrder: (data:any) => {
        OrederSub.next(data)
    },
    OrderListener: () => OrederSub.asObservable(),
};

const ResponceStatusSubject = new Subject();
export const ResponceStatusService = {
    sendRes: (data:any) => subject.next(data),
    getSub: () => subject.asObservable()
};


const BasketAddSub = new Subject()
export const basketSubServices = {
    setNewBasket: (data:any) => {
        BasketAddSub.next(data)
    },
    getSub: () => BasketAddSub.asObservable(),
};