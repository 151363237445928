import React, {useEffect,useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
// import {UDFCompatibleDatafeedBase} from "../../chart-datafeed/udf-compatible-datafeed-base";
import {DataFeedInstance} from "../../chart-datafeed/udf-compatible-datafeed";
import {SpotService} from "../../sub";
import {NumberColor} from "../common/number_color";
import {ExchangeSegmentMap} from "../common/number_color"
import {ParentStock} from '../../models/optionChain'
import {Quotes,TokenInfo} from '../../models/symbolData'
import Subscribed from '../../models/symbolData'

interface optionHeaderProps{
    data:ParentStock | null;
    darkLightClass:string;
}
const OptionChainHeader:React.FC<optionHeaderProps> =({data,darkLightClass})=>{
  
    const [ subData, setSubData ] = useState<Subscribed | null>(null);
    const dispatch = useDispatch();


    useEffect(() => {
        // if(subData) dispatch(setSportData(subData))
        if(subData) sendMessage();
    }, [subData])


    function sendMessage() {
        // send message to subscribers via observable subject
        SpotService.sendSpot(subData as Subscribed);
    }


    useEffect(() => {
        headerData()
        return () => {
            removeSub()
        }
    }, [data])

    let listenerGuid:string|undefined = undefined
    function headerData() {
        listenerGuid = `top-${data?.token}-${ExchangeSegmentMap[data?.exchange as string]}`
        DataFeedInstance?.tokenInfo({market_segment_id: ExchangeSegmentMap[data?.exchange as string] , token: Number(data?.token) }).then((tokenInfo:TokenInfo)=>{
            DataFeedInstance?.getQuotes([String(tokenInfo.symbol)],(quotes:Quotes[])=>{
                setSubData(quotes[0].v)
                // getlivePrice(quotes[0].v.lp)
            },()=>{})
        })
        
        DataFeedInstance?.subcscribeQuotesFromTokens([
            {market_segment_id: ExchangeSegmentMap[data?.exchange as string] , token: Number(data?.token) }],(quotes:Quotes[])=>{
            setSubData(quotes[0].v)
            // getlivePrice(quotes[0].v.lp)
        },listenerGuid)
    }

    function removeSub(){
        DataFeedInstance?.unsubscribeQuotes(listenerGuid)
    }


    if(subData==null){
        return(
            <div className='col-4 pl-4 py-2' style={{textAlign:'left'}}>
                <div>
                    <div className='topSymbolName dark_theme'>{data?.symbol}</div>
                    <div className='exchange'>{''}</div>
                </div>
                <div>
                    <div className='ltp'>{''}</div>
                    <div className='ltp'></div>
                    <div className='ltp'></div>
                </div>
            </div>
        )
    }
    else{
        return(
                    <div className='col-4 pl-4 py-2' style={{textAlign:'left'}}>
                        <div>
                            <div className={`topSymbolName ${darkLightClass}`}>{data?.symbol?data.symbol:''}</div>
                            <div className={`exchange ${darkLightClass}`}>{subData?subData.exchange:''}</div>
                        </div>
                        <div>
                            <div className={`ltp ${darkLightClass}`}>{subData?subData.lp:''}</div>
                            <div className='icon'><NumberColor number={subData?subData.ch:0} returnSign={true}/></div>
                            <div className='ltp1'><NumberColor number={subData?subData.ch:0}/> </div>
                            <div className='ltp1'><NumberColor number={subData?subData.chp:0} numFix={true} percentageAdd={true} isFiniteCheck={true} returnBracket={true}/></div>
                        </div>
                    </div>
            )
    }
}

export default OptionChainHeader