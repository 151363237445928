import { UDFCompatibleDatafeedBase } from './udf-compatible-datafeed-base';
import { Requester } from './requester';
import { OdinRequester } from './odin_requester';
import { InHouseRequester } from './inhouse_requester'; 
import { remoteConfigGetter } from "../utils/remoteConfig";

export let DataFeedInstance: UDFCompatibleDatafeed | null = null;

export class UDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
    constructor(
        odin_url: string,
        auth_token: string,
        inhouse_url: string,
        logout_function: () => void
    ) {
        const requester = new Requester(
            {
                'Authorization': "Bearer " + auth_token, 
                "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
                "X-Stage": String(process.env.REACT_APP_ENV), 
                'x-api-key': String(process.env.REACT_APP_VORTEX_KEY)
            },
            logout_function
        );

        const inHouseRequester = new InHouseRequester(
            {
                'Authorization': "Bearer " + auth_token, 
                "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
                "X-Stage": String(process.env.REACT_APP_ENV), 
                'x-api-key': String(process.env.REACT_APP_VORTEX_KEY)
            },
            logout_function
        );

        const odinRequester = new OdinRequester(
            {
                'x-api-key': remoteConfigGetter("x_api_key").asString(), 
                'Authorization': "Bearer " + auth_token, 
                'Content-Type': 'application/json',
            },
            logout_function
        );

        // Call the superclass constructor with initialized requesters
        super(odin_url, inhouse_url, requester, odinRequester, inHouseRequester);

        // Assign this instance to the global variable
        DataFeedInstance = this;
    }
}
