import axios,{ AxiosRequestHeaders } from 'axios';
import {remoteConfigGetter} from "../utils/remoteConfig";
import {loginEvent, setEmailAndMobile, setOtpData,setQrData} from "../slices/authSlice";
import {toast} from "react-toastify";
import authService from "./authService";
import {ResponceStatusService} from "../sub";

const globalHeaders:AxiosRequestHeaders =  {
    'Content-Type': 'application/json',
    "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
    "X-Stage": String(process.env.REACT_APP_ENV),
    'x-api-key': String(process.env.REACT_APP_VORTEX_KEY)
}
const apiFlowBackend = axios.create({
    baseURL: remoteConfigGetter('inhouse_base_url_prod').asString(),
    headers: globalHeaders
});

const waveBackend = axios.create({
    baseURL: process.env.REACT_APP_VORTEX_URL,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': String(process.env.REACT_APP_VORTEX_KEY)
    },
});

function authorizedHeaders(token:string){
    return {...globalHeaders,'Authorization': `Bearer ${token}`}
}

async function flowLogin2fo (data:any)  {
    return await apiFlowBackend.post('aux/authentication/login',data);
}

async function verifyMfa (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/inhouse/verifyMfa',data,{headers: headers});
}

async function sendTOpt (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/sendTotpSms',data,{headers: headers});
}

async function getTOtpData (token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.get('aux/authentication/totp',{headers: headers});
}

async function update2faPref (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.put('aux/authentication/update2faPref',data,{headers: headers});
}


// FORGET PASSWORD 4 API

async function forgotPasswordOtpRequest (data:any)  {
    let headers =  globalHeaders;
    return await apiFlowBackend.post('aux/authentication/forgot',data,{headers: headers});
}

async function forgotPasswordResendOTP (data:any)  {
    let headers =  globalHeaders;
    return await apiFlowBackend.post('aux/authentication/resendForgotOtp',data,{headers: headers});
}

async function verifyForgotOtp (data:any)  {
    let headers =  globalHeaders;
    return await apiFlowBackend.post('aux/authentication/verifyForgotOtp',data,{headers: headers});
}

async function resetForgotPassword (data:any)  {
    let headers =  globalHeaders;
    return await apiFlowBackend.post('aux/authentication/resetForgotPassword',data,{headers: headers});
}

async function verifyMfaSSO (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/submitMfa',data,{headers: headers});
}

async function refreshLogin (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/refreshLogin',data,{headers: headers});
}

async function refreshLoginOtpSend (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/refreshLoginOtp',data,{headers: headers});
}

async function SendUserId (data:any)  {
     let headers =  globalHeaders;
    return await apiFlowBackend.post('aux/authentication/forgotUserId',data,{headers: headers});
}

async function getRedirecUrl (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('aux/authentication/getRedirecUrl',data,{headers: headers});
}

async function getTotalMargin (data:any,token:string)  {
    let headers =authorizedHeaders(token);
    return await apiFlowBackend.post('margins/basket',data,{headers: headers});
}

async function getApplicationDetails(data:any){
    let headers =  globalHeaders;
    return await apiFlowBackend.get('aux/authentication/application/'+data.applicationId,{headers: headers});
}
async function getQrImage (data:any){
    return await apiFlowBackend.post('aux/authentication/qrImage',data,{});
}

async function getOptionUserPreferences (formValues:any,token:string){
    var headers = {...globalHeaders,'Authorization': "Bearer "+token}
    return await apiFlowBackend.get('aux/user/preferences',{headers: headers});

}
async function setUseroptionPreferences (formValues:any,token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    return await apiFlowBackend.put('aux/user/preferences',formValues,{headers: headers});
}

async function getHistoricalOHLC(token:string,segmentId:number,accesstoken:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+accesstoken};
    return await apiFlowBackend.get('aux/highLow?token='+token+"&market_segment_id="+segmentId,{headers: headers})
}

async function getFutures(formValues:any,token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'aux/futurechain',formValues,{headers: headers});
}

async function getStrategies(formValues:any,token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'strategies',formValues,{headers: headers});
}

async function getPayOff(formValues:any,token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    var aa = remoteConfigGetter('inhouse_base_url_prod').asString()
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'strategies/payoff',formValues,{headers: headers});
}

async function getAvailableFunds(token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    return apiFlowBackend.get('user/funds',{headers: headers});
}

async function getBuilderStrategies(formValues:any,token:string){
    var headers =  {'Content-Type': 'application/json','Authorization': "Bearer "+token};
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'strategies/build',formValues,{headers: headers});
}



apiFlowBackend.interceptors.response.use(undefined, err => {
         const error = err.response;
         if (error.status === 401) {
             ResponceStatusService.sendRes({type:'FLOW',status: 401})
         }
    });





function setUpSessionData(value:any,mfaType:any,dispatch:any,navigate:any,data:any) {
    let userData = value.data
    if(userData.status === 'success'){
        let newData = {...data,refreshToken: userData.refreshToken,mfaType: userData.mfa_type,mfaEnabled: userData.mfaEnabled}
        let dataSet = {data: newData,user_id: data.clientCode}
        dispatch(setOtpData(dataSet))
        toast.success(`OTP Verified`)
        const refreshData = { refreshToken: userData.refreshToken,ssoToken: userData.ssoToken,mfaType:userData.mfaType,deviceId: data.deviceId,tradingActive: userData.tradingActive}
        const userModify = { ...userData.data,...refreshData}

        // login event
        dispatch(setEmailAndMobile({mobile: null,email: userModify.email }))
        dispatch(loginEvent({mobile: null,email: userModify.email }))

        authService.saveUserSessionFlow(userModify,refreshData,dispatch)
        if(mfaType === 'otp') navigate("/setTOtp",{ replace: true})
        if(mfaType === 'totp')  navigate("/tvTerminal",{ replace: true});
    }
}

function setUpQrSessionData(value:any,dispatch:any,navigate:any,data:any) {
    let userData = value
    if(userData.status === 'success'){
        let newData = {...data,refreshToken: userData.refreshToken,mfaType: data.stateData.mfaType,mfaEnabled: userData.mfaEnabled}
        console.log(newData,"newData")
        let dataSet = {data: newData,user_id: userData.data.clientCode}
        console.log(dataSet,"dataSet")
        dispatch(setQrData(dataSet))
        const refreshData = { refreshToken: userData.refreshToken,ssoToken: userData.ssoToken,mfaType:data.stateData.mfaType,deviceId: data.stateData.deviceId,tradingActive: userData.tradingActive}
        const userModify = { ...userData.data,...refreshData}

        // login event
        dispatch(setEmailAndMobile({mobile: null,email: userModify.email }))
        dispatch(loginEvent({mobile: null,email: userModify.email }))

        authService.saveUserSessionFlow(userModify,refreshData,dispatch)
        toast.success(`Logged In Successfully`)
        navigate("/tvTerminal",{ replace: true});
    }
}
const flowBackEndServices2 = {
    verifyMfa,flowLogin2fo,sendTOpt,getTOtpData,setUpSessionData,update2faPref,
    forgotPasswordOtpRequest,forgotPasswordResendOTP,verifyForgotOtp,resetForgotPassword,verifyMfaSSO,
    refreshLogin,refreshLoginOtpSend,SendUserId,getRedirecUrl,getTotalMargin,getApplicationDetails,getQrImage,setUpQrSessionData,getOptionUserPreferences,setUseroptionPreferences
    ,getHistoricalOHLC,getFutures,getStrategies,getPayOff,getAvailableFunds,getBuilderStrategies
};

export default flowBackEndServices2;
