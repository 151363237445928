import type { Alerts } from "../AlertTools";
// import { ReactComponent as InfoIcon } from "../../../assets/icons/info-circle2.svg";
import "./create-or-edit.scss";

type CraeteOrEditProbs = {
  alerts: Omit<Alerts, "all-alerts">;
};

const CraeteOrEdit: React.FC<CraeteOrEditProbs> = ({ alerts }) => {
  return (
    <div className="create-or-edit">
      <span className="me-2">
        {alerts === "create-alerts" ? "Creating New Alert" : "Edit Alert"}
      </span>
      {/* <InfoIcon className="mb-1 create-or-edit"/> */}
    </div>
  );
};

export default CraeteOrEdit;
