import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import store from "./store";
import App from './App';
import ProdRedirection from './components/ProdRedirection/ProdRedirection';
import './index.css';
import { datadogRum } from '@datadog/browser-rum';

// Initialize DataDog RUM
datadogRum.init({
    applicationId: '778a52c3-6713-42f0-af19-0fe4e86b9129',
    clientToken: 'pub4225b131f419017e6a89a7b27ea26696',
    site: 'datadoghq.eu',
    service: 'tv-terminal',
    env: process.env.REACT_APP_ENV || "development",
    version: `${process.env.REACT_APP_ENV}:${process.env.REACT_APP_COMMIT_VERSION || 'unknown'}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event:any, context:any) => {
        // Collect RUM resource's response headers
        if (
            event.type === 'resource' &&
            event.resource.type === 'fetch' &&
            context?.requestInput?.origin === "https://vortex-api.rupeezy.in"
        ) {
            if (context.response && context.response.headers) {
                event.context.responseHeaders = Object.fromEntries(
                    context.response.headers.entries()
                );
            }
        }
        return true;
    },
});

// Render the application
const rootElement = document.getElementById("root");
if (!rootElement) {
    throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            {process.env.REACT_APP_ENV === "staging" ? <ProdRedirection /> : null}
            <App />
        </Provider>
    </React.StrictMode>
);
