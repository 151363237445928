import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import authService from "../../services/authService";
import { useNavigate } from 'react-router-dom';
import { TVChartContainer } from "../TVChartContainer";
import { ChartBuySell } from "../ChartBuySell/index";
import { Charges } from '../Charges';
import NewOptionModel from "../NewOptionChain/NewOptionModel";
import FutureChainModel from "../FutureChain/FutureChainModel";
import { OpenOptionModel as OpenModel, OpenNewOptionModel as OpenNewModel, OpenFutureChain } from "../../slices/optionChainSlice";
import DraggableWidget from "../DraggableWidget/DraggableWidget";
import { HandleSocket } from "../common/handleSocket";
import BasketModel from "../BasketOrder/basketModel";
import OptionStategyModel from "../OptionStrategy/optionStategyModel";
import AlertTools from "../Alerts/AlertTools"
import { BrokerApi } from "../../chart-brokerapi/broker";
import { messaging } from "../../utils/remoteConfig";
import { getToken } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
import { remoteConfigGetter } from "../../utils/remoteConfig";
import WalkThrough from './WalkThrough';
import { setSharedBasketId, basketShareEvent, BasketModelToggle, OpenPublicBasket } from "../../slices/basketOrderSlice";
import { currentUser } from "../../slices/authSlice"
import EditGGT from './GTT/EditGtt';
import type { RootState } from '../../store';

import ReCreateGTT from './GTT/Re-Create-GTT';
import { setRecreateNewGTTOrderWithId } from '../../slices/gttSlices';

import useLiveQuotes from '../../hooks/socket/useLiveQuotes'; // TODO:REMOVE THIS


const getOption = (state: any) => ({
  model_open: state.optionChain.model_open,
  option_key: state.optionChain.option_key,
  socket_open: state.optionChain.socket_open,
  user: state.authUser.user,
  basket_id: state.authUser.sharedBasketId,
  new_model_open: state.optionChain.new_model_open,
  open_future_chain: state.optionChain.open_future_chain,
  future_key: state.optionChain.future_key
});

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(currentUser);

  useEffect(() => {
    HandleSocket(true);
    return () => {
      // Cleanup
    };
  }, []);

  useEffect(() => {
    const basketId = sessionStorage.getItem("public_basket_id");
    if (basketId && basketId !== "") {
      dispatch(basketShareEvent());
      dispatch(setSharedBasketId(basketId));
      dispatch(BasketModelToggle(true));
      dispatch(OpenPublicBasket(true));
    }
  }, []);

  useEffect(() => {
    let ack = localStorage.getItem("acknowledge_disclosure");
    if (ack !== null && ack !== "false") {
      handleLogout();
    }
  }, []);

  const handleLogout = async () => {
    if (messaging !== null && Notification.permission === 'granted' && await isSupported()) {
      try {
        const token = await getToken(messaging, { vapidKey: remoteConfigGetter("vapidkey").asString() });
        if (token) {
          await BrokerApi.instance?.unSubscribeFcmByToken(token);
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      } catch (error) {
        const token = await getToken(messaging, { vapidKey: remoteConfigGetter("vapidkey").asString() });
        if (token) {
          await BrokerApi.instance?.unSubscribeFcmByToken(token);
          return token;
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      }
    }
    dispatch(authService.logout());
    navigate("/", { replace: true });
  };

  const OpenOptionModel = (key: string) => {
    const data = { model_open: true, option_key: key };
    dispatch(OpenModel(data));
  };

  const OpenNewOptionModel = (key: string) => {
    const data = { new_model_open: true, option_key: key };
    dispatch(OpenNewModel(data));
  };

  const OpenFutureChainModel = (key: string) => {
    const data = { open_future_chain: true, future_key: key };
    dispatch(OpenFutureChain(data));
  };

  const handleLogoutCallBack = useCallback(() => {
    handleLogout();
  }, []);

  const OpenOptionModelCallBack = useCallback((val: string) => {
    OpenOptionModel(val);
  }, []);

  const OpenNewOptionModelCallBack = useCallback((val: string) => {
    OpenNewOptionModel(val);
  }, []);

  const OpenFutureChainModelCallBack = useCallback((val: string) => {
    OpenFutureChainModel(val);
  }, []);

  // const OpenNewGGTModelCallBack = useCallback((val: string) => {
  //   OpenNewGGTModel(val);
  // }, []);

  // const OpenNewGGTModelCallBack = useCallback((val: string) => {
  //   OpenNewGGTModel(val);
  // }, []);

  return (
    <div>
      <div className="App">
        <TVChartContainer OpenFutureModel={OpenFutureChainModelCallBack} data={user} logoutFunction={handleLogoutCallBack} OpenOptionModel={OpenOptionModelCallBack} OpenNewOptionModel={OpenNewOptionModelCallBack} 
        // OpenNewGGTModel={OpenNewGGTModelCallBack}
         />
        <ChartBuySell token={user?.access_token} />
        <Charges />
        <NewOptionRender data={user} />
        <RenderFutureChain />
        <RenderGGT />
        <RecraeteGGT />
        <BasketRenderOne />
        <AlertRender/>
        <OptionStrategyRender />
        <DraggableWidget />
        <WalkThrough />
      </div>
    </div>
  );
};

export default Dashboard;

type NewOptionRenderProps = {
  data: any; // Replace `any` with actual type for user data
};

const NewOptionRender: React.FC<NewOptionRenderProps> = ({ data }) => {
  const new_model_open = useSelector((state: any) => state.optionChain.new_model_open, shallowEqual);
  const optionData = useSelector(getOption, shallowEqual);

  useEffect(() => {}, [new_model_open]);

  return new_model_open ? <NewOptionModel optionData={optionData} userdata={data} /> : null;
};

const RenderFutureChain: React.FC = () => {
  const open_future_chain = useSelector((state: any) => state.optionChain.open_future_chain, shallowEqual);
  const futureData = useSelector(getOption, shallowEqual);

  useEffect(() => {}, [open_future_chain]);

  return open_future_chain ? <FutureChainModel futureData={futureData} /> : null;
};

const RenderGGT: React.FC = () => {
  const open_GGT = useSelector((state: any) => state.gttAlerts.isOpenModal, shallowEqual);

  // const {recreateNewGTTOrderWithId:currentId,gttOrders: allOrders} = useSelector(
  //   (state: RootState) => state.gttAlerts
  // );

  // const targetOrder = allOrders.find((order) => order.id === currentId);

  // const token = targetOrder?.token || 0;
  // const exchange = targetOrder?.exchange?.split("_")[0].toLowerCase();
  // console.log("exchange",currentId,token)

  // const x = useLiveQuotes(null, null);

  useEffect(() => {}, [open_GGT]);

  return open_GGT !== '0' ? <EditGGT /> : null;
};

const RecraeteGGT: React.FC = () => {
  const open_GGT = useSelector((state: RootState) => state.gttAlerts.recreateNewGTTOrderWithId, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {}, [open_GGT]);
  return open_GGT !== '0' ? <ReCreateGTT CloseGttModal={()=>dispatch(setRecreateNewGTTOrderWithId('') )} /> : null;
};

const BasketRenderOne: React.FC = () => {
  const model_open_basket = useSelector((state: any) => state.basketOrder.model_open_basket, shallowEqual);

  useEffect(() => {}, [model_open_basket]);

  return model_open_basket ? <BasketModel /> : null;
};

const OptionStrategyRender: React.FC = () => {
  const model_open_option_strategy = useSelector((state: any) => state.optionStrategy.model_open_option_strategy, shallowEqual);

  useEffect(() => {}, [model_open_option_strategy]);

  return model_open_option_strategy ? <OptionStategyModel /> : null;
};

const AlertRender: React.FC = () => {
  const model_open_alert = useSelector((state: any) => state.alert.model_open_alert, shallowEqual);

  useEffect(() => {}, [model_open_alert]);

  return model_open_alert ? <AlertTools /> : null;
};