import { Chart,StudyTemplates } from "../models/charts"

export class SaveLoad {
    private _charts:Chart[] = [];
    private _studyTemplates: StudyTemplates[] = [];
    private _drawingTemplates: StudyTemplates[] = [];
    private _inHouseUrl: string;
    private _auth_token: string;
    private _getCharts: Promise<void>;
    private _getStudyTemplates: Promise<StudyTemplates[]>;
    private _getDrawingTemplates: Promise<StudyTemplates[]>;
    constructor(inHouseUrl:string,auth_token:string){
        this._charts = []
        this._studyTemplates = []
        this._drawingTemplates = []
        this._inHouseUrl = inHouseUrl+"aux/"
        this._auth_token = auth_token
        this._getCharts = this._getAllCharts()
        this._getStudyTemplates = this._getAllStudyTemplates()
        this._getDrawingTemplates = this._getAllDrawingTemplates()
    }

    init(){

    }
    async getAllCharts() {
        return this._getAllCharts().then((_)=>{
            return Promise.resolve(this._charts);
        })
        
    }

    async _getAllCharts(){
        return this._inhouseRequest('chartsList').then((response:Chart[])=>{
            this._charts = (response || [])
            return Promise.resolve()
        })
    }

    async removeChart(id:number) {
        for (var i = 0; i < this._charts.length; ++i) {
            if (this._charts[i].id.toString() === id.toString()) {
                
                return this._inhouseRequest('charts/'+id.toString(),undefined,undefined,"DELETE").then((_)=>{
                    this._charts.splice(i, 1);
                    return Promise.resolve()
                })
            }
        }

        return Promise.reject();
    }

    async saveChart(chartData:Chart) {
        chartData.timestamp = new Date().valueOf();
        return this._inhouseRequest('charts', chartData,undefined ,"POST").then((response:Chart)=>{
            var index:number = this._charts.findIndex((t:Chart)=> t.id == chartData.id)
            if( index == -1 ){
                window.GtmPusher({
                    event: 'tv-chart-layout-created'
                })
                this._charts.push(response);
            }else{
                this._charts[index] = response
            }
            return Promise.resolve(response.id);
        }).catch((error)=> console.log(error))
    }

    async getChartContent(id:number) {
        return this._inhouseRequest('charts/'+id.toString(),undefined,undefined,"GET").then((response:Chart)=>{
            return Promise.resolve(response.content);
        })
    }

    removeStudyTemplate(studyTemplateInfo:StudyTemplates) {
        console.error("remove study template", studyTemplateInfo)
        var id = studyTemplateInfo.name?.split("|")[0]
        for (var i = 0; i < this._studyTemplates.length; ++i) {
            if (this._studyTemplates[i].id?.toString() === id?.toString()) {
                
                return this._inhouseRequest('studyTemplates/'+id?.toString(),undefined,undefined,"DELETE").then((_)=>{
                    this._studyTemplates.splice(i, 1);
                    return Promise.resolve()
                })
            }
        }

        return Promise.reject();
    }

    getStudyTemplateContent(studyTemplateData:StudyTemplates) {
        for (var i = 0; i < this._studyTemplates.length; ++i) {
            if (this._studyTemplates[i].name === studyTemplateData.name) {
                return Promise.resolve(this._studyTemplates[i].content);
            }
        }

        return Promise.reject();
    }

    saveStudyTemplate(studyTemplateData:StudyTemplates) {
        console.error("study template", studyTemplateData)

        studyTemplateData.timestamp = new Date().valueOf();
        studyTemplateData.meta_info = JSON.stringify(studyTemplateData.meta_info)
        return this._inhouseRequest('studyTemplates', studyTemplateData,undefined ,"POST").then((response)=>{
            response.name = response.id.toString()+"|"+response.name
            this._studyTemplates.push(response);
            return Promise.resolve(response.id);
        })
    }

    getAllStudyTemplates() {
        return this._getStudyTemplates.then((_)=>{
            return Promise.resolve(this._studyTemplates);
        })

    }

    _getAllStudyTemplates(){
        return this._inhouseRequest('studyTemplates').then((response:StudyTemplates[])=>{
            this._studyTemplates = (response.map((aa:StudyTemplates)=>{
                aa.name = aa.id?.toString()+"|"+aa.name
                aa.meta_info = JSON.parse(aa.meta_info as string)
                return aa
            }) || [])
            return Promise.resolve(this._studyTemplates)
        })
    }

    _getAllDrawingTemplates(){
        return this._inhouseRequest('drawingTemplates').then((response:StudyTemplates[])=>{
            this._drawingTemplates = (response.map((aa:StudyTemplates)=>{
                aa.name = aa.name
                aa.content = aa.content
                return aa
            }) || [])
            return Promise.resolve(this._drawingTemplates)
        })
    }
    removeDrawingTemplate ( templateName:string) {
        for (var i = 0; i < this._drawingTemplates.length; ++i) {
            if (this._drawingTemplates[i].name === templateName) {
                return this._inhouseRequest(`drawingTemplates/${this._drawingTemplates[i].id}`,undefined,undefined,"DELETE").then((_)=>{
                    this._drawingTemplates.splice(i, 1);
                    return Promise.resolve();
                }).catch((err)=>{return Promise.reject(err)})
            }
        }

        return Promise.reject();
    }

    loadDrawingTemplate ( templateName:string) {
        for (var i = 0; i < this._drawingTemplates.length; ++i) {
            if (this._drawingTemplates[i].name === templateName) {
                return Promise.resolve(this._drawingTemplates[i].content);
            }
        }

        return Promise.reject();
    }

    saveDrawingTemplate (templateName:string, content:string) {
        // studyTemplateData.meta_info = JSON.stringify(studyTemplateData.meta_info)
        var payload:StudyTemplates = {
            name: templateName, 
            content: content,
            id:0
        }
        for (var i = 0; i < this._drawingTemplates.length; ++i) {
            if (this._drawingTemplates[i].name === templateName) {
                payload.id = this._drawingTemplates[i].id
                break;
            }
        }
        return this._inhouseRequest('drawingTemplates', payload,undefined ,"POST").then((response)=>{
            response.name = payload.name
            response.id = response.drawing_template_id
            for (var i = 0; i < this._drawingTemplates.length; ++i) {
                if (this._drawingTemplates[i].name === templateName) {
                    this._drawingTemplates.splice(i, 1);
                    break;
                }
            }
            this._drawingTemplates.push(response);
            return Promise.resolve();
        })
    }

    getDrawingTemplates () {
        return this._getDrawingTemplates.then((_)=>{
            return Promise.resolve(this._drawingTemplates.map(function(template:StudyTemplates) {
                return template.name;
            }));
        })
    }
    private async _inhouseRequest(urlPath: string, body?: StudyTemplates, params?: Record<string, string>, method: string = "GET") {
        const headers = new Headers({
            "Authorization": `Bearer ${this._auth_token}`,
            "x-api-key": process.env.REACT_APP_VORTEX_KEY || "",
        });

        const options: RequestInit = {
            headers,
            method,
        };

        if (body !== undefined && method !== "GET") {
            options.body = JSON.stringify(body);
        }

        const url = new URL(`${this._inHouseUrl}${urlPath}`);
        if (params) {
            url.search = new URLSearchParams(params).toString();
        }

        const response = await fetch(url.toString(), options);
        const responseText = await response.text();
        return JSON.parse(responseText);
    }

}