import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface AlertNameInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  error?: string; // Optional error message
}

const AlertNameInput: React.FC<AlertNameInputProps> = ({
  label,
  value,
  onChange,
  disabled,
  error,
}) => {
  return (
    <div className="">
      <Form.Label
        className="text-start w-100 pt-4 text-color font-16"
        htmlFor="alertName"
      >
        <strong className="text-color">{label}</strong> (Max. 20 characters)
      </Form.Label>

      <InputGroup>
        <Form.Control
          id="alertName"
          className={`create-or-edit-dropdown alert-name-input pe-4 ${disabled ? 'cursor-not-allowed alerts-disabled' : ''} ${error ? 'is-invalid' : ''}`}
          aria-describedby="Alert Name"
          placeholder="Enter alert name here"
          maxLength={20}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoComplete="off"
        />
      </InputGroup>

      {error && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export default AlertNameInput;
