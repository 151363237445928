import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";
import { RootState } from "../../../store";

// Use the types defined above
type StockData = {
  scripToken: number;
  marketSegmentId: number;
  symbol: string;
  securityDesc: string;
  instrumentName: string;
  series: string;
  eligibility: number;
  tick: number;
  hasOptionChain: boolean;
  has_icon: boolean;
  optionType?: 'PE' | 'CE';
  strikePrice?: number;
  expYYYYMMDD?: string
};

export type AlertData = {
  id: number;
  name: string;
  clientCode: string;
  token: number;
  marketSegmentId: number;
  triggerValue: number;
  property: 'LTP' | 'VOLUME' | 'AVGPRICE';
  condition: 'LE' | 'EQ' | 'GE';
  note: string;
  status: "Active" | "InActive";
  expiry: string;
  lastExecutedAt: string;
  isExecuted: boolean;
  source: string;
  createdAt: string;
  updatedAt: string;
  DeletedAt: string | null;
  stock_data: StockData;
};

type ApiResponse = {
  data: AlertData[];
  message: string;
  status: "success" | "failure";
};


const useAlerts = () => {
  const token = useSelector((state: RootState) => state.authUser.user?.access_token);

  const [activeAlerts, setActiveAlerts] = useState<AlertData[] | null>(null);
  const [inactiveAlerts, setInactiveAlerts] = useState<AlertData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAlerts = useCallback(async (forceRefresh: boolean = false) => {
    if (!forceRefresh && activeAlerts !== null && inactiveAlerts !== null) {
      return {
        activeAlerts: activeAlerts,
        inactiveAlerts: inactiveAlerts,
      };
    }
  
    if (!token) {
      setError("No token available");
      setLoading(false);
      return null;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await axios.get<ApiResponse>(
        `${FLOW_BACKEND_BASE_URL}aux/alerts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.status === "success") {
        const data = response.data.data || [];
  
        // Separate active and inactive alerts
        const active = data.filter((alert) => !alert.isExecuted);
        const inactive = data.filter((alert) => alert.isExecuted);
  
        setActiveAlerts(active);
        setInactiveAlerts(inactive);
  
        return {
          activeAlerts: active,
          inactiveAlerts: inactive,
        };
      } else {
        setError("Failed to fetch alerts");
        setActiveAlerts([]);
        setInactiveAlerts([]);
        return null;
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          setError(`Error: ${err.response.status} - ${err.response.data.message}`);
        } else if (err.request) {
          setError("No response received from the server");
        } else {
          setError(`Error: ${err.message}`);
        }
      }
      setActiveAlerts([]);
      setInactiveAlerts([]);
      return null;
    } finally {
      setLoading(false);
    }
  }, [token]);
  

  // Initial fetch on component mount
  useEffect(() => {
    fetchAlerts(true); // Force initial fetch
  }, [fetchAlerts]);

  // Manual refetch method
  const refetch = useCallback((options?: { 
    forceRefresh?: boolean;
    onSuccess?: (data: { activeAlerts: AlertData[], inactiveAlerts: AlertData[] }) => void;
    onError?: (error: string) => void;
  }) => {
    fetchAlerts(options?.forceRefresh ?? true) // Default to true if not specified
      .then((data) => {
        if (data) {
          options?.onSuccess?.(data);
        } else {
          options?.onSuccess?.({activeAlerts: [],inactiveAlerts:[]})
          // options?.onError?.("No data returned");
        }
      })
      .catch((error) => {
        options?.onError?.(error.message || "Refetch failed");
      });
  }, [fetchAlerts]);

  return { 
    activeAlerts, 
    inactiveAlerts, 
    loading, 
    error, 
    refetch 
  };
};

export default useAlerts;