import { AlertData } from "../../hooks/api/get/getAlerts";

export const propertyToString = (property: AlertData["property"]) => {
  if (property === "LTP") {
    return "Last Trade Price";
  }

  if (property === "VOLUME") {
    return "Volume";
  }

  if (property === "AVGPRICE") {
    return "Average Trade Price";
  }

  return "";
};

// export const propertyToStringForEdit = (property: AlertData["property"]) => {
//   if (property === "LTP") {
//     return "Last Trade Price";
//   }

//   if (property === "VOLUME") {
//     return "Volume";
//   }

//   if (property === "AVGPRICE") {
//     return "Average Trade Price";
//   }

//   return "";
// };