// 1. External Imports
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

// 2. Assets / Icons
import { ReactComponent as CloserIcon } from "../../../../assets/images/close-square2.svg";

// 3. Helper Functions
import { assetClasspicker} from "../gtt.helper";
import useStockDetails from "../../../../hooks/api/get/getScripDetails";
import { ExchangeTypes, getInstrumentType, GttBodyTabsSelectors } from "../GTTBody/gtt.body.selector.helper";
import { OptionTimeframe, TradeDirection } from "../gttTypes";

// 4. Components
import GttHeaders from "../GttHeader";
import GTTTabs from "../GTTTabs";
import GttBody from "../GTTBody";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { LiveQuotes } from "../../../../models/gttOrders";
import { DataFeedInstance } from "../../../../chart-datafeed/udf-compatible-datafeed";
import { Quotes } from "../../../../models/symbolData";
import nextId from "react-id-generator";

// 5. Types (for props)
export type GTTProbs = {
  CloseGttModal: () => void;  // Function to close the GTT modal
};

// SCSS
import '../common/scss/gtt.scss'

const ReCreateGGT: React.FC<GTTProbs> = ({ CloseGttModal }) => {
  // Fetch the valid timeframes based on selected assetClass and securityType
  const [selectedToken, setSelectedToken] = useState<number | null>(null);
  const [conditionalWidth,setConditionalWidth] = useState<boolean>(false)
  const [selectedMarketSegmentId, setSelectedMarketSegmentId] = useState<
    number | null
  >(null);


  // const gttTabsArray = GttBodyTabsSelector({
  //   assetClass: assetClasspicker(stockDetails?.instrumentName ?? ""),
  //   securityType: securityTypepicker(+probs.segMentId),
  // }); // TODO: we will get the probs and we will calcuate the probs based on this

  const [ltpChChp, setLtpChChp] = useState<LiveQuotes>({
    ltp: 0,
    ch: 0,
    chp: 0,
  });

  const [tradeDirection, setTradeDirection] = useState<TradeDirection>("Sell");
  const [currentGttTab, setCurrentGttTab] = useState<OptionTimeframe>();


  const {recreateNewGTTOrderWithId:currentId,gttOrders: allOrders} = useSelector(
    (state: RootState) => state.gttAlerts
  );

  const targetOrder = allOrders.find((order) => order.id === currentId);
  const token = targetOrder?.token || 0;
  const exchange = getInstrumentType(targetOrder?.exchange as ExchangeTypes);

  // Custom hook for stock details
  const { stockDetails } = useStockDetails(token, exchange || "");

  const gttTabsArray = GttBodyTabsSelectors({
    marketSegmentId: stockDetails?.marketSegmentID ?? "",
    choserString: stockDetails?.instrumentName ?? "",
  });

  // Initialize selectedToken and selectedMarketSegmentId from targetOrder
  useEffect(() => {
    if (targetOrder?.token && targetOrder?.market_segment_id) {
      setSelectedToken(targetOrder.token);
      setSelectedMarketSegmentId(targetOrder.market_segment_id);
      setTradeDirection(targetOrder.transaction_type === 'BUY' ? 'Buy' : 'Sell');
    }
  }, [targetOrder]);

  useEffect(() => {
    if (gttTabsArray.length > 0 && !currentGttTab) {
      setCurrentGttTab(gttTabsArray[0]);
    }
  }, [gttTabsArray, currentGttTab]);


  // if (!currentGttTab) {
  //   return null; // or a loading state if needed
  // }

  // Update current GTT tab based on selected option
  const currentGttSwitcher = (newGttTab: OptionTimeframe) => {
    setCurrentGttTab(newGttTab);
  };

  // Toggle between "Buy" and "Sell"
  const toggleTradeDirection = () => {
    setTradeDirection((prev) => (prev === "Buy" ? "Sell" : "Buy"));
  };

   // Data subscription useEffect Form Web Socket
   useEffect(() => {
    let listenerGuid: string | undefined;

    // Unsubscribe previous subscription if exists
    const cleanup = () => {
      if (listenerGuid) {
        DataFeedInstance?.unsubscribeQuotes(listenerGuid);
      }
    };

    // Subscribe to new quotes if token and market segment are available
    if (selectedToken && selectedMarketSegmentId) {
      listenerGuid = nextId();

      DataFeedInstance?.subcscribeQuotesFromTokens(
        [{ market_segment_id: selectedMarketSegmentId, token: selectedToken }],
        (quotes: Quotes[]) => {
          const selectedSegment = quotes[0].v;
          setLtpChChp({
            ltp: selectedSegment.lp,
            ch: selectedSegment.ch,
            chp: selectedSegment.chp,
          });
        },
        listenerGuid
      );
    }

    // Cleanup subscription on component unmount or dependency change
    return cleanup;
  }, [selectedToken, selectedMarketSegmentId]);


  if (!targetOrder || !token || !exchange || !stockDetails || !currentGttTab) {
    return null;
  }

  return (
    <Draggable handle=".handle_b" bounds="parent" allowAnyClick>
      <div style={{maxWidth:conditionalWidth?"960px":"483px"}} className="custom-order-dialog_basket currentBg">
        <div
          style={{ height: 80 }}
          className="container optionStartegyHead handle_b draggable-border"
        >
          {/* GTT Header */}
          <GttHeaders
            toggleTradeDirection={toggleTradeDirection}
            tradeDirection={tradeDirection}
            exchnage={exchange.toUpperCase()}
            scriptName={stockDetails.symbol}
            securityDesc={stockDetails.securityDesc}
            ltp={ltpChChp.ltp.toString()}
            ch={ltpChChp.ch}
            chp={ltpChChp.chp.toString()}
          />

          {/* Close Modal Button */}
          <div className="flex c-pointer" onClick={CloseGttModal}>
            <CloserIcon />
          </div>
        </div>

        {/* Tab Selector Component */}
        {gttTabsArray.length > 0 && (
          <GTTTabs
            optionTimeframe={gttTabsArray}
            selected={currentGttTab}
            toggleOptionTimeframe={currentGttSwitcher}
          />
        )}

        <GttBody
          setConditionalWidth={()=>setConditionalWidth(!conditionalWidth)}
          isCondtionalWidthNeeded={conditionalWidth}
          currentGTTTab={currentGttTab}
          token={`${token}`}
          exchnage={targetOrder?.exchange.toUpperCase()}
          ltp={ltpChChp.ltp.toString()}
          tradeDirection={tradeDirection}
          assetClass={assetClasspicker(stockDetails?.instrumentName ?? "")}
          securityType={"Direct"}
          tick={stockDetails?.tick ?? 0}
          lotSize={stockDetails?.lotSize ?? 0}
          CloseGttModal={CloseGttModal}
          securityDesc={stockDetails.securityDesc}
        />
      </div>
    </Draggable>
  );
};

export default ReCreateGGT;
