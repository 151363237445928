import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

import {
  GTTFormState,
  GTTOrderPayload,
  GTTOrderResponse,
  TradeDirection,
} from "../../../components/DashBoard/GTT/gttTypes";

import {
  tradeDirectionPayload,
  OptionTimeframePayload,
  triggerTypePayload,
  targetOrderTypePayload,
  safeParseNumber,
} from "../../../components/DashBoard/GTT/gtt.helper";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";

const ALERTS_API_URL = `${FLOW_BACKEND_BASE_URL}trading/orders/gtt`;

// Improved custom hook with better error handling and type safety
const useCreateGTTOrder = (stockToken: string, exchange: string, tradeDirection: TradeDirection) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GTTOrderResponse | null>(null);

  const token = useSelector((state: RootState) => state.authUser.user?.access_token);


  // Separate method to construct payload to improve readability
  const constructPayload = (
    orderData: GTTFormState,
    restOfProbs: any
  ): GTTOrderPayload => {
    const basePayload: Partial<GTTOrderPayload> = {
      exchange: exchange,
      token: +stockToken,
      tag_ids: [],
    };

    if (orderData.triggerType === "Single") {
      return {
        ...basePayload,
        transaction_type: tradeDirectionPayload(tradeDirection),
        product: OptionTimeframePayload(restOfProbs.currentGTTTab),
        gtt_trigger_type: triggerTypePayload(orderData.triggerType),
        price: orderData.orderType === "Limit" 
          ? safeParseNumber(orderData.price) 
          : 0,
        trigger_price: safeParseNumber(orderData.triggerPrice),
        quantity: parseInt(orderData.quantity, 10),
        variety: targetOrderTypePayload(orderData.orderType ?? ""),
        stoploss: null,
        profit: null,
      } as GTTOrderPayload;
    }

    // OCO (One Cancels Other) Order payload
    return {
      ...basePayload,
      transaction_type: tradeDirectionPayload(tradeDirection),
      product: OptionTimeframePayload(restOfProbs.currentGTTTab),
      gtt_trigger_type: triggerTypePayload(orderData.triggerType),
      price: 0.0,
      trigger_price: 0.0,
      quantity: 0,
      variety: "",
      stoploss: {
        quantity: +orderData.quantity,
        price: orderData.orderType === "Limit"  
          ? safeParseNumber(orderData.price) 
          : 0.0,
        trigger_price: safeParseNumber(orderData.triggerPrice),
        variety: targetOrderTypePayload(orderData.orderType ?? ""),
      },
      profit: {
        quantity: safeParseNumber(orderData.targetQuantity),
        price: orderData.targetOrderType === "Limit" 
          ? safeParseNumber(orderData.targetPrice) 
          : 0.0,
        trigger_price: safeParseNumber(orderData.targetTriggerPrice),
        variety: targetOrderTypePayload(orderData.targetOrderType ?? "Market"),
      },
    } as GTTOrderPayload;
  };

  const createGTTOrder = useCallback(
    async (
      orderData: GTTFormState,
      restOfProbs: any
    ): Promise<GTTOrderResponse> => {
      // Reset state before new request
      setIsLoading(true);
      setIsError(false);
      setError(null);

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const payload = constructPayload(orderData, restOfProbs);

        const response = await fetch(ALERTS_API_URL, {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        });

        const responseData: GTTOrderResponse = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message || "Order creation failed");
        }

        setData(responseData);
        return responseData;
      } catch (err: unknown) {
        const errorMessage =
          err instanceof Error
            ? err.message
            : "Unexpected error during GTT order creation";

        setIsError(true);
        setError(errorMessage);

        // Consider logging the error or sending to error tracking service
        // console.error("GTT Order Creation Error:", errorMessage);

        throw new Error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [token, tradeDirection]
  );

  return {
    createGTTOrder,
    isLoading,
    isError,
    error,
    data,
  };
};

export default useCreateGTTOrder;