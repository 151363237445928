import { getErrorMessage, logMessage, } from '../utils/helpers';
import { HistoricalData,RequestParams,PeriodParams,BarData,Meta,Bars } from "../models/historical"
import { ResolveSymbolInfo } from '../models/symbolData';

export class HistoryProvider {
    private _datafeedUrl:string
    private _requester:any
    constructor(datafeedUrl:string, requester:any, headers:any='') {
        this._datafeedUrl = datafeedUrl;
        this._requester = requester;
    }

    //This function returns the historical data of candles 
    getBars(symbolInfo:ResolveSymbolInfo, resolution:string, periodParams:PeriodParams) {
        const requestParams:RequestParams = {
            symbol: String(symbolInfo.symbol),
            token: String(symbolInfo.odin_token),
            market_segment_id: Number(symbolInfo.odin_market_segment_id),
            resolution: resolution,
            from: periodParams.from,
            to: periodParams.to,
            first_data_request: periodParams.firstDataRequest,
            next_time: true,
        };
        if (periodParams.countBack) {
            requestParams.countback = periodParams.countBack;
        }
        if (symbolInfo.currency_code) {
            requestParams.currencyCode = symbolInfo.currency_code;
        }
        if (symbolInfo.unit_id) {
            requestParams.unitId = symbolInfo.unit_id;
        }
        if (requestParams.token == undefined || requestParams.token == 0 || requestParams.token == null || requestParams.token == "" || requestParams.token == "0") {
            return new Promise((resolve, reject) => {
                const meta:Meta = {
                    noData :true 
                }
                let data:Bars={
                    bars: [],
                    meta: meta,
                }
                resolve(data);
            });
        }
        return new Promise((resolve, reject) => {
            this._requester.sendRequest(this._datafeedUrl, 'data/history', requestParams)
                .then((response:HistoricalData) => {
                if (response.s !== 'ok' && response.s !== 'no_data') {
                    reject(response.s);
                    return;
                }
                const bars:BarData[] = [];
                const meta:Meta = {
                    noData: false,
                    // nextTime:0,
                };
                if (response.s === 'no_data') {
                    meta.noData = true;
                    // meta.nextTime = response.next_time;
                }
                else {
                    const volumePresent = response.v !== undefined;
                    const ohlPresent = response.o !== undefined;
                    var totalLastDayVolumeTillBar = 0
                    if(response.t && response.c && response.o && response.h && response.l && response.v){
                        for (let i = 0; i < response.t?.length; ++i) {
                            const barValue:BarData = {
                                time: response.t[i] * 1000,
                                close: Number(response.c[i]),
                                open: Number(response.c[i]),
                                high: Number(response.c[i]),
                                low: Number(response.c[i]),
                                volume:0,
                                totalLastDayVolumeTillBar:0
                            };
                            if (ohlPresent) {
                                barValue.open = Number(response.o[i]);
                                barValue.high = Number(response.h[i]);
                                barValue.low = Number(response.l[i]);
                            }
                            if (volumePresent) {
                                barValue.volume = Number(response.v[i]);
                                if( barValue.time >= bod()){
                                    totalLastDayVolumeTillBar = totalLastDayVolumeTillBar  + barValue.volume
                                }
                                
                            }
                            if( resolution != "1D" && resolution != "1W" && resolution != "1M" &&resolution != "1Y"){
                                barValue.totalLastDayVolumeTillBar = totalLastDayVolumeTillBar
                            }
                            bars.push(barValue);
                        }
                    }
                }
                let data:Bars={
                        bars: bars,
                        meta: meta,
                    }
                
                resolve(data);
            }).catch((reason:any) => {
                // tslint:disable-next-line:no-console
                logMessage(`HistoryProvider: getBars() failed, error=${getErrorMessage(reason)}`);
                reject(reason);
            });
        });
    }
}

function bod(){ //It returns the date and time of current day
    var now = new Date();
    var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var timestamp = startOfDay;
    return Number(timestamp)
}
