import { UpdateOrderPayload } from "../../../hooks/api/put/updateGttAlert";
import { AssetClasses, ConstructPayloadParams, GTTOrderPayload, OptionTimeframe, OrderExchangeType, OrderType, SecurityType, TradeDirection, TriggerMode } from "./gttTypes";

export const assetClasspicker = (instrumentName: string): AssetClasses => {
  if (["FUTSTK", "OPTSTK"].includes(instrumentName)) {
    return "Stocks";
  }

  return "Index";
};

export const securityTypepicker = (market_segment_id: number): SecurityType => {
  if ([1, 3].includes(market_segment_id)) {
    return "Direct";
  } else if (market_segment_id === 2) {
    return "Future";
  } else {
    return "Options";
  }
};

export function getInstrumentName(instName: string | null): string {
  let res = "";

  if (
    instName &&
    Object.values(OrderExchangeType).includes(instName as OrderExchangeType)
  ) {
    const exchangeType =
      OrderExchangeType[instName as keyof typeof OrderExchangeType];

    switch (exchangeType) {
      case OrderExchangeType.NSE_EQ:
        res = "nse";
        break;
      case OrderExchangeType.NSE_FO:
        res = "nfo";
        break;
      case OrderExchangeType.MCX_FO:
        res = "mcx";
        break;
      case OrderExchangeType.NCDEX_FO:
        res = "cds";
        break;
      case OrderExchangeType.NSE_COMM:
        res = "mcx";
        break;
      case OrderExchangeType.NSE_CUR:
        res = "cds";
        break;
      case OrderExchangeType.NSE_OTS:
        res = "cds";
        break;
      case OrderExchangeType.BSE_EQ:
        res = "bse";
        break;
      case OrderExchangeType.BSE_FO:
        res = "bfo";
        break;
      default:
        res = "nse";
    }
  } else {
    res = "nse"; // Default if instName is invalid or null
  }

  return res;
}


export const tradeDirectionPayload = (
  tradeDirection: TradeDirection
): GTTOrderPayload["transaction_type"] => {
  switch (tradeDirection) {
    case "Buy":
      return "BUY";
    default:
      return "SELL";
  }
};

export const triggerTypePayload = (
  mode: TriggerMode
): GTTOrderPayload["gtt_trigger_type"] => {
  if (mode === "OCO") {
    return "oco";
  }
  return "single";
};

export const OptionTimeframePayload = (
  timeframe: OptionTimeframe
): GTTOrderPayload["product"] => {
  switch (timeframe) {
    case "Intraday":
      return "INTRADAY";
    case "Delivery":
      return "DELIVERY";
    case "Pay Later":
      return "MTF";

    case "Intraweek":
      return "BTST";
    case "Caryforward":
      return "DELIVERY";

    default:
      return "INTRADAY";
  }
};


export const targetOrderTypePayload = (
  timeframe: OrderType
): GTTOrderPayload["variety"] => {
  switch (timeframe) {
    case "Limit":
      return "RL";
    case "Market":
      return "RL-MKT";

    default:
      return "RL-MKT";
  }
};

export const safeParseNumber = (value: string | undefined, defaultValue: number = 0): number => {
  if (!value) return defaultValue;
  const parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};



export const constructGTTUpdatePayload = ({
  formState,
  targatedOrder,
  tradeDirection,
}: ConstructPayloadParams): UpdateOrderPayload[] => {
  const payload: UpdateOrderPayload[] = [
    {
      id: +targatedOrder.orders[0].id,
      price: formState.orderType === "Limit" 
        ? safeParseNumber(formState.price) 
        : 0.0,
      trigger_price: safeParseNumber(formState.triggerPrice),
      quantity: safeParseNumber(formState.quantity),
      variety: targetOrderTypePayload(formState.orderType),
    }
  ];

  // Check if the second order data is valid before adding it to the payload
  if (
    targatedOrder.orders[1]?.id &&
    formState.targetTriggerPrice !== undefined &&
    formState.targetQuantity !== undefined &&
    formState.targetOrderType !== undefined
  ) {
    payload.push({
      id: +targatedOrder.orders[1].id,
      price: formState.targetOrderType === "Limit" 
        ? safeParseNumber(formState.targetPrice) 
        : 0.0,
      trigger_price: safeParseNumber(formState.targetTriggerPrice),
      quantity: safeParseNumber(formState.targetQuantity),
      variety: targetOrderTypePayload(formState.targetOrderType),
    });
  }
  if(tradeDirection === 'Sell') {
    // reverse id of the paylaods we max have 2 orders
    const [idOne, idTwo] = [payload[0].id, payload[1].id];
    payload[0].id = idTwo;
    payload[1].id = idOne;
  }
  
  return payload;
};

export const handleGTTUpdateResponse = (
  result: { status: string; data?: any; message?: string }
) => {
  return {
    success: result.status === "success",
    message: result.status === "success" 
      ? "GTT Order updated successfully!"
      : result.message || "Failed to update GTT Order",
    orderId: result.data?.data?.order_id,
    notificationType: result.status === "success" ? 1 : 0
  };
};