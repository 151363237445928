import apiServer from "./apiServer";
import { setUser,logout as logOutApp, setSessionTime } from "../slices/authSlice";
import { API_KEY,MOMENT_KEY,SESSION_TIME,SOURCE,STORAGE_KEY, STORAGE_KEY_REFRESH } from "../utils/config";
import { remoteConfigGetter } from "../utils/remoteConfig";
import axios,{ AxiosRequestHeaders } from "axios";
import moment from 'moment';



const logout = () => async (dispatch:any) => {

    const key = JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null')
    var token = key?.access_token;
    logoutTerminal({},token).then(value => {
        return value.data
    }).then((data)=>{
        if(data.status=="success"){
            localStorage.removeItem(STORAGE_KEY);
            localStorage.removeItem(MOMENT_KEY);
            localStorage.removeItem(STORAGE_KEY_REFRESH);
        }
    }).catch(reason => {
        console.log("Error in logout api",reason)
    })
    dispatch(logOutApp())
};


async function ForgetPasswordOTP(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/password/reset/send-otp',data);
}

async function ChangePassword(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.put('authentication/v1/user/password/reset',data);
}
async function NewPasswordSet(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.put('authentication/v1/user/password',data);
}

async function MPinLogin(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/session',data);
}

async function ForgetMpinOtpSend(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin/reset/send-otp',data);
}
async function MpinOtpVerify(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/otp/verify',data);
}

async function ForgotMpinSet(formValues:any){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin/reset/verify-otp',data);
}

async function ChangeMpinFirstTime(formValues:any,headers:AxiosRequestHeaders){
    const data = getConfigDataBasic(formValues);
    return apiServer.post('authentication/v1/user/mpin',data,{headers: headers});
}


// DEPRECATED NO USE
async function SendUserId(formValues:any,headers:any){
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'aux/forgotuserid',formValues,{headers: headers});
}

function logoutTerminal(formValues:any,token:string){

    var headers:AxiosRequestHeaders = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.delete(remoteConfigGetter('inhouse_base_url_prod').asString()+'user/session',{headers: headers});
}

async function optionChaindata(formValues:any,token:string){
    var headers:AxiosRequestHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': String(process.env.REACT_APP_VORTEX_KEY),
        "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
        "X-Stage": String(process.env.REACT_APP_ENV),
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'aux/scrip/optiondata',formValues,{headers: headers});
}

async function newOptionChaindata(formValues:any,token:string){
    var headers:AxiosRequestHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': String(process.env.REACT_APP_VORTEX_KEY),
        "X-Application-Id": String(process.env.REACT_APP_TV_APPLICATION_ID),
        "X-Stage": String(process.env.REACT_APP_ENV),
        'Authorization': "Bearer "+token
         // 'api_key': API_KEY,
      }
    return axios.post(remoteConfigGetter('inhouse_base_url_prod').asString()+'strategies/option_chain',formValues,{headers: headers});
}

async function OtpVerify (otp:any,user_id:any,mobileNo:any)  {
    let formData = {mobileNo,otp,user_id}
    const data = getConfigDataBasic(formData);
    return apiServer.post('authentication/v1/user/otp/verify',data);
}

function getConfigDataBasic(formValues:any) {
    const api_key = API_KEY;
    const source = SOURCE;
    return {
        ...formValues,
        api_key,
        source
    };
}

function setTimeSession(dispatch:any) {
    var momentInTime = moment().add(SESSION_TIME,'minutes')
    dispatch(setSessionTime(momentInTime.valueOf()))
    localStorage.setItem(MOMENT_KEY,momentInTime.valueOf().toString())
}

function saveUserSession(user:any, mpin:any, dispatch:any) {
    dispatch(setUser(user));
    setTimeSession(dispatch);
    if (mpin) localStorage.setItem(STORAGE_KEY,JSON.stringify(user))
}

function saveUserSessionFlow(user:any,refreshData:any,dispatch:any) {
    dispatch(setUser(user));
    setTimeSession(dispatch);
    localStorage.setItem(STORAGE_KEY,JSON.stringify(user))
    localStorage.setItem(STORAGE_KEY_REFRESH,JSON.stringify(refreshData))
}



const authService = {
    logout,
    ForgetPasswordOTP,
    OtpVerify,
    ChangePassword,
    SendUserId,
    NewPasswordSet,
    MPinLogin,
    saveUserSession,
    ForgetMpinOtpSend,
    MpinOtpVerify,
    ForgotMpinSet,
    ChangeMpinFirstTime,
    optionChaindata,
    saveUserSessionFlow,
    newOptionChaindata
};

export default authService;
