import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from "../../../store";
import { FLOW_BACKEND_BASE_URL } from '../../../utils/config';
import debounce from 'lodash/debounce';
import { getSortName } from '../../../utils/getShortName';

// Define the types for the response data
export interface ScripLookUpHitSource {
  exp: string;
  sSymbol: string;
  sOptionType: string;
  nStrikePrice: string;
  nExpiryDate: number;
  nExpiryDate1: string;
  nExpiryDate2: string;
  nAssetToken: number;
  nInstrumentType: number;
  nMarketSegmentId: number;
  nNormal_MarketAllowed: number;
  nPriceBdAttribute: number;
  nRegularLot: number;
  nToken: number;
  sDerivitiveDesc: string;
  sInstrumentName: string;
  sSecurityDesc: string;
  sSeries: string;
  nSPOS: number;
  nPOS: number;
  DecimalLocator: number;
  nNRILimit: number;
  nFIILimit: number;
  nPriceTick: number;
  nSpread: number;
  sISINCode: string;
  sGlobalNormalFlag: string;
  nStrikePrice1: string;
  nIsIndex: number;
  nFOExists: number;
  nCoCode: number;
  nSectorCode: number;
  nOtherExchToken: number;
  sSector: string;
  nExpiryMonth: number;
  nIssueMaturityDate: number;
  nIsSIP: number;
  nScripInstrument: number;
  sQtyUnit: string;
  sSurvMeasureDescription: string | null;
  nMaxSingleTransactionQty: number;
  nIssuedCapital: number;
  dtLastUpdateTime: string;
  id: string;
  sExchange: string;
  sSearchText: string;
  sSymbolnew: string;
  MktSegId: number;
  ExchangeSegment: string;
}

// Structure of the API response
interface ScripLookUpResponse {
  status: boolean;
  result: {
    took: number;
    timed_out: boolean;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
    hits: {
      total: {
        value: number;
        relation: string;
      };
      max_score: number | null;
      hits: Array<{
        _index: string;
        _type: string;
        _id: string;
        _score: number | null;
        _source: ScripLookUpHitSource;
        sort: Array<any>;
      }>;
    };
  };
}

// Enum for product codes
enum ProductCode {
  STOCK_AND_INDICES = 9,
  FUTURES_AND_OPTIONS = 22
}

// Type for search types
type SearchType = 'Stocks' | 'Indices' | 'Future' | 'Options' | 'All';

// Interface for return value
interface UseScripLookUpReturn {
  data: ScripLookUpHitSource[] | null;
  loading: boolean;
  error: string | null;
}

// Interface for request parameters
interface ScripLookUpRequestParams {
  scripName: string;
  productCode: number;
  serchType: SearchType;
}

// Filtering function
const filterScripData = (
  data: ScripLookUpHitSource[], 
  productCode: number, 
  serchType: SearchType
): ScripLookUpHitSource[] => {
  // If no search type or empty data, return empty array
  if (!serchType || !data || data.length === 0) {
    return [];
  }

  // If search type is 'All', return all data
  if (serchType === 'All') {
    return data;
  }

  // Handle Stock and Indices (Product Code 9)
  if (productCode === ProductCode.STOCK_AND_INDICES) {
    // First, filter by market segment (Stock or Indices)
    const segmentFilteredData = data.filter(
      item => item.nMarketSegmentId === 1 || item.nMarketSegmentId === 3 || item.nMarketSegmentId === 8
    );

    // Then filter by specific type
    switch (serchType) {
      case 'Stocks':
        return segmentFilteredData.filter(item => item.nIsIndex === 0);
      case 'Indices':
        return segmentFilteredData.filter(item => item.nIsIndex === 1);
      default:
        return segmentFilteredData;
    }
  }

  // Handle Futures and Options (Product Code 22)
  if (productCode === ProductCode.FUTURES_AND_OPTIONS) {
    // First, filter by market segment (Futures or Options)
    const segmentFilteredData = data.filter(
      item => item.nMarketSegmentId === 2 || item.nMarketSegmentId === 4 || item.MktSegId === 5
    );

    // Then filter by specific type
    switch (serchType) {
      case 'Future':
        return segmentFilteredData.filter(item => getSortName(item.sInstrumentName) === "FUT");
      case 'Options':
        return segmentFilteredData.filter(item => getSortName(item.sInstrumentName) === "OPT");
      default:
        return segmentFilteredData;
    }
  }


  // If product code doesn't match, return original data
  return data;
};

// Main hook
const useScripLookUp = ({ 
  scripName, 
  productCode, 
  serchType = 'All' 
}: ScripLookUpRequestParams): UseScripLookUpReturn => {
  const [data, setData] = useState<ScripLookUpHitSource[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const token = useSelector((state: RootState) => state.authUser.user?.access_token);

  // Construct the API URL dynamically using scripName and productCode
  const apiUrl = `${FLOW_BACKEND_BASE_URL}non-trading/getScripLookUp/${scripName}/${productCode}`;

  // Debounced fetch function
  const debouncedFetch = useCallback(
    debounce(async () => {
      // Only fetch if scripName is not empty
      if (!scripName.trim()) {
        setData(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response: AxiosResponse<ScripLookUpResponse> = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.data.status && response.data.result.hits.hits.length > 0) {
          // Extract hits from the response
          const rawData = response.data.result.hits.hits.map(hit => hit._source);
          
          // Apply filtering based on product code and search type
          const filteredData = filterScripData(rawData, productCode, serchType);
          
          // Log the filtered data for debugging
          
          setData(filteredData);
          setError(null);
        } else {
          setData(null);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error');
        setData(null);
      } finally {
        setLoading(false);
      }
    }, 500), // 500ms debounce delay
    [apiUrl, token, scripName, productCode, serchType]
  );

  useEffect(() => {
    // Cancel any pending debounced calls when component unmounts
    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  useEffect(() => {
    // Trigger debounced fetch
    debouncedFetch();
  }, [scripName, productCode, debouncedFetch, serchType]);

  return { data, loading, error };
};

export default useScripLookUp;