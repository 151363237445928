import React from "react";
import './ToggleButton.scss';

export interface ToggleButtonProps<T extends string> {
  options: T[];
  selectedOption: T;
  onToggle: (option: T) => void;
  className?: string;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}

function ToggleButton<T extends string>({
  options,
  selectedOption,
  onToggle,
  className = "",
  style,
  buttonStyle
}: ToggleButtonProps<T>) {
  return (
    <div className={`toggle-container ${className}`} style={style}>
      <div className="">
        {options.map((option, index) => (
          <button
            key={option}
            type="button"
            style={{
              ...buttonStyle,
              borderBottomRightRadius: index === options.length - 1 ? '4px' : '0',
              borderTopRightRadius: index === options.length - 1 ? '4px' : '0',
              borderBottomLeftRadius: index === 0 ? '4px' : '0',
              borderTopLeftRadius: index === 0 ? '4px' : '0',
              fontWeight: 600,
            }}
            className={`toggle-button ${selectedOption === option ? 'active selected-group-button' : 'non-selected-group-button'}`}
            onClick={() => onToggle(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ToggleButton;
