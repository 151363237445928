import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./search.scss";

import { propertyToStringForEdit } from "../../../helper/alert/condition";
import type { Conditions } from "../../../helper/alert/condition";

interface LastTradedPriceInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  property: Conditions;
  error?: string; // Optional error message
}

const LastTradedPriceInput: React.FC<LastTradedPriceInputProps> = ({
  value,
  onChange,
  disabled,
  property,
  error,
}) => {
  return (
    <div className="last-traded-price-input">
      <Form.Label
        className="text-start w-100 text-color font-16"
        htmlFor="lastTradedPrice"
      >
        <strong className="text-color">
          
          Percentage ({propertyToStringForEdit(property)})
        </strong>
      </Form.Label>

      <InputGroup>
        <Form.Control
          id="lastTradedPrice"
          className={`border-right-0 create-or-edit-dropdown pe-4 ${
            disabled ? "alerts-disabled cou" : ""
          } ${error ? "is-invalid" : ""}`} // Apply 'is-invalid' if error exists
          aria-describedby="Last Traded Price Input"
          placeholder="Enter Percentage"
          maxLength={20}
          type="number"
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
        <InputGroup.Text
          className="create-or-edit-dropdown condition-dropdown-percentage border-left-0"
          aria-label="Percentage"
        >
          %
        </InputGroup.Text>
      </InputGroup>

      {/* Display error message if error exists */}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default LastTradedPriceInput;
