import React from "react";

interface PropsCustomBuyOrSell {
  children?: React.ReactNode; // ReactNode is more appropriate for children
}

const CustomBuyOrSell: React.FC<PropsCustomBuyOrSell> = ({ children }) => {
  const color = typeof children === 'string' && children.toLocaleLowerCase() === "buy" ? "#089981" : "#F23645";
  
  return (
    <div className="capitalize" style={{ color }} >
      {children}
    </div>
  );
};

export default CustomBuyOrSell;
