// useDeleteAlert.ts
import { useState } from "react";
import axios from "axios";
import { FLOW_BACKEND_BASE_URL } from "../../../utils/config";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface UseDeleteAlertResult {
  deleteAlert: (id: number) => Promise<boolean>; // Returning Promise<boolean>
  loading: boolean;
  error: string | null;
  success: boolean;
}

const useDeleteAlert = (): UseDeleteAlertResult => {
    const token = useSelector(
      (state: RootState) => state.authUser.user?.access_token
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
  
    const deleteAlert = async (id: number): Promise<boolean> => {
      if (!token) {
        setError("Authentication token is missing");
        return false;
      }
  
      setLoading(true);
      setError(null);
      setSuccess(false);
  
      try {
        const response = await axios.delete(
          `${FLOW_BACKEND_BASE_URL}aux/alerts/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          setSuccess(true);
          return true;
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (err: any) {
        const errorMessage =
          err.response?.data?.message || err.message || "Failed to delete alert";
        setError(errorMessage);
        return false;
      } finally {
        setLoading(false);
      }
    };
  
    return {
      deleteAlert,
      loading,
      error,
      success,
    };
  };
  
  export default useDeleteAlert;
  
