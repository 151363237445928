import React from "react";
import frameIcon from "../assets/icons/Frame.svg";

interface NoStocksFoundProps {
  heading: string; // This will be the path to the icon image
  subHeading: string;
}

const NoStocksFound: React.FC<NoStocksFoundProps> = ({
  heading,
  subHeading,
}) => {
  return (
    <div className="d-flex pb-2 flex-column justify-content-start align-items-center">
      <div className="">
        <img style={{height:150}} src={frameIcon} alt="No Stocks Found" />
      </div>
      <div className="heading2">{heading}</div>
      <div className="text1 colorGrey currentColor">{subHeading}</div>
    </div>
  );
};

export default NoStocksFound;
